.gw-input {
  display: block;
  width: 100%;
  height: calc(2em + .41em + .605em);
  font-family: "Open Sans", Arial, sans-serif;
  background: transparent;
  font-size: 1.4em;
  line-height: 1.75;
  padding: 8px 10px;
  flex: 1;
  background-color: #fff;
  background-color: var(--inputBackgroundColor, var(--th-white));
  border: 1px solid #DADADA;
  border: var(--inputBorder, var(--input-border));
  color: #242a38;
  color: var(--inputTextColor, var(--th-slate-dark));
  border-radius: .3em;
  /** Class Modifiers
---------------------------------------------------------*/ }
  .gw-input:focus {
    background-color: #fff;
    background-color: var(--inputBackgroundColor, var(--th-white)); }
  .gw-input:disabled, .gw-input:read-only {
    opacity: 0.36;
    user-select: none;
    text-decoration: none; }
  .gw-input::placeholder {
    color: var(--fontColorDark);
    opacity: 0.6; }
  .gw-input--s1 {
    height: calc(1.8em + .5em + .2em);
    line-height: 1.5;
    font-size: 1.2em; }
  .gw-input--s2 {
    height: calc(2em + .41em + .605em);
    line-height: 1.75;
    font-size: 1.4em; }
  .gw-input--s3 {
    height: calc(2.8em + .8em + .8em);
    line-height: 1.75;
    font-size: 1.6em; }
  .gw-input--slider-value {
    position: relative;
    width: 60px;
    margin: 0 0 0 1em; }

.gw-input:read-only {
  background-color: #eaedf0;
  opacity: 0.8;
  user-select: none;
  text-decoration: none; }

.gw-textarea {
  height: auto;
  resize: none; }
