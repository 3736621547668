

@import '../mixins/variable';

.gw-slider {
    display: flex;
    align-items: center;
    width: 100%;
}

input[type=range].gw-slider__input {
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;

    &:hover {
        opacity: 1; /* Fully shown on mouse-over */
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid hsla(0, 0%, 0%, 0.256);
        background-color: hsl(0, 0%, 99%);
        cursor: pointer;

        &:hover {
            filter: drop-shadow(1px 1px 1px hsla(0, 0%, 40%, 0.194));
        }
    }

    &::-moz-range-thumb {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid hsla(0, 0%, 0%, 0.256);
        background-color: hsl(0, 0%, 99%);
        cursor: pointer;

        &:hover {
            filter: drop-shadow(1px 1px 1px hsla(0, 0%, 40%, 0.194));
        }
    }

    &::-ms-thumb {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid hsla(0, 0%, 0%, 0.256);
        background-color: hsl(0, 0%, 99%);
        cursor: pointer;

        &:hover {
            filter: drop-shadow(1px 1px 1px hsla(0, 0%, 40%, 0.194));
        }
    }

    // Focus States

    &:focus::-webkit-slider-thumb {
        box-shadow: 0 0 4px 2px #006fe6;;
    }

    &:focus::-moz-range-thumb {
        box-shadow: 0 0 4px 2px #006fe6;;
    }

    &:focus::-ms-thumb {
        box-shadow: 0 0 4px 2px #006fe6;;
    }
}
