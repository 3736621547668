@import '../mixins/variable';
@import '../mixins/box-shadow';

.gw-login-bg {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    padding-bottom: 48px;
    background-color: var(--uniloginBackgroundColor);
}

.gw-unilogin-textcolor {
    color: #4370B4;
}

.gw-button-outlined:hover .gw-unilogin-textcolor {
    color: inherit;
    transition: 0s;
 }

.gw-login-texthelp {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 0;
    right: 4%;
    width: 210px;
    height: auto;

    &__tab {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 48px;
        background-color: white;
        border-radius: .8em .8em 0 0;
        @include box-shadow(0, 8px, 16px, rgba(0, 0, 0, 0.2));

        @media only screen and (max-width: 720px) {
            box-shadow: none;
            width: 36px;
        }
    }

    &__text {
        color: var(--fontColorDark);
        font-size: 1.2em;
        margin-left: 1em;

        @media only screen and (max-width: 720px) {
            margin-left: 0;
        }
    }

    @media only screen and (max-width: 720px) {
        width: 100%;
        margin-top: auto;
        background-color: #FFFFFF;
        justify-content: center;
        right: 0;
    }
}

.gw-unilogin {
    display: flex;
    max-width: 744px;
    min-width: 290px;
    min-height: 340px;
    background-color: white;
    border-radius: 8px;
    margin: 1em 1em 0 1em;
    @include box-shadow(0, 0, 16px, rgba(0, 0, 0, 0.2));

    &__product {
        display: flex;
        flex-direction: column;
        align-self: stretch;
        width: 50%;
        @include variable( border-color, --uniloginWindowBackgroundColor, --primaryColor, hsl(264, 83%, 42%));
        @include variable( background-color, --uniloginWindowBackgroundColor, --uniloginWindowBackgroundColor, hsl(209, 100%, 39%));
        border-radius: 8px 0 0 8px;

        @media only screen and (max-width: 720px) {
            width: 100%;
            background-color: white;
        }
    }

    &__logobox {
        width: 100%;
        padding: 43px 50px 45px 40px;
        display: flex;
        align-items: center;
        justify-content: left;

        @media only screen and (max-width: 720px) {
            @include variable( background-color, --uniloginWindowBackgroundColor, --uniloginWindowBackgroundColor, hsl(209, 100%, 39%));
            padding: 25px 0;
            justify-content: center;
            border-radius: 8px 8px 0 0;
        }

        @media only screen and (max-width: 390px) {
            width: 100%;
            border-radius: 0;
        }
    }

    &__logo {
        //width: 245px;
        height: 60px;

        @media only screen and (max-width: 720px) {
            //width: 210px;
            height: 52px;
        }
    }

    &__text {
        padding: 0 50px 50px 50px;
        font-size: 1.6em;
        line-height: 1.6em;
        font-family: 'Open Sans' sans-serif;
        color: white;

        @media only screen and (max-width: 720px) {
            color: var(--fontColorDark);
            padding: 25px 50px;
            text-align: center;
        }
    }

    &__sign-in {
        position: relative;
        display: flex;
        width: 50%;
        align-self: stretch;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 34px 30px 34px;

        & button:last-of-type {
            margin-bottom: 0;
        }

        & button {
            margin-bottom: 0.4em;
        }

        @media only screen and (max-width: 720px) {
            width: 100%;
            padding: 0 34px 25px 34px;
        }
    }

    &__more-options {
        position: relative;
        bottom: -3em;
        margin-top: 0;
        //font-size: 1.4em;
        //color: var(--primaryColorPurple);

        @media only screen and (max-width: 720px) {
            display: block;
            margin-top: 16px;
            bottom: 0;
        }
    }

    &-privacy {
        width: 720px;
        max-width: 720px;
        padding: 21px 0 0 26px;
        font-size: 1.2em;
        line-height: 1.5em;
        
        @media only screen and (max-width: 720px) {
            max-width: 372px;
            padding: 22px 1em 0 1em;
            text-align: center;
        }

        @media only screen and (max-width: 390px) {
            width: 100%;
            padding: 22px 1em 0 1em;
        }

        & a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__brand {
        margin: 22px 0 0 0;

        @media only screen and (max-width: 720px) {
            margin: 22px auto;
        }

        &--texthelp {
            width: 121.11px;
            height: 25.24px;
        }

        &--claro {
            width: 72px;
            height: 40px;
        }
    }

    @media only screen and (max-width: 720px) {
        width: 372px;
        max-width: 372px;
        flex-direction: column;
    }

    @media only screen and (max-width: 390px) {
        width: 100%;
        border-radius: 0;
    }
}