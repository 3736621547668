
.gw-panel {
    @include variable( background-color, --panelBackgroundColor,  --defaultColor, hsl(210, 2%, 84%) );

    &--light {
        @include variable( background-color, --panelBackgroundColorLight,  --defaultColor, hsl(210, 2%, 84%) );
    }

    &--dark {
        @include variable( background-color, --panelBackgroundColorDark,  --defaultColor, hsl(210, 2%, 84%) );
    }
}

.gw-text {
    @include variable( color, --fontColor,  --white, hsl(0, 0%, 100%) );
    
}

.gw-border-top {
    @include variable( border-top, --borderColor,  --th-grey, hsl(220, 20%, 91%) );
}

.gw-border-right {
    @include variable( border-right, --borderColor,  --th-grey, hsl(220, 20%, 91%) );
}