@import '../mixins/variable';

.gw-toggle-group {

    & button:first-child {
        margin-right: 0;
        padding: 10px 28px 10px 3px;
        border-radius: 5px 0 0 5px;
    }

    & button:last-child {
        margin-left: 0;
        padding: 10px 3px 10px 28px;
        border-radius: 0 5px 5px 0;
    }
}

.gw-toggle-button {

    font-size: 1.3em!important;

    &__dot {
    display: inline-block;
    width: .3em;
    height: .3em;
    background-color: var(--activeDotColor);
    border-radius: 1em;
    vertical-align: middle;
    margin: 0 12px 0 12px;
    }

    &__active-teal {
        --activeDotColor: white;
        background-color: var(--primaryColorTeal)!important;
        border-color: var(--primaryColorTeal)!important;
        color: white!important;
    }
}



