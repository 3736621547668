

@import '../mixins/variable';

.gw-button-dropdown-wrapper {
    position: relative;
    margin: 0;
    padding: 0;
}

.gw-dropdown-menu {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    display: block;
    float: left;
    min-width: 17em;
    font-size: 1.4em;
    color: var( --th-dark-slate);
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: .25em;

    &__item {
        display: block;
        width: 100%;
        padding: .45em 1.6em;
        clear: both;
        font-weight: 400;
        color: #212529!important;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;

        &:visited { }
        &:hover { background-color: hsla(0, 0%, 0%, 0.09) }
        &:focus { }
        &:active { }
    }
}