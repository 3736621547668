

@import '../mixins/variable';

input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}

 input[type=radio] {
    box-sizing: border-box;
    padding: 0;

    &:disabled,
    &[disabled] {
        pointer-events: none;
        background-color: hsla(216, 100%, 92%, 0.35);
        border: 1px solid hsla(216, 97%, 88%, 0.35);
    }
}

.gw-radio {
    position: relative;
    display: block;
    font-size: 10px;
    padding-right: 1.25em;

    &__input {
        position: absolute;
        margin-top: .3em;
        overflow: visible;
        height: 16px;
        width: 16px;

        &:disabled ~ .gw-radio__input-label {
            color: hsl(208, 7%, 46%)!important;
        }
    }

    &__input-label {
        position: relative;
        display: inline-block;
        font-family: 'Open Sans', sans-serif;
        font-size: 1.4em;
        color: inherit;
        line-height: 1.5;
        margin: 0 0 .5em 1.8em;
    }

    // Modifiers

    &--no-form {
        position: relative;
        display: block;    
        padding-left: 1.5em;
    }

    &--inline {
        display: -ms-inline-flexbox;
        display: inline-flex;
        margin-right: 1.5em;

        &:not(:first-child) {
            padding-left: 0;
        }
    }
}
