@import '../mixins/variable';

.screenstick {
    width: 100%;
    height: 600px;
    position: relative;
}

.gw-app-menu-rollout {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    width: 326px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--th-cool-grey-800);
    font-size: 10px;

    & label {
        color: #fff;
    }

    &__header {
        padding: 1.8em;
    }

    &__language {
        //position: relative;
        justify-content: flex-end;
        padding: 0 3.5em 1.8em 3.5em;
    }

    &__branding {
        //position: relative;
        justify-content: flex-end;
        padding: 0 3.5em 3.5em 3.5em;
    }

    &__profile {
        width: 6.5em;
        height: 6.5em;
        border: 0.6em solid var(--brandColorBlue); 
        border-radius: 10em;
        background-color: #fff;
    }
}

.gw-app-menu-nav-area {
display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex;
display: flex;
-webkit-box-direction: normal;
-webkit-box-orient: vertical;
-webkit-flex-direction: column;
-moz-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
justify-content: space-between;
padding: 1em 0;
flex: 1 1;
font-size: 10px;
@include variable( background-color, --settingsDialogMenuBackgroundColor,  --th-grey-white, hsl(210, 10%, 96%) );

    &__menu {
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin: 5px 0;

        & > li {
            margin: 0;
            padding: 0;
            border: 0;
            background-color: transparent;
            cursor: pointer;
            fill: #fff;
            color: #fff;

            & a {
                color: #fff;
                font-size: 1.5rem;
                text-decoration: none;
            }
        }
    }
}
