

.gw-button-group {
    position: relative;
    display: inline-flex;
    vertical-align: middle;

    & > .gw-icon-button {
        position: relative;
        flex: 1 1 auto;
        width: 4.3em;
        height: 3.8em;
        margin: 0!important;
        border-radius: .4em;

        &:not(:first-of-type) {
            border-top-left-radius: 0!important;
            border-bottom-left-radius: 0!important;
        }

        &:not(:last-of-type) {
            border-top-right-radius: 0!important;
            border-bottom-right-radius: 0!important;
        }


        &:hover {
            background-color: #CCD0DB;
        }

        &--active {  
            background-color: #626f88;
        }
        
        &--active:hover {
            background-color: #626f88;
        }


    }

}
