
@import '../mixins/variable';

.gw-icon-fill {
    @include variable( fill, --iconFillColor,  --iconFillColor, hsl(0, 0%, 0%));
}

.icons-wrap {
    display: flex;
    flex-wrap: wrap;

    & > button {
        margin-bottom: 10px;
    }
}