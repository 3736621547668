.gw-notification {
  position: relative;
  padding: 1.2em;
  margin: 1em 0;
  border: 1px solid transparent;
  border-radius: .25em;
  /** Elements
---------------------------------------------------------*/
  /** Modifiers
---------------------------------------------------------*/ }
  .gw-notification__iconbox {
    display: inline-block;
    vertical-align: top;
    margin-top: 0.9em; }
  .gw-notification__textbox {
    display: inline-block; }
  .gw-notification__title {
    font-family: "Roboto", sans-serif;
    text-transform: none;
    margin: 0;
    padding: 0;
    font-size: 1.6em;
    font-weight: 600; }
    .gw-notification__title--info {
      color: var(--infoTitleColor); }
    .gw-notification__title--success {
      color: var(--successTitleColor); }
    .gw-notification__title--error {
      color: var(--errorTitleColor); }
    .gw-notification__title--warning {
      color: var(--warningTitleColor); }
  .gw-notification__icon {
    width: 2em;
    height: 2em;
    flex-shrink: 0;
    margin-right: 1em;
    align-self: center; }
  .gw-notification_icon-wrapper {
    width: 2em;
    height: 2em;
    display: inline-block; }
  .gw-notification__message {
    font-family: "Open Sans", sans-serif;
    text-transform: none;
    margin: 0.2em 0 0 0;
    padding: 0;
    font-size: 1.2em;
    font-weight: 400; }
    .gw-notification__message--info {
      color: var(--infoWarningColor); }
    .gw-notification__message--success {
      color: var(--successWarningColor); }
    .gw-notification__message--error {
      color: var(--errorWarningColor); }
    .gw-notification__message--warning {
      color: var(--warningWarningColor); }
  .gw-notification__wrapper {
    display: flex;
    justify-content: space-between; }
  .gw-notification .gw-notification-action-buttons-wrapper {
    display: flex;
    align-items: center; }
    .gw-notification .gw-notification-action-buttons-wrapper > :not(:first-child) {
      margin-left: .25em; }
    .gw-notification .gw-notification-action-buttons-wrapper > :not(:last-child) {
      margin-right: .25em; }
  .gw-notification--info {
    background-color: var(--infoBackgroundColor);
    border-color: var(--infoBorderColor); }
  .gw-notification--success {
    background-color: var(--successBackgroundColor);
    border-color: var(--successBorderColor); }
  .gw-notification--error {
    background-color: var(--errorBackgroundColor);
    border-color: var(--errorBorderColor); }
  .gw-notification--warning {
    background-color: var(--warningBackgroundColor);
    border-color: var(--warningBorderColor); }
  .gw-notification--mobile {
    display: flex;
    flex-direction: row; }

/** Buttons
---------------------------------------------------------*/
.gw-notification-close-button {
  position: absolute;
  top: .5em;
  right: .5em; }

.gw-notification-status-icon {
  font-size: 10px;
  width: 2.25em;
  height: 1.8em;
  margin: -.3em .8em 0 0;
  vertical-align: middle;
  opacity: 0.9; }
  .gw-notification-status-icon--info {
    fill: var(--infoTitleColor); }
  .gw-notification-status-icon--success {
    fill: var(--successTitleColor); }
  .gw-notification-status-icon--error {
    fill: var(--errorTitleColor); }
  .gw-notification-status-icon--warning {
    fill: var(--warningTitleColor); }
