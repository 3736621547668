
/* stylelint-disable */
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
  
    @if $index {
      @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }
  
    @return $string;
  }
  
  @function svg-data-uri($viewBox, $data, $additionalProps: false) {
    $attrs: "";
    @if($additionalProps != false) {
      @each $propKey, $propValue in $additionalProps {
        $attrs: '#{$attrs}#{$propKey}=\'#{$propValue}\' ';
      }
    }
  
    $encoded: '';
    $slice: 2000;
    $index: 0;
    $loops: ceil(str-length($data) / $slice);
  
    @for $i from 1 through $loops {
      $chunk: str-slice($data, $index, $index + $slice - 1);
      $chunk: str-replace($chunk, '"', "'");
      $chunk: str-replace($chunk, '<', '%3C');
      $chunk: str-replace($chunk, '>', '%3E');
      $chunk: str-replace($chunk, '&', '%26');
      $chunk: str-replace($chunk, '#', '%23');
      $encoded: #{$encoded}#{$chunk};
      $index: $index + $slice;
    }
  
    @return 'data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' #{$attrs}viewBox=\'#{$viewBox}\'>#{$encoded}</svg>';
  }
  /* stylelint-enable */