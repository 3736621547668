// Layout
.gw-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include variable( background-color, --toolbarBackgroundColor,  --defaultColor, hsl(210, 2%, 84%) );
    box-shadow: 2px 0 23px -8px rgba(0,0,0,0.75);
    z-index: 2147483625;

    &__toolbar-buttons {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        padding: 0;

        & > .gw-group {
            margin: 0 2.2em 0 0;
        }
    }
    
    &__icon-buttons {
        position: relative;
        display: flex;
        justify-content: flex-end;
        margin: 0;
        padding: 0;
    }

    &__logo-wrapper {
        margin-left: auto;
        padding: 1em 6em 1em 1em;
    }
}

.gw-toolbar-buttons {

    &__move-area {
        // Move Icon
        position: relative;
        display: inline-block;
        overflow: hidden;
        height: 35px;
        width: 35px;
        margin: 0 0 0 .8em;
        background-color: transparent;
    }
}

.gw-toolbar-move {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;

    &__icon {
        position: relative;
        height: 20px;
        margin: 0 2px 0 0;
        @include variable( fill, --iconFillColor,  --iconFillColor, hsl(0, 0%, 0%));
        opacity: .4;

        &:hover {
            @include variable( fill, --iconFillColor,  --iconFillColor, hsl(0, 0%, 0%));
            opacity: 1;
            }
  
          &:active {
              fill: darken(#606060, 8%);
          }
    }
}

.gw-toolbar-list {
    display: flex;
    align-items: center;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;

    &--wrap {
        flex-wrap: wrap;
        flex-shrink: 1;
    }

    &__element {
        position: relative;
        display: inline-block;
        transition-duration: 0.5s;
        padding: 0;
        margin: 0.3em;
        border-radius: 0.9em;

        &--icon-button {
            padding: 0;
            border-radius: 35px;
        }

        &--hover-toggle {
            // Make the dropdown visible on hover - this will need to be triggered using javascript if you want the trigger to be a click
            &:hover > ul,
            &:focus-within > ul,
            & ul:hover {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    &__dropdown {
        visibility: hidden; 
        opacity: 0;
        position: absolute;
        transition: all 0.5s ease;
        margin-top: 0;
        padding: 0.4em;
        top: 4.3em;
        width: auto;
        left: 0;
        display: none;
    }

}

.gw-toolbar-list-dropdown {
    @include variable( background-color, --toolbarBackgroundColor,  --defaultColor, hsl(210, 2%, 84%) );
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0.4em;
    border-bottom-left-radius: 0.7em;
    border-bottom-right-radius: 0.7em;
    // Drop shadow
    box-shadow: 0px 9px 20px -14px rgba(0,0,0,0.40);

    &__element {
        display: inline-block;
        position: relative;
        transition-duration: 0.2s;
        padding: 0;
        margin: 0;

        &:not(:last-child) {
            margin-right: .35em;
        }
    }

    &--more {
        width: 170px;
        top: 3.5em;
        left: -12.7em;
        padding: 0;

        & .gw-list-group-item {
            padding: .8em 1.3em;
        }
    }
}



// Undocked Toolbar

.gw-toolbar--undocked {
    @include variable( background-color, --toolbarBackgroundColor,  --defaultColor, hsl(210, 2%, 84%) );
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 
    0 1px 3px 1px rgba(60,64,67,0.15);
}
.gw-toolbar-wrapper--undocked {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0;
}

// Mobile Toolbars

.gw-app-bar {
    position: relative;
    width: 100%;
    height: 8.2em;
    background-color: var(--brandColorBlue);
    padding: 14px;
    
    &__title {
        flex: 0 0 65%;
        text-align: center;
        line-height: 1.2em;
        max-height: 5em;
        overflow: hidden;

        &--left {
            text-align: left;
            flex: 1 1 0;
        }

        h1 {
            color: #fff;
            font-size: 2em;
            text-transform: uppercase;
            line-height: 1.2em;
            margin: 0;
        }
    }

    &--bottom {
        display: flex;
        height: 8em;
        justify-content: space-between;
        @include variable( background-color, --toolbarBackgroundColor,  --defaultColor, hsl(210, 2%, 84%) );
        margin-top: 26px;
        padding: 0;
    }

    &__middle {
        position: absolute;
        right: 50%;
        top: -3em;
        transform: translate(50%, 0);

        &--inline {
            position: static;
            display: inherit;
        }
    }
    
    &__left {
        display: inherit;
    }

    &__right {
        position: absolute;
        right: 2.8em;
        top: -3em;

        &--inline {
            position: static;
            display: inherit;
        }
    }
}

.gw-app-mainbar {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    height: 15em;
    padding: 1em 0 0 0;
    background: rgb(1,125,191);
    background: linear-gradient(0deg, rgba(1,125,191,1) 0%, rgba(1,177,249,1) 100%);
    justify-content: center;
    margin-top: auto;

    & div:first-of-type {
        margin-left: 1.5em;
    }

    & div:last-of-type {
        margin-right: 1.5em;
    }

    &__wrapper {
        width: 13em;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    & p {
        font-size: 1.6em;
        font-weight: 600;
        color: #FFF;
        flex-basis: 100%;
        text-align: center;
    }
}