


@import '../mixins/variable';

/** Forms
---------------------------------------------------------*/
.gw-form {
    position: relative;
    width: 100%;

    &__container {
        width: 100%; /* Width of the outside container */
    }
}

.gw-form-fieldset {
    position: relative;
    border: 0;
    padding-left: 1.5em;
    padding-right: 1.5em;
    margin-top: 1em;
    margin-bottom: 1em;
    min-width: 175px;
    width: 100%;

    &__label {
        font-family: "Open Sans", Arial, sans-serif;
        font-size: 1.3em;
        font-weight: 600;
        display: inline-block;
        margin: 0 0 .1em 0;
        padding-top: .9em;
        box-sizing: border-box;
        color: yellow;
    }

    &__legend {
        padding: 0;
        font-weight: 600;
    }

    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;

        &--inline {
            display: flex;
        }

        &--two-col {
            position: relative;
            display: flex;
            width: 100%;
        }

        &--colour-picker {
            margin: 0;
            padding: 0;
            border: 0;
            background: transparent;
            border-radius: 0;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 1em;
        }
    }

    &--no-padding {
        padding-left: 0;
        padding-right: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.gw-form-fieldset-item {
    -webkit-align-items: center;
    align-items: center;
    padding: 0;
    margin: 0 .3em .3em 0;
    // width: 100%;

    &__label {
        font-family: "Open Sans", Arial, sans-serif;
        font-size: 1.3em;
        font-weight: 600;
        display: inline-block;
        margin: 0 0 .3em 0;
        padding: 9px 0;
        box-sizing: border-box;
        color: var(--fontColorDark);

        &--left {
            margin: 0 1.5em 1em 0;
        }
    }

    &__wrapper {
        display: block;

        &--slider {
            display: flex;
            align-items: center;
        }
    }

    &--colour-picker {
        display: flex;
        justify-content: center;
    }

    &--inline {
        display: -ms-inline-flexbox;
        display: inline-flex;
        margin-right: 1.5em;

        &:not(:first-child) {
            padding-left: 0;
        }
    }
}
