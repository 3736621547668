@import '../mixins/variable';

.gw-layout {
    // Dashboard layout
    &__dashboard {
        display: flex;
        font-size: 10px;
        background-color: #f8f8f8;

        &--header {
            display: flex;
            @include variable( background-color, --dashboardHeaderBackgroundColor,  --defaultColor, hsl(0, 0%, 100%) );
            //background: #fff;
            height: 70px;
            width: 100%;
            box-shadow: 0px 7px 25px #00000029;
            z-index: 2;
        }

        &--sidebar {
            bottom: 0;
            height: calc(100% - 90px);
            width: 200px;
            @include variable( background-color, --dashboardSidebarBackgroundColor,  --white, hsl(0, 0%, 100%) );
            //background-color: #FCFCFC;
            box-shadow: 3px 12px 16px #455B634A;
            background-size: 120px;
            z-index: 2;

            border-top: solid;
            border-top-width: 1px;
            @include variable( border-top-color, --dashboardSidebarBorderTopColor,  --th-grey, hsl(0, 0%, 100%) );
            //border-top-color: var( --th-grey);
        }

        &--main {
            min-height: calc(100% - 66px);
            width: calc(100% - 190px);
            height: calc(100% - 100px);
            margin: 90px 0 0 200px;
            padding: 27px 20px 20px;
            overflow-y: auto;
            z-index: -1;
            background-color: var( --white);
        }
    }

    // Sidebar layout
    &__sidebar {
        font-size: 10px;
        display: grid;
        grid-template-rows: 75px auto 75px;
        grid-template-areas:
        "header"
        "content"
        "footer";
        background-color: #f8f8f8;

        &--header {
            grid-area: header;
            background: #fff;
            box-shadow: 0 1px 20px -4px #838383;
        }

        &--content {
            grid-area: content;
            padding: 15px;
        }

        &--footer {
            grid-area: footer;
            background: #fff;
            box-shadow: 0 1px 20px -4px #838383;
        }
    }

    // Toolbar layout
    &__toolbar {
        font-size: 10px;
        background-color: #f8f8f8;
    }
}