@import "../mixins/variable";
@import "../mixins/box-shadow";

// Container that the modal scrolls within
.gw-window {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: auto;
  height: auto;
  z-index: var(--zindex-modal);
  outline: 0;
  &--rounded {
    .gw-window-content {
      border: none;
      border-radius: 8px;
      box-shadow: 0px 8px 16px hsla(0, 0%, 0%, 0.2);
      background: #fafafa 0% 0% no-repeat padding-box;

      .gw-window-header {
        border: none;
        padding: 7px;
        border-radius: 8px;

        .gw-window-header__move-area {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}
.gw-window-dialog {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  pointer-events: none;
  &--follow-cursor {
    position: fixed;
  }
}
.gw-window-dialog-scrollable {
  display: flex; // IE10/11
  max-height: subtract(100%, 2rem * 2);
  .gw-window-content {
    max-height: subtract(100vh, 2rem * 2); // IE10/11
    overflow: hidden;
  }
  .gw-window-header,
  .gw-window-footer {
    flex-shrink: 0;
  }
  .gw-window-body {
    overflow-y: auto;
  }
}
.gw-window-dialog-centered {
  display: flex;
  align-items: center;
  min-height: subtract(100%, 2rem * 2);
  &::before {
    display: block; // IE10
    height: subtract(100vh, 2rem * 2);
    height: min-content; // Reset height to 0 except on IE
    content: "";
  }
  &.gw-window-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;
    .gw-window-content {
      max-height: none;
    }
    &::before {
      content: none;
    }
  }
} // Actual modal
.gw-window-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
  // counteract the pointer-events: none; in the .modal-dialog
  @include variable(
    color,
    --modalTextColor,
    --th-slate-dark,
    hsl(222, 22%, 18%)
  );
  pointer-events: auto;
  @include variable(
    background-color,
    --modalContentBackgroundColor,
    --modal-content-bg,
    #fff
  );
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: var(--modal-content-border-radius);
  @include box-shadow(
    0,
    3px,
    8px,
    rgba(0, 0, 0, 0.2)
  ); // emove focus outline from opened modal
  outline: 0;
  &--list-group {
    border: none;
    z-index: 0;
    border-radius: 0.8em;
    &::before {
      position: absolute;
      top: -1.1em;
      left: 2.4em;
      content: "";
      width: 0;
      height: 0;
      border-left: 1em solid transparent;
      border-right: 1em solid transparent;
      border-bottom: 1.2em solid;
      @include variable(
        border-bottom-color,
        --listGroupItemArrowColor,
        --list-group-item-background-color,
        #fff
      );
      z-index: 1;
    }
    &::after {
      position: absolute;
      top: -1.3em;
      left: 2.4em;
      content: "";
      width: 0;
      height: 0;
      border-left: 1em solid transparent;
      border-right: 1em solid transparent;
      border-bottom: 1.2em solid var(--th-grey);
      z-index: -2;
    }
  }
  &--pointer-bottom-middle {
    &::before {
      top: initial;
      bottom: -1.2em;
      left: 50%;
      border-top: 1.2em solid var(--white);
      border-bottom: none;
      transform: translate(-50%);
    }
    &::after {
      top: initial;
      bottom: -1.4em;
      left: 50%;
      border-top: 1.2em solid var(--th-grey);
      border-bottom: none;
      transform: translate(-50%);
    }
  }
  &--pointer-bottom-right {
    &::before {
      top: initial;
      bottom: -1.2em;
      left: initial;
      right: 2.5em;
      border-top: 1.2em solid var(--white);
      border-bottom: none;
      transform: none;
    }
    &::after {
      top: initial;
      bottom: -1.4em;
      left: initial;
      right: 2.5em;
      border-top: 1.2em solid var(--th-grey);
      border-bottom: none;
      transform: none;
    }
  }
} // Modal background
.gw-window-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  width: 100vw;
  height: 100vh;
  background-color: var(--modal-backdrop-bg); // Fade for backdrop
  &.fade {
    opacity: 0;
  }
  &.show {
    opacity: var(--modal-backdrop-opacity);
  }
} // Modal header
// Top section of the modal w/ title and dismiss
.gw-window-header {
  display: flex;
  align-items: center; // so the close btn always stays on the upper right corner
  justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 600;
  border-bottom: 1px solid;
  @include variable(
    border-color,
    --modalContentBorderColorHeader,
    --modal-header-border-color,
    #e5e5e5
  );
  padding: 1em 1em;
  border-radius: 1px;
  @include variable(
    background-color,
    --modalHeaderBackgroundColor,
    --modal-header-background-color,
    #f5f5f5
  );
  & > h5 {
    font-size: 1.4em;
    @include variable(
      color,
      --modalTextColor,
      --th-slate-dark,
      hsl(222, 22%, 18%)
    );
  }
  &__move-area {
    // Move Icon
    position: relative;
    display: inline-block;
    overflow: hidden;
    height: 35px;
    width: 35px;
    margin: 0 0 0 0.8em;
    background-color: transparent;
  }
}
.gw-window-header-move {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  &__icon {
    position: relative;
    height: 20px;
    margin: 0 2px 0 0;
    @include variable(fill, --iconFillColor, --iconFillColor, hsl(0, 0%, 0%));
    opacity: 0.4;
    &:hover {
      @include variable(fill, --iconFillColor, --iconFillColor, hsl(0, 0%, 0%));
      opacity: 1;
    }
    &:active {
      fill: darken(#606060, 8%);
    }
  }
} // Title text within header
.gw-window-title {
  margin-top: 0;
  margin-bottom: 0;
  line-height: var(--modal-title-line-height);
} // Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.gw-window-body {
  position: relative; // Enable `flex-grow: 1` so that the body take up as much space as possible
  // when there should be a fixed height on `.modal-dialog`.
  flex: 1 1 auto;
  padding: var(--modal-inner-padding);
  & > p {
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 1.4em;
    @include variable(
      color,
      --modalTextColor,
      --th-slate-dark,
      hsl(222, 22%, 18%)
    );
  }

  &--section {
    margin-bottom: 1em;
  }

  &--list-group {
    padding: 0;
  }
} // Footer (for actions)
.gw-window-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center; // vertically center
  justify-content: space-between; // Right align buttons with flex property because text-align doesn't work on flex items
  padding: 2em - 1em / 2;
  border-top: 1px solid;
  @include variable(
    border-color,
    --modalContentBorderColorFooter,
    --th-white,
    #fff
  );
  border-radius: 1px;
  margin-top: auto;
  > * {
    margin: 1em / 2;
  }
  & > :not(:last-child) {
    margin-right: 0.25em;
  }
  & > :not(:first-child) {
    margin-left: 0.25em;
  }
  & > p {
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 1.4em;
    @include variable(
      color,
      --modalTextColor,
      --th-slate-dark,
      hsl(222, 22%, 18%)
    );
  }
} // Measure scrollbar width for padding body during modal show/hide
.gw-window-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
