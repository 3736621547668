$sides: ("b": "bottom","t": "top","l": "left","r": "right");
@each $prefix, $value in $sides {
  $property: if($prefix == '', '', -#{$value});
  @for $i from 0 through 10 {
    .m#{$prefix}{
      &#{($i * 5)} {
        margin#{$property}:#{($i * 5)}px;
      }
    }
    .p#{$prefix}{
      &#{($i * 5)} {
        padding#{$property}:#{($i * 5)}px;
      }
    }
  }
}