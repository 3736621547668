

@import '../mixins/variable';

.gw-search {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  // reset base font size
  font-size: 10px;
  

  &--filter {
    background-color: inherit;
    width: 100%;
  }

  &__icon {
    width: 18px;
    height: 18px;
    position: absolute;
    left: 16px;
    fill: #bcc3d1;
    align-self: center;
  }

  &__icon-hide {
    width: 46px;
    height: 42.2px;
    position: absolute;
    right: 0;
  }

  & > .gw-button {
    position: absolute;
    border: none!important;
    background-color: transparent;
    padding: 0;
    width: 31px;
    height: 100%;
    border-radius: 2px!important;
    right: 0;

    &:hover {
      border: none!important;
      @include variable( background-color,  --iconButtonBackgroundHoverColor,  --icon-button-background-hover-color, rgba(220, 223, 228, 0.5) );
    }

    & > i {
      right: 0;
      margin: 0;
      align-self: center;
    }

  }

  & > .gw-input {
    position: relative;
    display:flex;
    flex-direction:row;
    padding: 0 2em 0 3em;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--ListGroupSearchItemTextColor);
      opacity: 1; /* Firefox */
    }
    
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: var(--ListGroupSearchItemTextColor);
    }
    
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: var(--ListGroupSearchItemTextColor);
    }
  }
}

.gw-search-menu {
  position: relative;
  width: inherit;
  //background: #fff;
  background-color: var(--ListGroupSearchItemColor);
  border-radius: .3em;
  box-shadow: 0 0 1px 1px hsla(0, 0%, 27%, 0.3);

  & li {
    //border-bottom: 1px solid hsla(0, 0%, 0%, 0.15);
    border-bottom: 1px solid var(--ListGroupSearchItemBorderColor);
  }

  & li:last-child {
    border-bottom: none;
  }

      /** Class Elements
---------------------------------------------------------*/

// Link

  &__link {
    margin: 0;
    border: 0;
    background: transparent;
    border-radius: 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--ListGroupSearchItemTextColor);
    display: block;
    width: 100%;
    text-align: left;
    padding: 10px;
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none!important;
    -webkit-font-smoothing: antialiased;
  
    &:hover {
      background-color: hsl(210, 20%, 98%);
      background-color: var(--ListGroupSearchItemColorHover);
    }
  
    &.gw-search-menu__link--disabled {
      
    }

    &.gw-search-menu__link:disabled {
      pointer-events: none;
      opacity: 0.36;
    }
  
    & > p {
      font-size: 1em;
      margin-bottom: 0;
      color: var(--ListGroupSearchItemTextColor);
    }
  
    & > small {
      font-weight: 100;
    }
  }

  // Category

  &__category {
    display: flex;
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.15);
  
    &:last-child {
      border-bottom: none;
    }
  
    & > aside {
      flex-basis: 10%;
      background-color: var(--ListGroupSearchItemAsideColor);
      padding: 10px 10px;
      font-weight: 600;

      & p {
      color: var(--ListGroupSearchItemAsideTextColor);
      }
    }
  
    & > main {
      flex-basis: 90%;
    }
  }
}
