

@import '../mixins/variable';

.gw-input {
    display: block;
    width: 100%;
    height: calc(2em + .41em + .605em);
    font-family: "Open Sans", Arial, sans-serif;
    background: transparent;
    font-size: 1.4em;
    line-height: 1.75;
    padding: 8px 10px;
    flex: 1;
    @include variable( background-color, --inputBackgroundColor, --th-white, #fff );
    @include variable( border, --inputBorder, --input-border, 1px solid #DADADA );
    @include variable( color, --inputTextColor,  --th-slate-dark, hsl(222, 22%, 18%) );
    border-radius: .3em;

    &:focus {
        @include variable( background-color, --inputBackgroundColor, --th-white, #fff );
    }

    &:disabled,
    &:read-only {
        opacity: 0.36;
        user-select: none;
        text-decoration: none;
    }

    &::placeholder {
        color: var(--fontColorDark);
        opacity: 0.6;
    }

  /** Class Modifiers
---------------------------------------------------------*/
    &--s1 {
        height: calc(1.8em + .5em + .2em);
        line-height: 1.5;
        font-size: 1.2em;
    }

    &--s2 {
        height: calc(2em + .41em + .605em);
        line-height: 1.75;
        font-size: 1.4em;
    }

    &--s3 {
        height: calc(2.8em + .8em + .8em);
        line-height: 1.75;
        font-size: 1.6em;
    }

    &--slider-value {
        position: relative;
        width: 60px;
        margin: 0 0 0 1em;
    }
}

.gw-input:read-only {
    background-color: #eaedf0;
    opacity: 0.8;
    user-select: none;
    text-decoration: none;
}

.gw-textarea {
    height: auto;
    resize: none;
}