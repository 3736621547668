
@import '../mixins/variable';

.gw-tags {
  & ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &.gw-tags--inline {
    & li:not(:last-child) {
      margin: 0 4px 0 0;
      float: left;
    }
  }
}

.gw-tag {
  font-family: "Roboto", sans-serif;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(0, 0, 0, 0.12);
  padding: 1px 6px;
  border-radius: 3px;
  font-size: 1.3em;
  font-weight: 500;
  line-height: 2.5em;

  & button {
    width: 16px;
    height: 16px;
    border: 0;
    margin: 0 0 0 4px;
    cursor: pointer;
    background-color: transparent;

    & i {
      width: 100%;
      height: 100%;
      top: -1px;
      right: 0;
      filter: brightness(0.13);
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.12);
      border-radius: 8px;
    }
  }

  /**
    * @Colours
    */

  &--green {
    background-color: var(--primaryColorGreen);
    color: rgb(255, 255, 255);
    & i {
      filter: brightness(1) !important;
      &:hover {
        filter: brightness(0.13) !important;
      }
    }
    & button:hover {
      background-color: rgba(255, 255, 255, 0.12);
    }
  }
  &--pink {
    background-color: var(--primaryColorPink);
    color: rgb(255, 255, 255);
    & i {
      filter: brightness(1) !important;
      &:hover {
        filter: brightness(0.13) !important;
      }
    }
    & button:hover {
      background-color: rgba(255, 255, 255, 0.12);
    }
  }
  &--teal {
    background-color: var(--primaryColorTeal);
    color: rgb(255, 255, 255);
    & i {
      filter: brightness(1) !important;
      &:hover {
        filter: brightness(0.13) !important;
      }
    }
    & button:hover {
      background-color: rgba(255, 255, 255, 0.12);
    }
  }
  &--purple {
    background-color: var(--primaryColorPurple);
    color: rgb(255, 255, 255);
    & i {
      filter: brightness(1) !important;
      &:hover {
        filter: brightness(0.13) !important;
      }
    }
    & button:hover {
      background-color: rgba(255, 255, 255, 0.12);
    }
  }
  &--orange {
    background-color: var(--primaryColorOrange);
    color: rgb(255, 255, 255);
    & i {
      filter: brightness(1) !important;
      &:hover {
        filter: brightness(0.13) !important;
      }
    }
    & button:hover {
      background-color: rgba(255, 255, 255, 0.12);
    }
  }
  &--blue {
    background-color: var(--primaryColorBlue);
    color: rgb(255, 255, 255);
    & i {
      filter: brightness(1) !important;
      &:hover {
        filter: brightness(0.13) !important;
      }
    }
    & button:hover {
      background-color: rgba(255, 255, 255, 0.12);
    }
  }
}
