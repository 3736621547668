

@import '../mixins/variable';

.gw-checkbox {
    position: relative;
    display: block;
    padding-right: 1.25em;

    &__input {
        position: absolute;
        margin-top: .3em;
        overflow: visible;
        -webkit-appearance: none;
        border-radius: 4px;
        height: 16px;
        width: 16px;
        background: #D7E7FF;
        border: 1px solid #C3DAFF;

        &:checked:after {
            content: "";
            left: 5px;
            top: 2px;
            width: 4px;
            height: 10px;
            border: solid #FFF;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
        }

        &:checked {
            background-color: var(--primaryColor);
        }

        &:disabled ~ .gw-checkbox__input-label {
            color: hsl(208, 7%, 46%) !important;
        }
    }

    &__input-label {
        position: relative;
        display: inline-block;
        font-family: 'Open Sans', sans-serif;
        font-size: 1.4em;
        color: inherit;
        line-height: 1.5;
        margin: 0 0 .5em 1.8em;
    }

    // Modifiers

    &--no-form {
        position: relative;
        display: block;    
        padding-left: 1.5em;
    }

    &--inline {
        display: -ms-inline-flexbox;
        display: inline-flex;
        margin-right: 1.5em;

        &:not(:first-child) {
            padding-left: 0;
        }
    }

    & > input[type=checkbox] {
        box-sizing: border-box;
        padding: 0;

        &::after,
        &::before {
            box-sizing: border-box;
        }
    }
    
    & > input[type=checkbox] {
        box-sizing: border-box;
        padding: 0;
    
        &:active {
            background-color: darken(hsla(216, 100%, 92%, 0.35), 20%);
        }
    
        &:disabled,
        &[disabled] {
            pointer-events: none;
            background-color: hsla(216, 100%, 92%, 0.35);
            border: 1px solid hsla(216, 97%, 88%, 0.35);
        }
    }
}


