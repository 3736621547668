

@import '../mixins/variable';

.gw-settings {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border: 1px solid hsla(0, 0%, 0%, 0.125);
}

.gw-settings-pane {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr;
    gap: 1em;
    height: 100%;
    width: 100%;
    @include variable( background-color, --settingsDialogBackgroundColor,  --modal-content-bg, #fff );

    &__left {
        height: 100%;
    }

    &__right {
        height: 100%;
    }
}

.gw-settings-nav-area {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 1em 0;
    @include variable( background-color, --settingsDialogMenuBackgroundColor,  --th-grey-white, hsl(210, 10%, 96%) );

    &__menu {
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin: 5px 0;

        & > li {
            margin: 0;
            padding: 0;
            border: 0;
            background-color: transparent;
            cursor: pointer;
        }
    }

    &__branding {
        position: relative;
        margin-top: auto;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
}

.gw-settings-main {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @include variable( color, --modalTextColor,  --th-slate-dark, hsl(222, 22%, 18%) );
    @include variable( background-color, --settingsDialogBackgroundColor,  --modal-content-bg, #fff );
    padding: 1.8em;
    height: 100%;

    &__header {
        padding: 0;
        margin: 0;
    }

    &__body {
        height: 100%;
    }
}

.gw-settings-main-header {
    position: relative;
}


.gw-settings-body {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 40em;

    &__title {
        position: relative;
        font-family: "Roboto", sans-serif;
        font-weight: 900;
        -webkit-font-smoothing: antialiased;
        margin: .4em 0;
        @include variable( color, --modalTextColor,  --th-slate-dark, hsl(222, 22%, 18%) );
        font-size: 2em;
    }
    
    &__row {
        position: relative;
        width: 100%;
        margin-bottom: 1.4em;

        &>:not(:last-child) {
            margin-top: 2.25em;
        }

        & > .gw-group {
            align-items: flex-start;
        }
    }

    &__button-area {
            padding: 1em;
            margin-top: auto;
    }
}

.gw-settings-features {

    &__item {}
}

.gw-settings-feature {

    &__name {}
    &__icon {}
}

.gw-settings-field {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    &__label {
        font-family: "Open Sans", Arial, sans-serif;
        font-size: 1.3em;
        font-weight: 600;
        display: inline-block;
        margin: 0 0 .8em 0;
        padding-top: .9em;
        box-sizing: border-box;
        @include variable( color, --modalTextColor,  --th-slate-dark, hsl(222, 22%, 18%) );
    }
}

.gw-settings-pane-settings-title {
    position: relative;
    padding: 0;

    &__icon {
        position: relative;
        top: 0;
        right: .2em;
        height: 2em;
        @include variable( fill, --settingsTitleColor, --settings-title-color, hsl(211, 100%, 45%));
        margin: 0 3px 0 0;
        
    }
    &__text {
        flex: 1;
        font-family: "Open Sans", sans-serif;
        font-size: 1.6em;
        font-weight: 500;
        -webkit-font-smoothing: antialiased;
        margin: 5px 0;
        @include variable( color, --settingsTitleColor, --settings-title-color, hsl(211, 100%, 45%));
    }
}

.gw-settings-menu-button {
    margin: 0;
    border: 0;
    background-color: transparent;
    border-radius: 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    display: block;
    width: 100%;
    text-align: left;
    padding: 1.3em 2em;
    cursor: pointer;
    box-sizing: border-box;
    border-left: 5px solid transparent;
    text-decoration: none !important;
    -webkit-font-smoothing: antialiased;
    @include variable( color, --modalTextColor,  --th-slate-dark, hsl(222, 22%, 18%) );

    &:hover {
        @include variable( background-color, --settingsDialogItemBackgroundColorHover,  --settings-item-background-hover-color, hsl(212, 10%, 93%) );
        @include variable( color, --modalTextColor,  --th-slate-dark, hsl(222, 22%, 18%) );
    }

    &:active {
        @include variable( background-color, --settingsDialogItemBackgroundColorActive, --settings-item-background-active-color, hsl(220, 20%, 91%) );
        @include variable( color, --modalTextColor,  --th-slate-dark, hsl(222, 22%, 18%) );
    }


    &--selected {
        border-left: 4px solid;
        @include variable( border-color, --themeColorPrimary, --primaryColor, hsl(211, 100%, 45%));
        background-color: var( --th-grey);
        @include variable( background-color, --settingsDialogItemSelectedBackgroundColor, --th-grey, hsl(220, 20%, 91%));
        font-weight: 600;

        &:hover {
            @include variable( background-color, --settingsDialogItemSelectedBackgroundColor, --th-grey, hsl(220, 20%, 91%));
        }
    }
}

.gw-settings-logo {
    position: relative;
    text-align: center;
    color: #0DA0A0;
    height: 35px;
    margin: 1em;
    display: block;
}


/* React Setting panel css */

.settingsWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    z-index: 999999999999999;
  }
  
  .settings-modal-dialog {
    box-shadow: 0 3px 15px rgba(0,0,0,0.12), 0 5px 30px rgba(0,0,0,0.24);
    padding: 0;
    position: sticky;
    z-index: 2147483645;
    outline: none;
    border: 0px;
    text-align: left;
    margin: 0 auto;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 777px
  }