

@import '../mixins/variable';

.gw-color-picker {
    position: relative;
    border-radius: 10px;
    color: #484848;
    padding: 0;
    min-width: 260px;
    
    transition: visibility 0s, opacity 0.1s linear;
    
    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;
    
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 10px;
    }
    
    &__list-item {
        display: flex;
        justify-content: center;
    }
    
    &__button {
        border: 2px solid var(--fontColorDark);
        display: block;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        transition: border 0.2s cubic-bezier(0.4, 0, 1, 1);
        text-indent: -9999px;
        overflow: hidden;
        cursor: pointer;
    
        &:hover {
            border-color: #004C71;
        }
    }
}