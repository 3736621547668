@import '../mixins/variable';


/** Button
---------------------------------------------------------*/

.gw-button {
    position: relative;
    display: inline-block;
    font-family: "Open Sans", Arial, sans-serif;
    text-transform: none;
    padding: 10px 20px;
    height: 44px;
    min-width: 15px;
    font-size: 1.4em;
    font-weight: 600;
    user-select: none;
    overflow: visible;
    text-decoration: none;
    transition: background-color ease-in-out 0.06s;
    transform: none;
    line-height: 1.4;
    transition: color .10s ease-in-out,background-color .10s ease-in-out,border-color .10s ease-in-out,box-shadow .10s ease-in-out;
    cursor: pointer;
    @include variable( background-color, --defaultColor,  --defaultColor, hsl(210, 2%, 84%) );
    @include variable( color, --buttonTextColor, --fontColorDark, hsl(0, 0%, 0%));
    @include variable( border, --buttonBorder, --buttonBorderColor, 2px solid hsl(210, 2%, 84%));
    @include variable( border-radius, --buttonBorderRadius, --buttonBorderRadius, 30px);

    &:visited {
        text-decoration: none;
        @include variable( color, --buttonTextVisitedColor, --buttonTextVisitedColor, hsl(0, 0%, 0%));

    }

    &:hover {
        @include variable( background-color, --buttonBackgroundHoverColor, --defaultColorHover , hsl(210, 2%, 74%) );
        @include variable( color, --buttonTextHoverColor, --fontColorDark, hsl(0, 0%, 0%) );
        @include variable( border, --buttonBorderColorHover, --buttonBorderColorHover, 2px solid hsl(210, 2%, 74%) );
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:active {
        @include variable( background-color, --buttonBackgroundActiveColor, --defaultColorActive, hsl(210, 2%, 64%));
        @include variable( color, --buttonTextActiveColor, --fontColorDark, hsl(0, 0%, 0%));
        @include variable( border, --buttonBorderColorActive, --buttonBorderColorActive, 2px solid hsl(210, 2%, 74%));
    }

    & > svg{
        @include variable( fill, --iconFillColor,  --iconFillColor, hsl(0, 0%, 0%));
    }

    /** Class Modifiers
---------------------------------------------------------*/

&--dropdown {
    position: relative;
    z-index: 2;

    & > .gw-button__icon {
        height: 11px;
        width: 11px;
        margin-left: 8px;
    }
}

    &--no-background {
        @include variable( background-color, --buttonNoBackgroundColor, --primaryColor, hsl(211, 100%, 45%) );
        @include variable( color, --buttonNoBackgroundTextColor, --fontColorWhite, hsl(0, 0%, 100%));
        @include variable( fill, --buttonNoBackgroundIconColor, --fontColorWhite, hsl(0, 0%, 100%));
        @include variable( border, --buttonNoBackgroundBorder, --primaryButtonBorder, hsl(211, 100%, 45%));

        &:visited {
            text-decoration: none;
            @include variable( color, --primaryButtonTextColor, hsl(0, 0%, 100%), hsl(0, 0%, 100%));
        }

        &:hover {
                @include variable( background-color, --buttonNoBackgroundColorHover, --primaryColorHover, hsl(211, 100%, 35%));
                @include variable( color, --buttonNoBackgroundTextColor, --fontColorWhite, hsl(0, 0%, 100%));
                @include variable( border, --buttonNoBackgroundBorderHover, --primaryButtonBorderHover, 2px solid hsl(211, 100%, 35%));

        }

        &:active {
            @include variable( background-color, --buttonNoBackgroundColorActive, --primaryColorActive, hsl(211, 100%, 25%));
            @include variable( color, --buttonNoBackgroundTextColor, --fontColorWhite, hsl(0, 0%, 100%));
            @include variable( border, --buttonNoBackgroundBorderActive, --primaryButtonBorderActive, 2px solid hsl(211, 100%, 35%));
        }
    }

    &--primary {
        @include variable( background-color, --primaryButtonBackgroundColor, --primaryColor, hsl(211, 100%, 45%) );
        @include variable( color, --primaryButtonTextColor, --fontColorWhite, hsl(0, 0%, 100%));
        @include variable( border, --primaryButtonBorder, --primaryButtonBorder, hsl(211, 100%, 45%));
        @include variable( border-radius, --buttonBorderRadius, --buttonBorderRadius, 30px);

        &:visited {
            text-decoration: none;
            @include variable( color, --primaryButtonTextColor, hsl(0, 0%, 100%), hsl(0, 0%, 100%));
        }

        &:hover {
                @include variable( background-color, --primaryButtonBackgroundHoverColor, --primaryColorHover, hsl(211, 100%, 35%));
                @include variable( color, --primaryButtonTextHoverColor, --fontColorWhite, hsl(0, 0%, 100%));
                @include variable( border, --primaryButtonBorderHover, --primaryButtonBorderHover, 2px solid hsl(211, 100%, 35%));

        }

        &:active {
            @include variable( background-color, --primaryButtonBackgroundActiveColor, --primaryColorActive, hsl(211, 100%, 25%));
            @include variable( color, --primaryButtonTextActiveColor, --fontColorWhite, hsl(0, 0%, 100%));
            @include variable( border, --primaryButtonBorderActive, --primaryButtonBorderActive, 2px solid hsl(211, 100%, 35%));
        }

        & > svg{
            @include variable( fill, --buttonIconFillColor,  --buttonIconFillColor, hsl(0, 0%, 0%));
        }

    }

    &--secondary {

        @include variable( background-color, --secondaryButtonBackgroundColor, --secondaryColor, hsl(221, 20%, 33%));
        @include variable( color, --secondaryButtonTextColor, --fontColorWhite, hsl(0, 0%, 100%));
        @include variable( border, --secondaryButtonBorder, --secondaryButtonBorderColor, 2px solid hsl(221, 20%, 33%));
        @include variable( border-radius, --secondaryButtonBorderRadius, --buttonBorderRadius, 30px);

        &:visited {
            text-decoration: none;
            @include variable( color, --secondaryButtonTextColor, --fontColorWhite, hsl(0, 0%, 100%));
        }

        &:hover {

            @include variable( background-color, --secondaryButtonBackgroundHoverColor, --secondaryColorHover, hsl(221, 20%, 23%));
            @include variable( color, --secondaryButtonTextHoverColor, --fontColorWhite, hsl(0, 0%, 100%));
            @include variable( border, --secondaryButtonBorderHover, --secondaryButtonBorderColorHover, 2px solid hsl(221, 20%, 23%));
        }

        &:active {
            @include variable( background-color, --secondaryButtonBackgroundActiveColor, --secondaryColorActive, hsl(221, 20%, 13%));
            @include variable( color, --secondaryButtonTextActiveColor, --fontColorWhite, hsl(0, 0%, 100%));
            @include variable( border, --secondaryButtonBorderColorActive, --secondaryButtonBorderColorActive, 2px solid hsl(221, 20%, 13%));
        }

        & > svg{
            @include variable( fill, --secondaryButtonIconFillColor,  --secondaryButtonIconFillColor, hsl(0, 0%, 0%));
        }
    }

    &:disabled,
    &[disabled] {
        pointer-events: none;
        opacity: 0.36;

        & .gw-button__icon {
            opacity: 0.36;
        }
    }

    &--s1 {
        padding: 6px 15px;
        font-size: 1.3em;
        height: 36px;
        line-height: 1.5;
    }

    &--s2 {
        padding: 10px 20px;
        font-size: 1.5em;
        height: 44px;
        line-height: 1.4;
    }

    &--s3 {
        padding: 16px 38px;
        font-size: 1.7em;
        height: 56px;
        line-height: 1.3;
    }

    /**
     * @Full Width
     */

    &--full-width {
        width: 100%;
    }

    /** Class Elements
    ---------------------------------------------------------*/

    &__icon {
        position: relative;
        top: -1px;
        right: 2px;
        height: 18px;
        width: 18px;
        vertical-align: middle;
        margin: 0 .2em 0 0;

        // left of button with text right
        &--left {margin: 0 .2em 0 0!important}
        // right of button with left text
        &--right {margin: 0 0 0 .4em!important}

        //Use for s1 size buttons
        &--s1 {
            width: 12px;
            height: 12px;
            top: 0;
        }
    }

    &--double-icon {
        font-size: 1.3em;
        font-weight: 700;
        text-transform: uppercase;
    }

    /**
    * @Colours
    */

    &--green {
        background-color: var(--primaryColorGreen);
        border: var(--buttonBorderGreen);
        color: hsl(0, 0%, 100%);

        &:visited {
            color: hsl(0, 0%, 100%);
            text-decoration: none;
        }

        &:hover {
            background-color: var(--primaryColorGreenHover);
            border: var(--buttonBorderGreenHover);
            color: hsl(0, 0%, 100%);
        }

        &:active {
            background-color: var(--primaryColorGreenActive);
            border: var(--buttonBorderGreenActive);
            color: hsl(0, 0%, 100%);
        }
    }

    &--pink {
        background-color: var(--primaryColorPink);
        border: var(--buttonBorderPink);
        color: hsl(0, 0%, 100%);

        &:visited {
            color: hsl(0, 0%, 100%);
            text-decoration: none;
        }

        &:hover {
            background-color: var(--primaryColorPinkHover);
            border: var(--buttonBorderPinkHover);
            color: hsl(0, 0%, 100%);
        }

        &:active {
            background-color: var(--primaryColorPinkActive);
            border: var(--buttonBorderPinkActive);
            color: hsl(0, 0%, 100%);
        }
    }

    &--teal {
        background-color: var(--primaryColorTeal);
        border: var(--buttonBorderTeal);
        color: hsl(0, 0%, 100%);

        &:visited {
            color: hsl(0, 0%, 100%);
            text-decoration: none;
        }

        &:hover {
            background-color: var(--primaryColorTealHover);
            border: var(--buttonBorderTealHover);
            color: hsl(0, 0%, 100%);
        }

        &:active {
            background-color: var(--primaryColorTealActive);
            border: var(--buttonBorderTealActive);
            color: hsl(0, 0%, 100%);
        }
    }

    &--purple {
        background-color: var(--primaryColorPurple);
        border: var(--buttonBorderPurple);
        color: hsl(0, 0%, 100%);

        &:visited {
            color: hsl(0, 0%, 100%);
            text-decoration: none;
        }

        &:hover {
            background-color: var(--primaryColorPurpleHover);
            border: var(--buttonBorderPurpleHover);
            color: hsl(0, 0%, 100%);
        }

        &:active {
            background-color: var(--primaryColorPurpleActive);
            border: var(--buttonBorderPurpleActive);
            color: hsl(0, 0%, 100%);
        }
    }

    &--orange {
        background-color: var(--primaryColorOrange);
        border: var(--buttonBorderOrange);
        color: hsl(0, 0%, 100%);

        &:visited {
            color: hsl(0, 0%, 100%);
            text-decoration: none;
        }

        &:hover {
            background-color: var(--primaryColorOrangeHover);
            border: var(--buttonBorderOrangeHover);
            color: hsl(0, 0%, 100%);
        }

        &:active {
            background-color: var(--primaryColorOrangeActive);
            border: var(--buttonBorderOrangeActive);
            color: hsl(0, 0%, 100%);
        }
    }

    &--blue {
        background-color: var(--primaryColorBlue);
        border: var(--buttonBorderBlue);
        color: hsl(0, 0%, 100%);

        &:visited {
            color: hsl(0, 0%, 100%);
            text-decoration: none;
        }

        &:hover {
            background-color: var(--primaryColorBlueHover);
            border: var(--buttonBorderBlueHover);
            color: hsl(0, 0%, 100%);
        }

        &:active {
            background-color: var(--primaryColorBlueActive);
            border: var(--buttonBorderBlueActive);
            color: hsl(0, 0%, 100%);
        }
    }

    &--light {
        background-color: var(--launchpadButtonLight);
        border: var(--launchpadButtonLight);

        &:visited {
            text-decoration: none;
        }

        &:hover {
            background-color: var(--launchpadButtonLightHover);
            border: var(--launchpadButtonLightHover);
        }

        &:active {
            background-color: var(--launchpadButtonLightActive);
            border: var(--launchpadButtonLightActive);
        }
    }

    /**
    * @ Link
    */

    &--link {
        font-weight: 400;
        color: var(--buttonLinkTextColor)!important; //to remove in future? !important 
        background-color: transparent;
        border: 2px solid transparent;
        text-decoration: none;

        // &:link { }
        &:visited {
            color: var(--primaryColor);
        }

        &:hover {
            background-color: transparent;
            border: 2px solid transparent;
            color: var(--primaryColorHover);
            text-decoration: underline;
        }

        &:focus {
            background-color: transparent;
            border: 2px solid transparent;
            text-decoration: underline;
        }

        &:active {
            background-color: transparent;
            border: 2px solid transparent;
            color: var(--primaryColorActive);
        }
    }

    // Darkmode version of Link button
    &--link-dark {
        color: var(--fontColorWhite)!important;
        font-weight: 600;
        background-color: transparent;
        border: 2px solid transparent;
        text-decoration: none;

        &:visited {
            color: var(--fontColorWhite);
            text-decoration: none;
        }

        &:hover {
            color: var(--fontColorWhite);
            text-decoration: underline;
            background-color: transparent;
            border: 2px solid transparent;
        }

        &:active {
            color: hsl(0, 0%, 100%);
        }
    }

// For Icon Buttons
&--icon {
    background-color: var(--transparent);
    color: hsl(0, 0%, 100%);
    border: 2px solid transparent;
    @include variable( fill, --toolbarIconButtonFillColor, --fontColorDark, hsl(0, 0%, 0%));

    &:disabled,
    &[disabled] {

        background-color: var(--transparent);
        border-color: var(--transparent);
    }

    &:visited {
        color: var(--fontColorDark);
        text-decoration: none;
    }

    &:hover {
        background-color: var(--defaultColorHover);
        color: hsla(0, 0%, 0%, 0.6);
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:active {
        background-color: var(--defaultColorActive, hsl(210, 2%, 64%));
        color: var(--fontColorDark, hsl(0, 0%, 0%));
    }
}
}

::slotted(.gw-button__icon){
    position: relative;
    top: -1px;
    right: 2px;
    height: 18px;
    width: 18px;
    vertical-align: middle;
    margin: 0;
}

