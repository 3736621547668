@import '../mixins/variable';

.gw-menu-notification {
    position: absolute;
    top: 4px;
    right: 2px;
    width: 8px;
    height: 8px;
    @include variable( background-color,  --menuNotifyBackgroundColour,  --default-menu-notify-background-colour,hsl(264, 100%, 68%) );
    border-radius: 1em;
    //border: 2px solid var(--toolbarBackgroundColor, var(--white));

    &--inline {
        position: static;
        float: right;
        width: 8px;
        height: 8px;
        border: 0;
        margin-top: 4px;
    }
}
