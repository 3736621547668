
@import '../mixins/variable';

.gw-toggle-checkbox {

    &__input {
        display: block;
        -webkit-appearance: none;
        cursor: pointer;
    
        &:hover:before {
            filter: drop-shadow(1px 1px 2px hsla(0, 0%, 40%, 0.762));
        }
    
        &:checked:before {
            // Want to ensure that the Data URI is formatted with double-quotes
    
            /* stylelint-disable-next-line */
            content: url(svg-data-uri("0 0 24.905 16.056", '<g>	<path fill="#21BA45" d="M22,7.504c0,2.485-2.015,4.5-4.5,4.5h-13c-2.485,0-4.5-2.015-4.5-4.5l0,0c0-2.485,2.015-4.5,4.5-4.5h13		C19.985,3.004,22,5.018,22,7.504L22,7.504z"/><g><g><circle fill="#DDDDDD" cx="17.029" cy="7.864" r="6.548"/></g><g opacity="0.04"><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="17.0286" y1="14.4113" x2="17.0286" y2="1.3161"><stop  offset="0" style="stop-color:#000000"/><stop offset="0.1375" style="stop-color:#000000;stop-opacity:0.8625"/><stop offset="1" style="stop-color:#000000;stop-opacity:0"/></linearGradient>			<path fill="url(#SVGID_1_)" d="M17.029,1.643c3.43,0,6.22,2.79,6.22,6.22c0,3.43-2.79,6.22-6.22,6.22s-6.22-2.79-6.22-6.22				C10.808,4.434,13.599,1.643,17.029,1.643 M17.029,1.316c-3.616,0-6.548,2.932-6.548,6.548c0,3.616,2.931,6.548,6.548,6.548				c3.616,0,6.548-2.931,6.548-6.548C23.576,4.248,20.645,1.316,17.029,1.316L17.029,1.316z"/>		</g>	</g></g>', (height: "25px")));
        }
    
        &:before {
            // Want to ensure that the Data URI is formatted with double-quotes
    
            /* stylelint-disable-next-line */
            content: url(svg-data-uri("0 0 24.905 16.056", "<g style='box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);'>	<path opacity=\"0.26\" fill=\"#221F1F\" d=\"M24.905,8.159c0,2.531-2.049,5-4.576,5H7.22c-2.527,0-4.576-2.469-4.576-5l0,0		c0-2.531,2.049-5,4.576-5h13.109C22.856,3.159,24.905,5.627,24.905,8.159L24.905,8.159z\"/>	<g>		<g>			<circle fill=\"#FBFBFB\" cx=\"7.864\" cy=\"7.864\" r=\"6.548\"/>		</g>		<g>			<circle fill=\"#F1F1F1\" cx=\"7.864\" cy=\"7.864\" r=\"6.548\"/>		</g>		<g opacity=\"0.04\">			<linearGradient id=\"SVGID_1_\" gradientUnits=\"userSpaceOnUse\" x1=\"7.8637\" y1=\"14.4113\" x2=\"7.8637\" y2=\"1.3161\">				<stop  offset=\"0\" style=\"stop-color:#000000\"/>				<stop  offset=\"0.1375\" style=\"stop-color:#000000;stop-opacity:0.8625\"/>				<stop  offset=\"1\" style=\"stop-color:#000000;stop-opacity:0\"/>			</linearGradient>			<path fill=\"url(#SVGID_1_)\" d=\"M7.864,1.643c3.43,0,6.22,2.79,6.22,6.22c0,3.43-2.79,6.22-6.22,6.22c-3.43,0-6.22-2.79-6.22-6.22				C1.643,4.434,4.434,1.643,7.864,1.643 M7.864,1.316c-3.616,0-6.548,2.932-6.548,6.548c0,3.616,2.931,6.548,6.548,6.548				s6.548-2.931,6.548-6.548C14.411,4.248,11.48,1.316,7.864,1.316L7.864,1.316z\"/>		</g>		<g opacity=\"0.12\">			<linearGradient id=\"SVGID_2_\" gradientUnits=\"userSpaceOnUse\" x1=\"7.8637\" y1=\"14.4113\" x2=\"7.8637\" y2=\"1.3161\">				<stop  offset=\"0\" style=\"stop-color:#FFFFFF;stop-opacity:0\"/>				<stop  offset=\"0.8625\" style=\"stop-color:#FFFFFF;stop-opacity:0.8625\"/>				<stop  offset=\"1\" style=\"stop-color:#FFFFFF\"/>			</linearGradient>			<path fill=\"url(#SVGID_2_)\" d=\"M7.864,1.643c3.43,0,6.22,2.79,6.22,6.22c0,3.43-2.79,6.22-6.22,6.22c-3.43,0-6.22-2.79-6.22-6.22				C1.643,4.434,4.434,1.643,7.864,1.643 M7.864,1.316c-3.616,0-6.548,2.932-6.548,6.548c0,3.616,2.931,6.548,6.548,6.548				s6.548-2.931,6.548-6.548C14.411,4.248,11.48,1.316,7.864,1.316L7.864,1.316z\"/>		</g>	</g></g>", (height: "25px")));
            display: block;
        }
    }
    
} 