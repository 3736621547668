@import '../mixins/variable';

.gw-list-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin: 0;
    border-radius: .8em;
    @include variable( background-color, --listGroupBackgroundColor,  --list-group-background-color, transparent );

    &__item {
        width: 100%;
        box-sizing: border-box;

        &:first-of-type {
            border-top-left-radius: .6em;
            border-top-right-radius: .6em;
        }
    
         &:last-of-type {
            border-bottom-right-radius: .6em;
            border-bottom-left-radius: .6em;
        }

    }

    &__item {
        position: relative;
        display: block;
        font-family: 'Open Sans', sans-serif;
        font-size: 1.5em;
        font-weight: 400;
        padding: 1.1em 1.3em;
        @include variable( background-color, --listGroupItemBackgroundColor,  --list-group-item-background-color, #fff );
        border: 1px solid rgba(0,0,0,.125);
        margin-bottom: -1px;
        @include variable( color, --listGroupItemTextColor,  --th-slate-dark, hsl(222, 22%, 18%) );
    
        &--no-padding {
            padding: 0;
        }
    
        &__button {
            margin: 0;
            border: 0;
            background-color: transparent;
            @include variable( background-color, --listGroupItemBackgroundColor,  --list-group-item-background-color, #fff );
            border-radius: 0;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            display: block;
            width: 100%;
            text-align: left;
            padding: 10px;
            cursor: pointer;
            box-sizing: border-box;
            text-decoration: none!important;
            -webkit-font-smoothing: antialiased;
        }
    
        &:hover {
            @include variable( background-color, --listGroupItemBackgroundColorHover,  --list-group-item-background-hover-color, hsl(210, 10%, 96%) );
            @include variable( color, --modalTextColor,  --th-slate-dark, hsl(222, 22%, 18%) );
        }
    
        &:active {
            @include variable( background-color, --listGroupItemBackgroundColorActive, --list-group-item-background-active-color, hsl(212, 10%, 93%));
            @include variable( color, --modalTextColor,  --th-slate-dark, hsl(222, 22%, 18%) );
        }
    
        &--transparent {
            background-color: transparent;
        }
    
        &--current {
            background-color: var( --list-item-hover);
            font-weight: 600;
            @include variable( color, --modalTextColor,  --th-slate-dark, hsl(222, 22%, 18%) );
        }
    
        &--nav-menu {
            font-family: "Roboto", sans-serif;
            border: none;
            margin-bottom: 0;
            padding: .8em 1.3em;
        }

        &--nav-sub-menu {
            font-family: "Roboto", sans-serif;
            border: none;
            margin-bottom: 0;
            padding: .8em 1.3em .8em 4em;
            line-height: 1.5em;
        }
    
        &--tile {
            position: relative;
            font-family: "Roboto", sans-serif;
            border: none;
            margin-bottom: 0;
            padding: .52em .3em;
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            border-top-left-radius: 1.2em;
            border-bottom-left-radius: 1.2em;
    
            &:not(:last-child) {
                margin-bottom: .4em;
            }
    
            &:first-child {
                border-top-left-radius: 1.2em;
                border-bottom-left-radius: 1.2em;
            }
    
            &:hover {
                @include variable( background-color, --listGroupItemBackgroundColor, --list-group-item-background-color,  hsl(0, 0%, 100%) );
                //background-color: var( --white);
            }
    
            &:active {
                @include variable( background-color, --listGroupItemBackgroundColor, --list-group-item-background-color,  hsl(0, 0%, 100%) );
                //background-color: var( --white);
            }
    
        }
    
        &__icon {
            position: relative;
            top: -1px;
            right: .4em;
            height: 18px;
            width: 18px;
            vertical-align: middle;
            @include variable( fill, --listGroupItemIconColor, --list-group-item-icon-color, hsl(222, 22%, 18%));
            margin: 0;
        }

        &__icon-app {
            position: relative;
            display: inline-flex;
            width: 21px;
            height: 21px;
            vertical-align: middle;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
        }

        &--not-clickable {
            pointer-events: none;
            user-select: none;
        }

        &--first-item {
            border-top-left-radius: .6em!important;
            border-top-right-radius: .6em!important;
        }

        &--last-item {
            border-bottom-right-radius: .6em!important;
            border-bottom-left-radius: .6em!important;
        }

        &--actions {
            @include variable( background-color, --listGroupItemActionsBackgroundColor,  --th-grey-white, hsl(210, 10%, 97%));
        }

    }

    &--mobile li {
        font-size: 1.8em;
    }
}

