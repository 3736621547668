/** Button
---------------------------------------------------------*/
.gw-button {
  position: relative;
  display: inline-block;
  font-family: "Open Sans", Arial, sans-serif;
  text-transform: none;
  padding: 10px 20px;
  height: 44px;
  min-width: 15px;
  font-size: 1.4em;
  font-weight: 600;
  user-select: none;
  overflow: visible;
  text-decoration: none;
  transition: background-color ease-in-out 0.06s;
  transform: none;
  line-height: 1.4;
  transition: color .10s ease-in-out,background-color .10s ease-in-out,border-color .10s ease-in-out,box-shadow .10s ease-in-out;
  cursor: pointer;
  background-color: #d5d6d7;
  background-color: var(--defaultColor, var(--defaultColor));
  color: black;
  color: var(--buttonTextColor, var(--fontColorDark));
  border: 2px solid #d5d6d7;
  border: var(--buttonBorder, var(--buttonBorderColor));
  border-radius: 30px;
  border-radius: var(--buttonBorderRadius, var(--buttonBorderRadius));
  /** Class Modifiers
---------------------------------------------------------*/
  /**
     * @Full Width
     */
  /** Class Elements
    ---------------------------------------------------------*/
  /**
    * @Colours
    */
  /**
    * @ Link
    */ }
  .gw-button:visited {
    text-decoration: none;
    color: black;
    color: var(--buttonTextVisitedColor, var(--buttonTextVisitedColor)); }
  .gw-button:hover {
    background-color: #bbbdbe;
    background-color: var(--buttonBackgroundHoverColor, var(--defaultColorHover));
    color: black;
    color: var(--buttonTextHoverColor, var(--fontColorDark));
    border: 2px solid #bbbdbe;
    border: var(--buttonBorderColorHover, var(--buttonBorderColorHover)); }
  .gw-button:hover, .gw-button:focus {
    text-decoration: none; }
  .gw-button:active {
    background-color: #a1a3a5;
    background-color: var(--buttonBackgroundActiveColor, var(--defaultColorActive));
    color: black;
    color: var(--buttonTextActiveColor, var(--fontColorDark));
    border: 2px solid #bbbdbe;
    border: var(--buttonBorderColorActive, var(--buttonBorderColorActive)); }
  .gw-button > svg {
    fill: black;
    fill: var(--iconFillColor, var(--iconFillColor)); }
  .gw-button--dropdown {
    position: relative;
    z-index: 2; }
    .gw-button--dropdown > .gw-button__icon {
      height: 11px;
      width: 11px;
      margin-left: 8px; }
  .gw-button--no-background {
    background-color: #006fe6;
    background-color: var(--buttonNoBackgroundColor, var(--primaryColor));
    color: white;
    color: var(--buttonNoBackgroundTextColor, var(--fontColorWhite));
    fill: white;
    fill: var(--buttonNoBackgroundIconColor, var(--fontColorWhite));
    border: #006fe6;
    border: var(--buttonNoBackgroundBorder, var(--primaryButtonBorder)); }
    .gw-button--no-background:visited {
      text-decoration: none;
      color: white;
      color: var(--primaryButtonTextColor, var(white)); }
    .gw-button--no-background:hover {
      background-color: #0056b3;
      background-color: var(--buttonNoBackgroundColorHover, var(--primaryColorHover));
      color: white;
      color: var(--buttonNoBackgroundTextColor, var(--fontColorWhite));
      border: 2px solid #0056b3;
      border: var(--buttonNoBackgroundBorderHover, var(--primaryButtonBorderHover)); }
    .gw-button--no-background:active {
      background-color: #003e80;
      background-color: var(--buttonNoBackgroundColorActive, var(--primaryColorActive));
      color: white;
      color: var(--buttonNoBackgroundTextColor, var(--fontColorWhite));
      border: 2px solid #0056b3;
      border: var(--buttonNoBackgroundBorderActive, var(--primaryButtonBorderActive)); }
  .gw-button--primary {
    background-color: #006fe6;
    background-color: var(--primaryButtonBackgroundColor, var(--primaryColor));
    color: white;
    color: var(--primaryButtonTextColor, var(--fontColorWhite));
    border: #006fe6;
    border: var(--primaryButtonBorder, var(--primaryButtonBorder));
    border-radius: 30px;
    border-radius: var(--buttonBorderRadius, var(--buttonBorderRadius)); }
    .gw-button--primary:visited {
      text-decoration: none;
      color: white;
      color: var(--primaryButtonTextColor, var(white)); }
    .gw-button--primary:hover {
      background-color: #0056b3;
      background-color: var(--primaryButtonBackgroundHoverColor, var(--primaryColorHover));
      color: white;
      color: var(--primaryButtonTextHoverColor, var(--fontColorWhite));
      border: 2px solid #0056b3;
      border: var(--primaryButtonBorderHover, var(--primaryButtonBorderHover)); }
    .gw-button--primary:active {
      background-color: #003e80;
      background-color: var(--primaryButtonBackgroundActiveColor, var(--primaryColorActive));
      color: white;
      color: var(--primaryButtonTextActiveColor, var(--fontColorWhite));
      border: 2px solid #0056b3;
      border: var(--primaryButtonBorderActive, var(--primaryButtonBorderActive)); }
    .gw-button--primary > svg {
      fill: black;
      fill: var(--buttonIconFillColor, var(--buttonIconFillColor)); }
  .gw-button--secondary {
    background-color: #434e65;
    background-color: var(--secondaryButtonBackgroundColor, var(--secondaryColor));
    color: white;
    color: var(--secondaryButtonTextColor, var(--fontColorWhite));
    border: 2px solid #434e65;
    border: var(--secondaryButtonBorder, var(--secondaryButtonBorderColor));
    border-radius: 30px;
    border-radius: var(--secondaryButtonBorderRadius, var(--buttonBorderRadius)); }
    .gw-button--secondary:visited {
      text-decoration: none;
      color: white;
      color: var(--secondaryButtonTextColor, var(--fontColorWhite)); }
    .gw-button--secondary:hover {
      background-color: #2f3646;
      background-color: var(--secondaryButtonBackgroundHoverColor, var(--secondaryColorHover));
      color: white;
      color: var(--secondaryButtonTextHoverColor, var(--fontColorWhite));
      border: 2px solid #2f3646;
      border: var(--secondaryButtonBorderHover, var(--secondaryButtonBorderColorHover)); }
    .gw-button--secondary:active {
      background-color: #1b1f28;
      background-color: var(--secondaryButtonBackgroundActiveColor, var(--secondaryColorActive));
      color: white;
      color: var(--secondaryButtonTextActiveColor, var(--fontColorWhite));
      border: 2px solid #1b1f28;
      border: var(--secondaryButtonBorderColorActive, var(--secondaryButtonBorderColorActive)); }
    .gw-button--secondary > svg {
      fill: black;
      fill: var(--secondaryButtonIconFillColor, var(--secondaryButtonIconFillColor)); }
  .gw-button:disabled, .gw-button[disabled] {
    pointer-events: none;
    opacity: 0.36; }
    .gw-button:disabled .gw-button__icon, .gw-button[disabled] .gw-button__icon {
      opacity: 0.36; }
  .gw-button--s1 {
    padding: 6px 15px;
    font-size: 1.3em;
    height: 36px;
    line-height: 1.5; }
  .gw-button--s2 {
    padding: 10px 20px;
    font-size: 1.5em;
    height: 44px;
    line-height: 1.4; }
  .gw-button--s3 {
    padding: 16px 38px;
    font-size: 1.7em;
    height: 56px;
    line-height: 1.3; }
  .gw-button--full-width {
    width: 100%; }
  .gw-button__icon {
    position: relative;
    top: -1px;
    right: 2px;
    height: 18px;
    width: 18px;
    vertical-align: middle;
    margin: 0 .2em 0 0; }
    .gw-button__icon--left {
      margin: 0 0.2em 0 0 !important; }
    .gw-button__icon--right {
      margin: 0 0 0 0.4em !important; }
    .gw-button__icon--s1 {
      width: 12px;
      height: 12px;
      top: 0; }
  .gw-button--double-icon {
    font-size: 1.3em;
    font-weight: 700;
    text-transform: uppercase; }
  .gw-button--green {
    background-color: var(--primaryColorGreen);
    border: var(--buttonBorderGreen);
    color: white; }
    .gw-button--green:visited {
      color: white;
      text-decoration: none; }
    .gw-button--green:hover {
      background-color: var(--primaryColorGreenHover);
      border: var(--buttonBorderGreenHover);
      color: white; }
    .gw-button--green:active {
      background-color: var(--primaryColorGreenActive);
      border: var(--buttonBorderGreenActive);
      color: white; }
  .gw-button--pink {
    background-color: var(--primaryColorPink);
    border: var(--buttonBorderPink);
    color: white; }
    .gw-button--pink:visited {
      color: white;
      text-decoration: none; }
    .gw-button--pink:hover {
      background-color: var(--primaryColorPinkHover);
      border: var(--buttonBorderPinkHover);
      color: white; }
    .gw-button--pink:active {
      background-color: var(--primaryColorPinkActive);
      border: var(--buttonBorderPinkActive);
      color: white; }
  .gw-button--teal {
    background-color: var(--primaryColorTeal);
    border: var(--buttonBorderTeal);
    color: white; }
    .gw-button--teal:visited {
      color: white;
      text-decoration: none; }
    .gw-button--teal:hover {
      background-color: var(--primaryColorTealHover);
      border: var(--buttonBorderTealHover);
      color: white; }
    .gw-button--teal:active {
      background-color: var(--primaryColorTealActive);
      border: var(--buttonBorderTealActive);
      color: white; }
  .gw-button--purple {
    background-color: var(--primaryColorPurple);
    border: var(--buttonBorderPurple);
    color: white; }
    .gw-button--purple:visited {
      color: white;
      text-decoration: none; }
    .gw-button--purple:hover {
      background-color: var(--primaryColorPurpleHover);
      border: var(--buttonBorderPurpleHover);
      color: white; }
    .gw-button--purple:active {
      background-color: var(--primaryColorPurpleActive);
      border: var(--buttonBorderPurpleActive);
      color: white; }
  .gw-button--orange {
    background-color: var(--primaryColorOrange);
    border: var(--buttonBorderOrange);
    color: white; }
    .gw-button--orange:visited {
      color: white;
      text-decoration: none; }
    .gw-button--orange:hover {
      background-color: var(--primaryColorOrangeHover);
      border: var(--buttonBorderOrangeHover);
      color: white; }
    .gw-button--orange:active {
      background-color: var(--primaryColorOrangeActive);
      border: var(--buttonBorderOrangeActive);
      color: white; }
  .gw-button--blue {
    background-color: var(--primaryColorBlue);
    border: var(--buttonBorderBlue);
    color: white; }
    .gw-button--blue:visited {
      color: white;
      text-decoration: none; }
    .gw-button--blue:hover {
      background-color: var(--primaryColorBlueHover);
      border: var(--buttonBorderBlueHover);
      color: white; }
    .gw-button--blue:active {
      background-color: var(--primaryColorBlueActive);
      border: var(--buttonBorderBlueActive);
      color: white; }
  .gw-button--light {
    background-color: var(--launchpadButtonLight);
    border: var(--launchpadButtonLight); }
    .gw-button--light:visited {
      text-decoration: none; }
    .gw-button--light:hover {
      background-color: var(--launchpadButtonLightHover);
      border: var(--launchpadButtonLightHover); }
    .gw-button--light:active {
      background-color: var(--launchpadButtonLightActive);
      border: var(--launchpadButtonLightActive); }
  .gw-button--link {
    font-weight: 400;
    color: var(--buttonLinkTextColor) !important;
    background-color: transparent;
    border: 2px solid transparent;
    text-decoration: none; }
    .gw-button--link:visited {
      color: var(--primaryColor); }
    .gw-button--link:hover {
      background-color: transparent;
      border: 2px solid transparent;
      color: var(--primaryColorHover);
      text-decoration: underline; }
    .gw-button--link:focus {
      background-color: transparent;
      border: 2px solid transparent;
      text-decoration: underline; }
    .gw-button--link:active {
      background-color: transparent;
      border: 2px solid transparent;
      color: var(--primaryColorActive); }
  .gw-button--link-dark {
    color: var(--fontColorWhite) !important;
    font-weight: 600;
    background-color: transparent;
    border: 2px solid transparent;
    text-decoration: none; }
    .gw-button--link-dark:visited {
      color: var(--fontColorWhite);
      text-decoration: none; }
    .gw-button--link-dark:hover {
      color: var(--fontColorWhite);
      text-decoration: underline;
      background-color: transparent;
      border: 2px solid transparent; }
    .gw-button--link-dark:active {
      color: white; }
  .gw-button--icon {
    background-color: var(--transparent);
    color: white;
    border: 2px solid transparent;
    fill: black;
    fill: var(--toolbarIconButtonFillColor, var(--fontColorDark)); }
    .gw-button--icon:disabled, .gw-button--icon[disabled] {
      background-color: var(--transparent);
      border-color: var(--transparent); }
    .gw-button--icon:visited {
      color: var(--fontColorDark);
      text-decoration: none; }
    .gw-button--icon:hover {
      background-color: var(--defaultColorHover);
      color: rgba(0, 0, 0, 0.6); }
    .gw-button--icon:hover, .gw-button--icon:focus {
      text-decoration: none; }
    .gw-button--icon:active {
      background-color: var(--defaultColorActive, #a1a3a5);
      color: var(--fontColorDark, black); }

::slotted(.gw-button__icon) {
  position: relative;
  top: -1px;
  right: 2px;
  height: 18px;
  width: 18px;
  vertical-align: middle;
  margin: 0; }
