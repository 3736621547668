@import '../mixins/variable';

.gw-filter-dropdown {
    position: relative;
    // background-color: transparent;
    border-radius: 2px;


    &__select {
        //background-color: #FFF;
    }
    &__results {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        border-radius: 0;
        background-color: var(--ListGroupSearchItemColor);
    }
}

.gw-filter-list-button{
    display: flex;
    justify-content: space-between;
}
.gw-filter-list-button:hover{
    background-color: rgba(220, 223, 228, 0.5) !important;
}
.gw-filter-list-button:focus{
    background-color: rgba(220, 223, 228, 0.5) !important;
}

.gw-filter-dropdown-search-box {
    position: relative;
    display: flex;
    flex-direction: row;
    z-index: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);

    & > .gw-input {
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: var(--ListGroupSearchItemTextColor);
            opacity: 1; /* Firefox */
            }
          
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: var(--ListGroupSearchItemTextColor);
            }
          
            &::-ms-input-placeholder { /* Microsoft Edge */
            color: var(--ListGroupSearchItemTextColor);
            }
    }

    &__input {
        flex-grow: 2!important;
        padding: 0 3em 0 3em!important;
        //background: transparent url(svg-data-uri("0 0 18 18", '<path d="M692,390.31l-4.74-4.74a6.51,6.51,0,1,0-2,2l4.74,4.74a.72.72,0,0,0,1,0l1-1a.72.72,0,0,0,0-1Zm-10.21-3.86a4.32,4.32,0,1,1,4.31-4.31h0A4.31,4.31,0,0,1,681.79,386.45Z" transform="translate(-675.28 -375.62)" fill="#fff" /> ', (height: 22px, width: 22px))) no-repeat!important;
        background-repeat: no-repeat, repeat!important;
        background-position: left 1em top 52%, 0 0!important;
        background-size: 1.2em auto, 100%!important;
        fill: var(--toolbarIconFill)!important;
        border-bottom-left-radius: 0!important;
        border-bottom-right-radius: 0!important;
        color: var(--ListGroupSearchItemTextColor);
    }

    &__button-area {
        position: absolute;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        height: 100%;
        right: 0;

        & > .gw-button {
            // background-color: transparent;

            &:hover {
                //@include variable( background-color,  --iconButtonBackgroundHoverColor,  --icon-button-background-hover-color, rgba(220, 223, 228, 0.5) );
                border: 0;
            }
        }
    }
}

.gw-filter-dropdown-button {
    position: relative!important;
    display: flex!important;
    justify-content: center;
    align-items: center;
    //width: 2.5em!important;
    height: 100%!important;
    //padding: 0.8em 0.4em!important;
    border-radius: 0!important;
    border: 0!important;

    &:visited {
        text-decoration: none;
        background-color: transparent;
        border: 0;

    }

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:active {
        // background-color: transparent;
        border: 0;
    }

    & > .gw-filter-dropdown-button__clear {
        height: 1.5em;
        margin: 0!important;
        left: 0.4px!important;
    }

    & > .gw-filter-dropdown-button__show {
        height: 0.5em;
        margin: 0!important;
        width: 100%!important;
        left: 0.4px!important;
        top: .5px;
    }

    &__search-show {
        width: 12px;
        height: 12px;
    }

    &__search-clear {
        width: 18px;
        height: 18px;
    }
}

.hiddenContent {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.gw-list-group__item--no-radius{
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.gw-search__icon_filter {
    position: absolute;
    left: 10px;
    top: 15px;
    align-self: center;
}

.gw-icon-button__icon_filter{
    height: 16px !important;
    width: 16px !important;
}

.gw-filter-dropdown-list-group {
    position: relative;
    margin-top: -.1em;
    z-index: 2;
    border-radius: 0;
    max-height: 510px;
    overflow: scroll;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);

    & > .gw-list-group__item {
        
        &:first-child {
            border-radius: 0;
        }

        &.gw-search-menu__link:disabled {
            pointer-events: none;
            opacity: 0.36;
          }

        &:focus {
            background-color: rgba(220, 223, 228, 0.5) !important;
            .gw-filter-list-button {
                background-color: rgba(220, 223, 228, 0.5) !important;
            }
        }
    }

    &

    &__button {
        // background-color: transparent;
        color: var(--ListGroupSearchItemTextColor);
    }
}

.dark-bg {
    background-color: var(--th-slate-dark);
    padding: 2em;
}