.gw-thumbnail {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.gw-thumbnail-wrapper {
    display: inline-block;
    position: relative;
    width: 13.2em;
    height: 17em;
    border-radius: 1.6em;
    border: 0.4em solid var(--brandColorBlue);
    background-color: white;
    margin: 0.7em;

    &--add {
        display: flex;
        background-color: var(--th-grey);
        border: 0;
        justify-content: center;
        align-items: center;

        button {
            box-shadow: 0px 3px 9px rgba(0,0,0,0.62);
        }
    }

    &__number {
        margin-top: 0.4em;
        margin-left: 1em;

        & p {
            font-family: "Open Sans", Arial, sans-serif;
            font-size: 1.6em;
            font-weight: 600;
        }
    }

    &__close {
        margin-top: 0.4em;
        margin-right: 0.4em;
    }

    &__preview {
        position: relative;
        width: 10em;
        height: 10.8em;
        margin: 0.5em auto 0 auto;
        background-color: var(--th-grey);
        box-sizing: border-box;
    }
}

