

@import '../mixins/variable';

/**
* @ Signin
*/

.gw-button-signin {

    border: var(--buttonBorderColor);
    
    &--google:hover .gw-button__icon,
    &--google:active .gw-button__icon {
        content: url(svg-data-uri("0 0 17.64 18", '<path d="M-819.042,651.851a8.99,8.99,0,0,1-.958-4.042,8.987,8.987,0,0,1,.958-4.042A9,9,0,0,1-811,638.809a8.653,8.653,0,0,1,6.022,2.344l-2.581,2.582A4.864,4.864,0,0,0-811,642.388a5.364,5.364,0,0,0-5.036,3.711,5.41,5.41,0,0,0-.282,1.71,5.41,5.41,0,0,0,.282,1.71A5.365,5.365,0,0,0-811,653.23a5.409,5.409,0,0,0,3.047-.859,4.141,4.141,0,0,0,1.8-2.716H-811v-3.481h8.476a10.348,10.348,0,0,1,.164,1.84,8.777,8.777,0,0,1-2.684,6.615A8.593,8.593,0,0,1-811,656.809,9,9,0,0,1-819.042,651.851Z" transform="translate(820 -638.809)" fill="#fff"/>', (height: "16px")));
    }

    &--microsoft:hover .gw-button__icon,
    &--microsoft:active .gw-button__icon {
        content: url(svg-data-uri("0 0 18.517 18.517", '<path fill="#fff" d="M.717.717H8.9V8.9H.717zM9.617.717H17.8V8.9H9.617zM.717 9.617H8.9V17.8H.717zM9.617 9.617H17.8V17.8H9.617z"/>', (height: "16px")));
    }

    &--twitter:hover .gw-button__icon,
    &--twitter:active .gw-button__icon {
        content: url(svg-data-uri("0 0 18.53 15.057", '<path d="M80.827,113.507A10.744,10.744,0,0,0,91.644,102.69c0-.165,0-.328-.011-.491a7.735,7.735,0,0,0,1.9-1.968,7.589,7.589,0,0,1-2.184.6,3.815,3.815,0,0,0,1.671-2.1,7.619,7.619,0,0,1-2.414.923,3.805,3.805,0,0,0-6.479,3.467,10.793,10.793,0,0,1-7.835-3.972,3.8,3.8,0,0,0,1.177,5.075,3.773,3.773,0,0,1-1.726-.476v.048a3.8,3.8,0,0,0,3.05,3.727,3.8,3.8,0,0,1-1.717.065,3.806,3.806,0,0,0,3.552,2.64,7.628,7.628,0,0,1-4.721,1.631A7.738,7.738,0,0,1,75,111.8a10.763,10.763,0,0,0,5.827,1.7" transform="translate(-75 -98.45)" fill="#fff"/>', (height: "16px")));
    }

    &--facebook:hover .gw-button__icon,
    &--facebook:active .gw-button__icon {
        content: url(svg-data-uri("0 0 18.52 18.52", '<path d="M22.52,5.022V21.5A1.022,1.022,0,0,1,21.5,22.52H16.778V15.348h2.407l.36-2.8H16.778V10.769c0-.809.225-1.361,1.385-1.361h1.48v-2.5a19.8,19.8,0,0,0-2.157-.11,3.368,3.368,0,0,0-3.595,3.695v2.061H11.479v2.8h2.414V22.52H5.022A1.022,1.022,0,0,1,4,21.5V5.022A1.022,1.022,0,0,1,5.022,4H21.5A1.022,1.022,0,0,1,22.52,5.022Z" transform="translate(-4 -4)" fill="#fff"/>', (height: "16px")));
    }

    &--linkedin:hover .gw-button__icon,
    &--linkedin:active .gw-button__icon {
        content: url(svg-data-uri("0 0 18.52 18.52", '<path d="M21.149,4H5.367A1.352,1.352,0,0,0,4,5.335V21.183A1.353,1.353,0,0,0,5.367,22.52H21.149a1.355,1.355,0,0,0,1.371-1.336V5.335A1.354,1.354,0,0,0,21.149,4ZM9.493,19.781H6.746V10.943H9.493ZM8.12,9.735A1.593,1.593,0,1,1,9.712,8.142,1.593,1.593,0,0,1,8.12,9.735ZM19.781,19.781H17.036v-4.3c0-1.025-.019-2.344-1.427-2.344-1.429,0-1.648,1.117-1.648,2.269v4.372H11.215V10.943H13.85v1.208h.037a2.886,2.886,0,0,1,2.6-1.428c2.782,0,3.3,1.831,3.3,4.21Z" transform="translate(-4 -4)" fill="#fff"/>', (height: "16px")));
    }

    &--apple:hover .gw-button__icon,
    &--apple:active .gw-button__icon {
        content: url(svg-data-uri("0 0 17.08 20.325", '<path xmlns="http://www.w3.org/2000/svg" id="Icon_awesome-apple" data-name="Icon awesome-apple" d="M14.561,12.991A4.236,4.236,0,0,1,16.83,9.143a4.875,4.875,0,0,0-3.843-2.024c-1.611-.127-3.372.939-4.016.939-.681,0-2.242-.894-3.467-.894C2.972,7.205.281,9.184.281,13.209a11.31,11.31,0,0,0,.653,3.685c.581,1.665,2.677,5.749,4.864,5.681,1.144-.027,1.951-.812,3.44-.812,1.443,0,2.192.812,3.467.812,2.205-.032,4.1-3.744,4.656-5.414a4.5,4.5,0,0,1-2.8-4.17ZM11.993,5.54a4.274,4.274,0,0,0,1.089-3.29A4.812,4.812,0,0,0,10,3.834,4.341,4.341,0,0,0,8.839,7.1,3.811,3.811,0,0,0,11.993,5.54Z" transform="translate(-0.281 -2.25)" fill="#fff"/>', (height: "16px")));
    }
}