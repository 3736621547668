

@import '../mixins/variable';

.gw-select {
  width: 100%;
  height: calc(2em + .75em + .5em);
  line-height: 1.75;
  min-width: 175px;
  font-family: "Open Sans", Arial, sans-serif;
  /* stylelint-disable-next-line */
  background: hsl(0, 0%, 100%) url(svg-data-uri("0 0 18 18", '<path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" fill="#232937"/><path d="M0-.75h24v24H0z" fill="none"/>', (height: 22px, width: 22px))) no-repeat;
  @include variable( background-color, --settingsDialogBackgroundColor,  --modal-content-bg, #fff );
  background-repeat: no-repeat, repeat;
  background-position: right 0.9em top 40%, 0 0;
  background-size: 1.3em auto, 100%;
  border: 1px solid #DADADA;
  @include variable( border, --selectBorder, --select-border, 1px solid #DADADA );
  padding: 5px 30px 5px 10px;
  -webkit-appearance: none;
  font-size: 1.3em;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: block;
  @include variable( color, --modalTextColor,  --th-slate-dark, hsl(222, 22%, 18%) );
  cursor: pointer;

  &:hover {
    background-color: hsl(0, 0%, 97%);
    @include variable( background-color, --selectBackgroundColorHover,  --select-background-color-hover, hsl(0, 0%, 97%) );
  }

  &--dark {
    background: transparent url(svg-data-uri("0 0 18 18", '<path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" fill="#fff"/><path d="M0-.75h24v24H0z" fill="none"/>', (height: 22px, width: 22px))) no-repeat;
    background-repeat: no-repeat, repeat;
    background-position: right 0.9em top 40%, 0 0;
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;

    &:hover {
      background-color: hsla(0, 0%, 97%, 0.057);
    }
  }

  &--no-border {
    border: none;
  }
}
