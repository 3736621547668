@import "variables";
@import "../mixins/background-sprites";

.th-wq,
.th-th {
  --toolbarBackgroundColor: var(--defaultColor);

  --themeColorDefault: var(--defaultColor);
  --themeColorPrimary: var(--themeColorWriq);
  --themeColorSecondary: var(--secondaryColor);

  // Dashboard
  --dashboardTopColor: var(--brandColorTeal);
  --dashboardTitleColor: var(--themeColorWriq);

  // icon Fill
  --iconFillColor: var(--white);

  // default button
  --buttonBackgroundColor: var(--defaultColor);
  --buttonBackgroundHoverColor: var(--defaultColorHover);
  --buttonBackgroundActiveColor: var(--defaultColorActive);
  --buttonTextColor: var(--fontColorDark);
  --buttonTextHoverColor: var(--fontColorDark);
  --buttonTextActiveColor: var(--fontColorDark);
  --buttonTextVisitedColor: var(--fontColorDark);
  --buttonBorder: var(--buttonBorder);
  --buttonBorderHover: var(--buttonBorderColorHover);
  --buttonBorderRadius: var(--buttonRoundness);
  --buttonIconFillColor: var(--black);

  // primary button
  --primaryButtonBackgroundColor: var(--primaryColorTeal);
  --primaryButtonBackgroundHoverColor: var(--primaryColorTealHover);
  --primaryButtonBackgroundActiveColor: var(--primaryColorTealActive);
  --primaryButtonBorderActive: var(--buttonBorderTealActive);
  --primaryButtonTextColor: var(--fontColorWhite);
  --primaryButtonTextHoverColor: var(--fontColorWhite);
  --primaryButtonTextActiveColor: var(--fontColorWhite);
  --primaryButtonTextVisitedColor: var(--fontColorWhite);
  --primaryButtonBorder: var(--buttonBorderTeal);
  --primaryButtonBorderHover: var(--buttonBorderTealHover);
  --primaryButtonBorderRadius: var(--buttonRoundness);
  --primaryButtonIconFillColor: var(--white);

  // secondary button
  --secondaryButtonBackgroundColor: var(--secondaryColor);
  --secondaryButtonBackgroundHoverColor: var(--secondaryColorHover);
  --secondaryButtonBackgroundActiveColor: var(--secondaryColorActive);
  --secondaryButtonTextColor: var(--fontColorWhite);
  --secondaryButtonTextHoverColor: var(--fontColorWhite);
  --secondaryButtonTextActiveColor: var(--fontColorWhite);
  --secondaryButtonTextVisitedColor: var(--fontColorWhite);
  --secondaryButtonBorder: var(--secondaryButtonBorder);
  --secondaryButtonBorderHover: var(--secondaryButtonBorderColorHover);
  --secondaryButtonBorderRadius: var(--buttonRoundness);
  --secondaryButtonIconFillColor: var(--white);

  // Tables and Accordions
  --table-hover-item: var(--list-item-light-hover);

  // Settings Dialog
  --settingsTitleColor: var(--themeColorPrimary);
}

.th-rw {
  --themeColorDefault: var(--defaultColor);
  --themeColorPrimary: var(--themeColorReadWrite);
  --themeColorSecondary: var(--secondaryColor);

  // Toolbar
  --toolbarBackgroundColor: var(--defaultColor);
  --toolbarIconFill: var(--th-slate);
  --toolbarIconHoverFill: var(--th-slate);
  --toolbarIconActiveFill: var(--th-slate);
  --toolbarIconToggledHoverFill: var(--primaryColorPurple);
  // circle indicatior
  --toolbarIconToggleFill: var(--primaryColorPurple);
  --toolbarIconToggleHoverFill: var(--primaryColorPurple);

  // Dashboard
  --dashboardTopColor: var(--brandColorPurple);
  --dashboardTitleColor: var(--themeColorReadWrite);

  // icon Fill
  --iconFillColor: var(--th-slate-dark);

  // default button
  --buttonBackgroundColor: var(--defaultColor);
  --buttonBackgroundHoverColor: var(--defaultColorHover);
  --buttonBackgroundActiveColor: var(--defaultColorActive);
  --buttonTextColor: var(--fontColorDark);
  --buttonTextHoverColor: var(--fontColorDark);
  --buttonTextActiveColor: var(--fontColorDark);
  --buttonTextVisitedColor: var(--fontColorDark);
  --buttonBorder: var(--buttonBorder);
  --buttonBorderHover: var(--buttonBorderColorHover);
  --buttonBorderActive: var(--buttonBorderColorActive);
  --buttonBorderRadius: var(--buttonRoundness);
  --buttonIconFillColor: var(--black);

  // primary button
  --primaryButtonBackgroundColor: var(--primaryColorPurple);
  --primaryButtonBackgroundHoverColor: var(--primaryColorPurpleHover);
  --primaryButtonBackgroundActiveColor: var(--primaryColorPurpleActive);
  --primaryButtonBorderActive: var(--buttonBorderPurpleActive);
  --primaryButtonTextColor: var(--fontColorWhite);
  --primaryButtonTextHoverColor: var(--fontColorWhite);
  --primaryButtonTextActiveColor: var(--fontColorWhite);
  --primaryButtonTextVisitedColor: var(--fontColorWhite);
  --primaryButtonBorder: var(--buttonBorderPurple);
  --primaryButtonBorderHover: var(--buttonBorderPurpleHover);
  --primaryButtonBorderRadius: var(--buttonRoundness);
  --primaryButtonIconFillColor: var(--white);

  // secondary button
  --secondaryButtonBackgroundColor: var(--secondaryColor);
  --secondaryButtonBackgroundHoverColor: var(--secondaryColorHover);
  --secondaryButtonBackgroundActiveColor: var(--secondaryColorActive);
  --secondaryButtonTextColor: var(--fontColorWhite);
  --secondaryButtonTextHoverColor: var(--fontColorWhite);
  --secondaryButtonTextActiveColor: var(--fontColorWhite);
  --secondaryButtonTextVisitedColor: var(--fontColorWhite);
  --secondaryButtonBorder: var(--secondaryButtonBorder);
  --secondaryButtonBorderHover: var(--secondaryButtonBorderColorHover);
  --secondaryButtonBorderRadius: var(--buttonRoundness);
  --secondaryButtonIconFillColor: var(--white);

  // Tables and Accordions
  --table-hover-item: var(--list-item-light-hover);

  // Settings Dialog
  --settingsTitleColor: var(--themeColorPrimary);

  // Notify
  --menuNotifyBackgroundColour: var(--brandColorPurple);

  // Unilogin
  --uniloginBackgroundColor: #FAF7FF;
  --uniloginWindowBackgroundColor: var(--primaryColorPurple);
}

.th-pr {
  --themeColorDefault: var(--defaultColor);
  --themeColorPrimary: var(--themeColorPdfReader);
  --themeColorSecondary: var(--secondaryColor);

  // primary button
  --primaryButtonBackgroundColor: var(--primaryColorBlue);
  --primaryButtonBackgroundHoverColor: var(--primaryColorBlueHover);
  --primaryButtonBackgroundActiveColor: var(--primaryColorBlueActive);
  --primaryButtonBorderActive: var(--buttonBorderBlueActive);
  --primaryButtonTextColor: var(--fontColorWhite);
  --primaryButtonTextHoverColor: var(--fontColorWhite);
  --primaryButtonTextActiveColor: var(--fontColorWhite);
  --primaryButtonTextVisitedColor: var(--fontColorWhite);
  --primaryButtonBorder: var(--buttonBorderBlue);
  --primaryButtonBorderHover: var(--buttonBorderBlueHover);
  --primaryButtonBorderRadius: var(--buttonRoundness);
  --primaryButtonIconFillColor: var(--white);

  // Toolbar
  --toolbarBackgroundColor: var(--th-grey);
  --toolbarButtonBackgroundColor: var(--th-grey);
  --toolbarButtonBackgroundHoverColor: var(--ContrastPinkOrbitNoteHover);
  --toolbarButtonBackgroundActiveColor: var(--ContrastPinkOrbitNoteActive);
  --toolbarButtonBorderActive: var(--ContrastButtonBorderPink);

  --toolbarIconFill: var(--th-slate);
  --toolbarIconHoverFill: var(--white);
  --toolbarIconActiveFill: var(--white);
  --toolbarIconToggleFill: var(--ContrastPinkOrbitNoteActive);
  --toolbarIconToggleHoverFill: var(--white);
  --toolbarIconToggledHoverFill: var(--white);

  // Icon Buttons
  --iconButtonBackgroundDefaultColor: var(--icon-button-background-default-color);
  --iconButtonBackgroundHoverColor: var(--icon-button-background-hover-color);
  --iconButtonBackgroundActiveColor: var(--icon-button-background-active-color);
  --iconButtonBorderColor: 2px solid transparent;
  --iconFillColor: var(--th-slate);

  // Panels
  --panelBackgroundColor: var(--th-grey-light);
  --panelBackgroundColorLight: var(--white);
  --panelBackgroundColorDark: var(--th-grey);

  // Text
  --fontColor: var(--th-slate-dark);

  // Border
  --borderColor: 1px solid var(--th-grey-dark);

  // Settings Dialog
  --settingsTitleColor: var(--themeColorPrimary);

  // Notify 
  --menuNotifyBackgroundColour: var(--brandColorPink);

  // Dashboard
  --dashboardTopColor: var(--primaryColorBlue);
  --dashboardTitleColor: var(--themeColorEquatio);

  --cardIconFillColor: var(--primaryColorBlue);
}

.th-eq {
  --themeColorDefault: var(--defaultColor);
  --themeColorPrimary: var(--themeColorEquatio);
  --themeColorSecondary: var(--secondaryColor);

  // Dashboard
  --dashboardTopColor: var(--brandColorBlue);
  --dashboardTitleColor: var(--themeColorEquatio);

  // icon Fill
  --iconFillColor: var(--th-slate-dark);

  // default button
  --buttonBackgroundColor: var(--defaultColor);
  --buttonBackgroundHoverColor: var(--defaultColorHover);
  --buttonBackgroundActiveColor: var(--defaultColorActive);
  --buttonTextColor: var(--fontColorDark);
  --buttonTextHoverColor: var(--fontColorDark);
  --buttonTextActiveColor: var(--fontColorDark);
  --buttonTextVisitedColor: var(--fontColorDark);
  --buttonBorder: var(--buttonBorder);
  --buttonBorderHover: var(--buttonBorderColorHover);
  --buttonBorderRadius: var(--buttonRoundness);
  --buttonIconFillColor: var(--black);

  // primary button
  --primaryButtonBackgroundColor: var(--primaryColorBlue);
  --primaryButtonBackgroundHoverColor: var(--primaryColorBlueHover);
  --primaryButtonBackgroundActiveColor: var(--primaryColorBlueActive);
  --primaryButtonBorderActive: var(--buttonBorderBlueActive);
  --primaryButtonTextColor: var(--fontColorWhite);
  --primaryButtonTextHoverColor: var(--fontColorWhite);
  --primaryButtonTextActiveColor: var(--fontColorWhite);
  --primaryButtonTextVisitedColor: var(--fontColorWhite);
  --primaryButtonBorder: var(--buttonBorderBlue);
  --primaryButtonBorderHover: var(--buttonBorderBlueHover);
  --primaryButtonBorderRadius: var(--buttonRoundness);
  --primaryButtonIconFillColor: var(--white);

  // secondary button
  --secondaryButtonBackgroundColor: var(--secondaryColor);
  --secondaryButtonBackgroundHoverColor: var(--secondaryColorHover);
  --secondaryButtonBackgroundActiveColor: var(--secondaryColorActive);
  --secondaryButtonTextColor: var(--fontColorWhite);
  --secondaryButtonTextHoverColor: var(--fontColorWhite);
  --secondaryButtonTextActiveColor: var(--fontColorWhite);
  --secondaryButtonTextVisitedColor: var(--fontColorWhite);
  --secondaryButtonBorder: var(--secondaryButtonBorder);
  --secondaryButtonBorderHover: var(--secondaryButtonBorderColorHover);
  --secondaryButtonBorderRadius: var(--buttonRoundness);
  --secondaryButtonIconFillColor: var(--white);

  // Tables and Accordions
  --table-hover-item: var(--list-item-light-hover);

  // Settings Dialog
  --settingsTitleColor: var(--themeColorPrimary);

  // Unilogin
  --uniloginBackgroundColor: #F2F9FF;
  --uniloginWindowBackgroundColor: var(--brandColorBlueWCAG);
}

.th-ba {
  --themeColorDefault: var(--defaultColor);
  --themeColorPrimary: var(--themeColorBrowsealoud);
  --themeColorSecondary: var(--secondaryColor);

  --toolbarBackgroundColor: var(--th-grey);
  --toolbarIconButtonFillColor: var(--th-slate);

  --toolbarButtonBackgroundColor: var(--th-grey);
  --toolbarButtonBackgroundHoverColor: var(--primaryColorOrangeHover);
  --toolbarButtonBackgroundActiveColor: var(--primaryColorOrangeActive);
  --toolbarButtonBorderActive: var(--buttonBorderOrange);

  --toolbarIconFill: var(--th-slate);
  --toolbarIconHoverFill: var(--th-slate);
  --toolbarIconActiveFill: var(--primaryColorBlue);
  --toolbarIconToggleFill: var(--primaryColorBlue);
  --toolbarIconToggleHoverFill: var(--white);


  // Dashboard
  --dashboardTopColor: var(--brandColorOrange);
  --dashboardTitleColor: var(--themeColorBrowsealoud);

  // icon Fill
  --iconFillColor: var(--white);

  // default button
  --buttonBackgroundColor: var(--defaultColor);
  --buttonBackgroundHoverColor: var(--defaultColorHover);
  --buttonBackgroundActiveColor: var(--defaultColorActive);
  --buttonTextColor: var(--fontColorDark);
  --buttonTextHoverColor: var(--fontColorDark);
  --buttonTextActiveColor: var(--fontColorDark);
  --buttonTextVisitedColor: var(--fontColorDark);
  --buttonBorder: var(--buttonBorder);
  --buttonBorderHover: var(--buttonBorderColorHover);
  --buttonBorderRadius: var(--buttonRoundness);
  --buttonBorderIconFillColor: var(--black);

  // primary button
  --primaryButtonBackgroundColor: var(--primaryColorOrange);
  --primaryButtonBackgroundHoverColor: var(--primaryColorOrangeHover);
  --primaryButtonBackgroundActiveColor: var(--primaryColorOrangeActive);
  --primaryButtonBorderActive: var(--buttonBorderOrangeActive);
  --primaryButtonTextColor: var(--fontColorWhite);
  --primaryButtonTextHoverColor: var(--fontColorWhite);
  --primaryButtonTextActiveColor: var(--fontColorWhite);
  --primaryButtonTextVisitedColor: var(--fontColorWhite);
  --primaryButtonBorder: var(--buttonBorderOrange);
  --primaryButtonBorderHover: var(--buttonBorderOrangeHover);
  --primaryButtonBorderRadius: var(--buttonRoundness);
  --primaryButtonIconFillColor: var(--white);

  // secondary button
  --secondaryButtonBackgroundColor: var(--secondaryColor);
  --secondaryButtonBackgroundHoverColor: var(--secondaryColorHover);
  --secondaryButtonBackgroundActiveColor: var(--secondaryColorActive);
  --secondaryButtonTextColor: var(--fontColorWhite);
  --secondaryButtonTextHoverColor: var(--fontColorWhite);
  --secondaryButtonTextActiveColor: var(--fontColorWhite);
  --secondaryButtonTextVisitedColor: var(--fontColorWhite);
  --secondaryButtonBorder: var(--secondaryButtonBorder);
  --secondaryButtonBorderHover: var(--secondaryButtonBorderColorHover);
  --secondaryButtonBorderRadius: var(--buttonRoundness);
  --secondaryButtonIconFillColor: var(--white);

  // Tables and Accordions
  --table-hover-item: var(--list-item-light-hover);

  // Settings Dialog
  --settingsTitleColor: var(--themeColorPrimary);

  // Notify 
  --menuNotifyBackgroundColour: var(--white);

  & .gw-menu-notification--inline {
      --menuNotifyBackgroundColour: var(--themeColorBrowsealoud);
    }
}

.th-rd {
  font-size: 10px;
  --themeColorDefault: var(--defaultColor);
  --themeColorPrimary: var(--themeColorReachDeck);
  --themeColorSecondary: var(--secondaryColor);

  // Toolbar
  --toolbarBackgroundColor: var(--defaultColor);
  --toolbarIconFill: var(--th-slate);
  --toolbarIconHoverFill: var(--th-slate);
  --toolbarIconActiveFill: var(--th-slate);
  --toolbarIconToggledHoverFill: var(--primaryColorBlue);
  // circle indicatior
  --toolbarIconToggleFill: var(--primaryColorBlue);
  --toolbarIconToggleHoverFill: var(--primaryColorBlue);

  // Dashboard
  --dashboardTopColor: var(--th-grey);
  --dashboardTitleColor: var(--th-slate-dark);

  // icon Fill
  --iconFillColor: var(--th-slate-dark);

  // default button
  --buttonBackgroundColor: var(--defaultColor);
  --buttonBackgroundHoverColor: var(--defaultColorHover);
  --buttonBackgroundActiveColor: var(--defaultColorActive);
  --buttonTextColor: var(--fontColorDark);
  --buttonTextHoverColor: var(--fontColorDark);
  --buttonTextActiveColor: var(--fontColorDark);
  --buttonTextVisitedColor: var(--fontColorDark);
  --buttonBorder: var(--buttonBorder);
  --buttonBorderHover: var(--buttonBorderColorHover);
  --buttonBorderRadius: var(--buttonRoundness);
  --buttonBorderIconFillColor: var(--black);

  // primary button
  --primaryButtonBackgroundColor: var(--primaryColorReachDeck);
  --primaryButtonBackgroundHoverColor: var(--primaryColorReachDeckHover);
  --primaryButtonBackgroundActiveColor: var(--primaryColorReachDeckActive);
  --primaryButtonBorderActive: var(--buttonBorderReachDeckActive);
  --primaryButtonTextColor: var(--fontColorWhite);
  --primaryButtonTextHoverColor: var(--fontColorWhite);
  --primaryButtonTextActiveColor: var(--fontColorWhite);
  --primaryButtonTextVisitedColor: var(--fontColorWhite);
  --primaryButtonBorder: var(--buttonBorderReachDeck);
  --primaryButtonBorderHover: var(--buttonBorderReachDeckHover);
  --primaryButtonBorderRadius: var(--buttonRoundness);
  --primaryButtonIconFillColor: var(--white);

  // secondary button
  --secondaryButtonBackgroundColor: var(--secondaryColor);
  --secondaryButtonBackgroundHoverColor: var(--secondaryColorHover);
  --secondaryButtonBackgroundActiveColor: var(--secondaryColorActive);
  --secondaryButtonTextColor: var(--fontColorWhite);
  --secondaryButtonTextHoverColor: var(--fontColorWhite);
  --secondaryButtonTextActiveColor: var(--fontColorWhite);
  --secondaryButtonTextVisitedColor: var(--fontColorWhite);
  --secondaryButtonBorder: var(--secondaryButtonBorder);
  --secondaryButtonBorderHover: var(--secondaryButtonBorderColorHover);
  --secondaryButtonBorderRadius: var(--buttonRoundness);
  --secondaryButtonIconFillColor: var(--white);

  // Tables and Accordions
  --table-hover-item: var(--list-item-light-hover);

    // Settings Dialog
    --settingsTitleColor: var(--themeColorPrimary);
}

.th-ss {
  --themeColorDefault: var(--defaultColor);
  --themeColorPrimary: var(--themeColorSpeechStream);
  --themeColorSecondary: var(--secondaryColor);

  // Dashboard
  --dashboardTopColor: var(--brandColorPink);
  --dashboardTitleColor: var(--themeColorSpeechStream);

  // icon Fill
  --iconFillColor: var(--th-slate-dark);

  // default button
  --buttonBackgroundColor: var(--defaultColor);
  --buttonBackgroundHoverColor: var(--defaultColorHover);
  --buttonBackgroundActiveColor: var(--defaultColorActive);
  --buttonTextColor: var(--fontColorDark);
  --buttonTextHoverColor: var(--fontColorDark);
  --buttonTextActiveColor: var(--fontColorDark);
  --buttonTextVisitedColor: var(--fontColorDark);
  --buttonBorder: var(--buttonBorder);
  --buttonBorderHover: var(--buttonBorderColorHover);
  --buttonBorderRadius: var(--buttonRoundness);
  --buttonBorderIconFillColor: var(--black);

  // primary button
  --primaryButtonBackgroundColor: var(--themeColorSpeechStream);
  --primaryButtonBackgroundHoverColor: var(--primaryColorPinkHover);
  --primaryButtonBackgroundActiveColor: var(--primaryColorPinkActive);
  --primaryButtonBorderActive: var(--buttonBorderPinkActive);
  --primaryButtonTextColor: var(--fontColorWhite);
  --primaryButtonTextHoverColor: var(--fontColorWhite);
  --primaryButtonTextActiveColor: var(--fontColorWhite);
  --primaryButtonTextVisitedColor: var(--fontColorWhite);
  --primaryButtonBorder: var(--buttonBorderPink);
  --primaryButtonBorderHover: var(--buttonBorderPinkHover);
  --primaryButtonBorderRadius: var(--buttonRoundness);
  --primaryButtonIconFillColor: var(--white);

  // secondary button
  --secondaryButtonBackgroundColor: var(--secondaryColor);
  --secondaryButtonBackgroundHoverColor: var(--secondaryColorHover);
  --secondaryButtonBackgroundActiveColor: var(--secondaryColorActive);
  --secondaryButtonTextColor: var(--fontColorWhite);
  --secondaryButtonTextHoverColor: var(--fontColorWhite);
  --secondaryButtonTextActiveColor: var(--fontColorWhite);
  --secondaryButtonTextVisitedColor: var(--fontColorWhite);
  --secondaryButtonBorder: var(--secondaryButtonBorder);
  --secondaryButtonBorderHover: var(--secondaryButtonBorderColorHover);
  --secondaryButtonBorderRadius: var(--buttonRoundness);
  --secondaryButtonIconFillColor: var(--white);

  // Tables and Accordions
  --table-hover-item: var(--list-item-light-hover);

  // Settings Dialog
  --settingsTitleColor: var(--themeColorPrimary);
}

.th-sv,
.th-ft {
  --themeColorDefault: var(--defaultColor);
  --themeColorPrimary: var(--themeColorFluencyTutor);
  --themeColorSecondary: var(--secondaryColor);

  // Dashboard
  --dashboardTopColor: var(--brandColorGreen);
  --dashboardTitleColor: var(--themeColorFluencyTutor);

  // icon Fill
  --iconFillColor: var(--th-slate-dark);

  // default button
  --buttonBackgroundColor: var(--defaultColor);
  --buttonBackgroundHoverColor: var(--defaultColorHover);
  --buttonBackgroundActiveColor: var(--defaultColorActive);
  --buttonTextColor: var(--fontColorDark);
  --buttonTextHoverColor: var(--fontColorDark);
  --buttonTextActiveColor: var(--fontColorDark);
  --buttonTextVisitedColor: var(--fontColorDark);
  --buttonBorder: var(--buttonBorder);
  --buttonBorderHover: var(--buttonBorderColorHover);
  --buttonBorderRadius: var(--buttonRoundness);
  --buttonBorderIconFillColor: var(--black);

  // primary button
  --primaryButtonBackgroundColor: var(--themeColorFluencyTutor);
  --primaryButtonBackgroundHoverColor: var(--primaryColorGreenHover);
  --primaryButtonBackgroundActiveColor: var(--primaryColorGreenActive);
  --primaryButtonBorderActive: var(--buttonBorderGreenActive);
  --primaryButtonTextColor: var(--fontColorWhite);
  --primaryButtonTextHoverColor: var(--fontColorWhite);
  --primaryButtonTextActiveColor: var(--fontColorWhite);
  --primaryButtonTextVisitedColor: var(--fontColorWhite);
  --primaryButtonBorder: var(--buttonBorderGreen);
  --primaryButtonBorderHover: var(--buttonBorderGreenHover);
  --primaryButtonBorderRadius: var(--buttonRoundness);
  --primaryButtonIconFillColor: var(--white);

  // secondary button
  --secondaryButtonBackgroundColor: var(--secondaryColor);
  --secondaryButtonBackgroundHoverColor: var(--secondaryColorHover);
  --secondaryButtonBackgroundActiveColor: var(--secondaryColorActive);
  --secondaryButtonTextColor: var(--fontColorWhite);
  --secondaryButtonTextHoverColor: var(--fontColorWhite);
  --secondaryButtonTextActiveColor: var(--fontColorWhite);
  --secondaryButtonTextVisitedColor: var(--fontColorWhite);
  --secondaryButtonBorder: var(--secondaryButtonBorder);
  --secondaryButtonBorderHover: var(--secondaryButtonBorderColorHover);
  --secondaryButtonBorderRadius: var(--buttonRoundness);
  --secondaryButtonIconFillColor: var(--white);

  // Tables and Accordions
  --table-hover-item: var(--list-item-light-hover);

  // Settings Dialog
  --settingsTitleColor: var(--themeColorPrimary);
}

.th-cr,
.th-wh {

  --themeColorDefault: var(--defaultColor);
  --themeColorPrimary: var(--primaryColorClaroNavy);
  --themeColorSecondary: var(--secondaryColor);

  // default button
  --buttonBackgroundColor: var(--defaultColor);
  --buttonBackgroundHoverColor: var(--defaultColorHover);
  --buttonBackgroundActiveColor: var(--defaultColorActive);
  --buttonTextColor: var(--fontColorDark);
  --buttonTextHoverColor: var(--fontColorDark);
  --buttonTextActiveColor: var(--fontColorDark);
  --buttonTextVisitedColor: var(--fontColorDark);
  --buttonBorder: var(--buttonBorder);
  --buttonBorderHover: var(--buttonBorderColorHover);
  --buttonBorderRadius: var(--buttonRoundness);
  --buttonBorderIconFillColor: var(--black);

  // primary button
  --primaryButtonBackgroundColor: var(--primaryColorClaroNavy);
  --primaryButtonBackgroundHoverColor: var(--primaryColorClaroNavyHover);
  --primaryButtonBackgroundActiveColor: var(--primaryColorClaroNavyActive);
  --primaryButtonBorderActive: var(--buttonBorderClaroActive);
  --primaryButtonTextColor: var(--fontColorWhite);
  --primaryButtonTextHoverColor: var(--fontColorWhite);
  --primaryButtonTextActiveColor: var(--fontColorWhite);
  --primaryButtonTextVisitedColor: var(--fontColorWhite);
  --primaryButtonBorder: var(--buttonBorderClaro);
  --primaryButtonBorderHover: var(--buttonBorderClaroHover);
  --primaryButtonBorderRadius: var(--buttonRoundness);
  --primaryButtonIconFillColor: var(--white);

  // Alt green button
  --altButtonBackgroundColor: var(--altColor);
  --altButtonBackgroundHoverColor: var(--altColorHover);
  --altButtonBackgroundActiveColor: var(--altColorActive);
  --altButtonTextColor: var(--fontColorWhite);
  --altButtonTextHoverColor: var(--fontColorWhite);
  --altButtonTextActiveColor: var(--fontColorWhite);
  --altButtonTextVisitedColor: var(--fontColorWhite);
  --altButtonBorder: var(--altButtonBorder);
  --altButtonBorderHover: var(--altButtonBorderColorHover);
  --altButtonBorderRadius: var(--buttonRoundness);
  --altButtonIconFillColor: var(--white);

  // Unilogin
  --uniloginBackgroundColor: #F2F9FF;
  --uniloginWindowBackgroundColor: var(--themeColorPrimary);
}

.th-lds {

  --themeColorDefault: var(--defaultColor);
  --themeColorPrimary: var(--primaryColorLingdys);
  --themeColorSecondary: var(--secondaryColor);

  // default button
  --buttonBackgroundColor: var(--defaultColor);
  --buttonBackgroundHoverColor: var(--defaultColorHover);
  --buttonBackgroundActiveColor: var(--defaultColorActive);
  --buttonTextColor: var(--fontColorDark);
  --buttonTextHoverColor: var(--fontColorDark);
  --buttonTextActiveColor: var(--fontColorDark);
  --buttonTextVisitedColor: var(--fontColorDark);
  --buttonBorder: var(--buttonBorder);
  --buttonBorderHover: var(--buttonBorderColorHover);
  --buttonBorderRadius: var(--buttonRoundness);
  --buttonBorderIconFillColor: var(--black);

  // primary button
  --primaryButtonBackgroundColor: var(--primaryColorLingdys);
  --primaryButtonBackgroundHoverColor: var(--primaryColorLingdysHover);
  --primaryButtonBackgroundActiveColor: var(--primaryColorLingdysActive);
  --primaryButtonBorderActive: var(--buttonBorderLingdysActive);
  --primaryButtonTextColor: var(--fontColorWhite);
  --primaryButtonTextHoverColor: var(--fontColorWhite);
  --primaryButtonTextActiveColor: var(--fontColorWhite);
  --primaryButtonTextVisitedColor: var(--fontColorWhite);
  --primaryButtonBorder: var(--buttonBorderLingdys);
  --primaryButtonBorderHover: var(--buttonBorderLingdysHover);
  --primaryButtonBorderRadius: var(--buttonRoundness);
  --primaryButtonIconFillColor: var(--white);

  // Alt green button
  --altButtonBackgroundColor: var(--altColor);
  --altButtonBackgroundHoverColor: var(--altColorHover);
  --altButtonBackgroundActiveColor: var(--altColorActive);
  --altButtonTextColor: var(--fontColorWhite);
  --altButtonTextHoverColor: var(--fontColorWhite);
  --altButtonTextActiveColor: var(--fontColorWhite);
  --altButtonTextVisitedColor: var(--fontColorWhite);
  --altButtonBorder: var(--altButtonBorder);
  --altButtonBorderHover: var(--altButtonBorderColorHover);
  --altButtonBorderRadius: var(--buttonRoundness);
  --altButtonIconFillColor: var(--white);

  // Unilogin
  --uniloginBackgroundColor: #F2F9FF;
  --uniloginWindowBackgroundColor: var(--themeColorPrimary);
}

.th-aw {

  --themeColorDefault: var(--defaultColor);
  --themeColorPrimary: var(--primaryColorClaroNavy);
  --themeColorSecondary: var(--secondaryColor);

  // default button
  --buttonBackgroundColor: var(--defaultColor);
  --buttonBackgroundHoverColor: var(--defaultColorHover);
  --buttonBackgroundActiveColor: var(--defaultColorActive);
  --buttonTextColor: var(--fontColorDark);
  --buttonTextHoverColor: var(--fontColorDark);
  --buttonTextActiveColor: var(--fontColorDark);
  --buttonTextVisitedColor: var(--fontColorDark);
  --buttonBorder: var(--buttonBorder);
  --buttonBorderHover: var(--buttonBorderColorHover);
  --buttonBorderRadius: var(--buttonRoundness);
  --buttonBorderIconFillColor: var(--black);

  // primary button
  --primaryButtonBackgroundColor: var(--primaryColorAppWriter);
  --primaryButtonBackgroundHoverColor: var(--primaryColorAppWriterHover);
  --primaryButtonBackgroundActiveColor: var(--primaryColorAppWriterActive);
  --primaryButtonBorderActive: var(--buttonBorderAppWriterActive);
  --primaryButtonTextColor: var(--fontColorWhite);
  --primaryButtonTextHoverColor: var(--fontColorWhite);
  --primaryButtonTextActiveColor: var(--fontColorWhite);
  --primaryButtonTextVisitedColor: var(--fontColorWhite);
  --primaryButtonBorder: var(--buttonBorderAppWriter);
  --primaryButtonBorderHover: var(--buttonBorderAppWriterHover);
  --primaryButtonBorderRadius: var(--buttonRoundness);
  --primaryButtonIconFillColor: var(--white);

  // Alt green button
  --altButtonBackgroundColor: var(--altColor);
  --altButtonBackgroundHoverColor: var(--altColorHover);
  --altButtonBackgroundActiveColor: var(--altColorActive);
  --altButtonTextColor: var(--fontColorWhite);
  --altButtonTextHoverColor: var(--fontColorWhite);
  --altButtonTextActiveColor: var(--fontColorWhite);
  --altButtonTextVisitedColor: var(--fontColorWhite);
  --altButtonBorder: var(--altButtonBorder);
  --altButtonBorderHover: var(--altButtonBorderColorHover);
  --altButtonBorderRadius: var(--buttonRoundness);
  --altButtonIconFillColor: var(--white);

  // Unilogin
  --uniloginBackgroundColor: #F7F7F7;
  --uniloginWindowBackgroundColor: var(--primaryColorAppWriter);
}


.dark-mode {
  // Toolbar
  --toolbarBackgroundColor: var(--th-slate-dark);
  --toolbarButtonBackgroundColor: var(--th-slate-dark);
  --toolbarButtonBackgroundHoverColor: var(--icon-button-background-hover-color-dark);
  --toolbarButtonBackgroundActiveColor: var(--icon-button-background-active-color-dark);
  --toolbarButtonBorderActive: var(--buttonBorderPink);

  --toolbarIconFill: var(--white);
  --toolbarIconHoverFill: var(--white);
  --toolbarIconActiveFill: var(--white);

  // Dashboard universal
  --dashboardHeaderBackgroundColor: var(--th-slate-dark);
  --dashboardSidebarBorderTopColor: var(--th-slate);
  --dashboardSidebarBackgroundColor: var(--th-slate-dark);
  --dashboardContentBackgroundColor: var(--th-slate);

  --dashboardCardBackgroundColor: var(--th-slate-dark);
  --dashboardCardBackgroundHoverColor: var(--th-slate-dark-hover600);
  --dashboardCardBackgroundActiveColor: var(--th-slate-dark-active600);
  --dashboardCardHighlightColor: var(--brandColorBlue);

  --dashboardTextColor: var(--th-white);
  --dashboardTitleTextColor: var(--th-white);
  --dashboardSubTitleTextColor: var(--th-white);

  --menuIconFillColor: var(--white);

  // Icon Buttons
  --iconButtonBackgroundDefaultColor: var(--icon-button-background-default-color-dark);
  --iconButtonBackgroundHoverColor: var(--icon-button-background-hover-color-dark);
  --iconButtonBackgroundActiveColor: var(--icon-button-background-active-color-dark);
  --iconButtonBorderColor: 2px solid transparent;
  --iconFillColor: var(--white);

  // Button link
  --buttonLinkTextColor: var(--white);
  --buttonLinkIconColor: var(--white);

  // Button no background
  --buttonNoBackgroundTextColor: var(--white);
  --buttonNoBackgroundIconColor: var(--white);
  --buttonNoBackgroundColor: transparent;
  --buttonNoBackgroundColorHover: var(--icon-button-background-hover-color-dark);
  --buttonNoBackgroundColorActive: var(--icon-button-background-active-color-dark);
  --buttonNoBackgroundBorder: 2px solid transparent;
  --buttonNoBackgroundBorderHover: 2px solid transparent;
  --buttonNoBackgroundBorderActive: 2px solid transparent;

  // Panels
  --panelBackgroundColor: var(--th-slate);
  --panelBackgroundColorLight: var(--th-slate-light);
  --panelBackgroundColorDark: var(--th-slate-dark);

  // Text
  --fontColor: var(--textColorLight);
  --borderColor: 1px solid var(--th-slate-light);

    // Notifications
    --infoBackgroundColor: var(--notificationDarkModeBackground);
    --successBackgroundColor: var(--notificationDarkModeBackground);
    --errorBackgroundColor: var(--notificationDarkModeBackground);
    --warningBackgroundColor: var(--notificationDarkModeBackground);
    --infoBorderColor: var(--notificationDarkModeBorder);
    --successBorderColor: var(--notificationDarkModeBorder);
    --errorBorderColor: var(--notificationDarkModeBorder);
    --warningBorderColor: var(--notificationDarkModeBorder);
    --infoWarningColor: #fff;
    --successWarningColor: #fff;
    --errorWarningColor: #fff;
    --warningWarningColor: #fff;

    --infoTitleColor : #fff;
    --successTitleColor: #fff;
    --errorTitleColor: #fff;
    --warningTitleColor: #fff;
    
    //Accordion
    --accordionBackgroundColor: var(--th-slate-dark);
    --accordionTextColor: #fff;
    --accordionTextBottomColor: #f5f5f5;
    --accordionIconColor: var(--toolbarWhite);
    --accordionBackgroundColorHover: var(--th-slate-dark-hover600);

    //table
    --tableBackgroundColor: var(--th-slate);
    --tableBackgroundHeaderColor: var(--th-slate-alt);
    --tableBackgroundAltColor: var(--th-slate-alt);
    --tableBackgroundHoverColor: var(--th-slate-hover);
    --tableBorderColor: var(--th-slate-dark);
    --tableTextColor: #fff;

    //list group
    --ListGroupSearchItemColor: var(--th-slate-dark);
    --ListGroupSearchItemColorHover: var(--th-slate-dark-hover800);
    --ListGroupSearchItemTextColor: var(--th-white);
    --ListGroupSearchItemBorderColor:rgba(0, 0, 0, 0.225);
    --ListGroupSearchItemAsideColor: var(--th-slate-dark);
    --ListGroupSearchItemAsideTextColor: #fff;

  &.th-rw {
    --toolbarIconActiveFill: var(--white);
    --toolbarIconToggledHoverFill: var(--brandColorPurple);
    // circle indicatior
    --toolbarIconToggleFill: var(--brandColorPurple);
    --toolbarIconToggleHoverFill: var(--brandColorPurple);
  }

  &.th-pr {
    --toolbarButtonBackgroundHoverColor: var(--primaryColorBlue);
    --toolbarButtonBackgroundActiveColor: var(--primaryColorBlueHover);
    --toolbarButtonBorderActive: var(--buttonBorderBlueActive);
    // circle indicatior
    --toolbarIconToggleFill: var(--iconToggleFillOrbitNote);

    --textColor: var(--textColorLight);
    --cardIconFillColor: var(--brandColorBlue);

    // primary button
    --primaryButtonBackgroundColor: var(--primaryColor);
    --primaryButtonBackgroundHoverColor: var(--primaryColorHover);
    --primaryButtonBackgroundActiveColor: var(--primaryColorActive);
    --primaryButtonBorderActive: var(--primaryButtonBorderActive);

    --primaryButtonBorder: var(--primaryButtonBorder);
    --primaryButtonBorderHover: var(--primaryButtonBorderHover);

    //dashboard
    --dashboardTopColor: var(--primaryColorBlue);
    --dashboardTitleColor: var(--themeColorEquatio);

    --dashboardPaneBackgroundColor: var(--th-slate-dark);
    --dashboardPaneBorderColor: var(--th-slate-dark);
    --dashboardPaneDashedBorderColor: var(--dashboardDashedBorderDark);

    // List group
    --listGroupItemBackgroundColor: var(--th-slate-dark);
    --listGroupItemBackgroundColorHover: var(--th-slate-dark-hover800);
    --listGroupItemBackgroundColorActive: var(--th-slate-dark-active800);
    --listGroupTileBackgroundColorActive: var(--th-slate-dark-active800);
    --list-item-hover: var(--listGroupItemBackgroundColorHover); // set current highlighted list item the same as hover color

  }

  &.th-rd {
    // Toolbar
    --toolbarIconToggledHoverFill: var(--brandColorBlue);
    // circle indicatior
    --toolbarIconToggleFill: var(--brandColorBlue);
    --toolbarIconToggleHoverFill: var(--brandColorBlue);

    // Dashboard
    --dashboardTopColor: var(--th-slate);

    --dashboardCardAuditorColor: var(--primaryColorBlue);
    //--dashboardCardAuditorLabelColor: var(--brandColorBlue);
    --dashboardCardAuditorLabelColor: var(--th-grey);

    --dashboardCardEditorColor: var(--reachDeckEditorColor);
    //--dashboardCardEditorLabelColor: hsl(112, 78%, 80%);
    --dashboardCardEditorLabelColor: var(--th-grey);

    --dashboardCardToolbarColor: var(--primaryColorOrange);
    //--dashboardCardToolbarLabelColor: hsl(15, 100%, 80%);
    --dashboardCardToolbarLabelColor: var(--th-grey);

    --dashboardCardBorderColor: var(--th-slate-light);

    --dashboardCardFooterLinkColor: hsl(197, 100%, 50%);

    --icon-opacity: .9;

    --cardIconFillColor: var(--th-white);

    // List group
  --listGroupItemBackgroundColor: var(--th-slate-dark);
  --listGroupItemBackgroundColorHover: var(--th-slate-dark-hover800);
  --listGroupItemBackgroundColorActive: var(--th-slate-dark-active800);
  --listGroupTileBackgroundColorActive: var(--th-slate-dark-active800);
  --list-item-hover: var(--listGroupItemBackgroundColorHover); // set current highlighted list item the same as hover color

  }


  // Input

   --inputBorder: 1px solid var(--th-slate);
   --inputTextColor: var( --th-white);
   --inputBackgroundColor: var(--th-cool-grey-800);

  // Modal Dialog
  --modalHeaderBackgroundColor: var(--th-cool-grey-800);
  --modalContentBackgroundColor: var(--th-cool-grey-700);
  --modalContentBorderColorHeader: var(--th-cool-grey-700);
  --modalContentBorderColorFooter: var(--th-cool-grey-700);
  --modalTextColor: var(--th-white);

  // Settings Dialog
  --settingsDialogBackgroundColor: var(--th-cool-grey-700);
  --settingsDialogMenuBackgroundColor: var(--th-cool-grey-800);
  --settingsDialogItemSelectedBackgroundColor: var(--th-slate-dark);
  --settingsDialogItemBackgroundColorHover: var(--th-cool-grey-700);
  --settingsDialogItemBackgroundColorActive: var(--th-cool-grey-600);
  --settingsTitleColor: #fff;

  // Select Input
  --selectBorder: 1px solid var( --slate-light);
  --selectBackgroundColor: var( --th-slate);
  --selectBackgroundColorHover: rgba(255, 255, 255, 0.146);
  --selectBackgroundColorActive: rgba(255, 255, 255, 0.221);
  .gw-select {
    background: transparent url(svg-data-uri("0 0 18 18", '<path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" fill="#fff"/><path d="M0-.75h24v24H0z" fill="none"/>', (height: 22px, width: 22px))) no-repeat;
    background-repeat: no-repeat, repeat;
    background-position: right 0.9em top 40%, 0 0;
    background-color: #434e65 transparent;
    border: 1px solid #fff;
    background-size: 1.3em auto, 100%;
    color: #fff;
  }

  .gw-select > option {
    background: #434e65; //Windows needs a colour set here
  }

  // List Group
  --listGroupBackgroundColor: transparent;
  --listGroupItemTextColor: var(--th-white);
  --listGroupItemBackgroundColor: var(--th-cool-grey-800);
  --listGroupItemActionsBackgroundColor: var(--th-cool-grey-850);
  --listGroupItemBackgroundColorHover: var(--th-cool-grey-700);
  --listGroupItemBackgroundColorActive: var(--th-cool-grey-600);
  --listGroupItemIconColor: var(--th-white);
  --listGroupItemArrowColor: var(--th-cool-grey-800);
}

@media (prefers-color-scheme: dark) {
  :root {
    .day.dark-scheme {
      background: #333;
      color: white;
    }
    .night.dark-scheme {
      background: black;
      color: #ddd;
    }
  }
}

@media (prefers-color-scheme: light) {
  :root {
    .day.light-scheme {
      background: white;
      color: #555;
    }
    .night.light-scheme {
      background: #eee;
      color: black;
    }
  }
}
