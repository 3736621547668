@import '../mixins/variable';

svg.gw-round-spinner {
    width: 4.75em;
    transform-origin: center;
    animation: rotate 2s linear infinite;
  }
  
.gw-round-spinner circle {
    fill: none;
    @include variable( stroke, --themeColorPrimary,  --primaryColor, hsl(211, 100%, 45%) );
    stroke-width: 3;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: square;
    animation: dash 1.5s ease-in-out infinite;
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dashoffset: -125px;
    }
  }

  // percentage circle loader 

  .gw-progress-loader-wrapper {
    width: 14em;
    height: 12em;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 6px 1px rgba(67, 78, 101, 0.16);
    border-radius: 10px;
  }
  
  .gw-progress-loader {
    font-size: 10px;
    width: 6em;
    
    &__circle {
      transition: 0.35s stroke-dashoffset;
      // axis compensation
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
    }

    &__percentage {
      text-align: center;
      font-size: 1.6em;
      font-weight: 600;
      margin-top: .5em;
    }
  }
  
  
