

@import '../mixins/variable';

.gw-notification {
    position: relative;
    padding: 1.2em;
    margin: 1em 0;
    border: 1px solid transparent;
    border-radius: .25em;

  /** Elements
---------------------------------------------------------*/

    &__iconbox {
        display: inline-block;
        vertical-align: top;
        margin-top: 0.9em;
    }

    &__textbox {
        display: inline-block;
    }

    &__title {
        font-family: "Roboto", sans-serif;
        text-transform: none;
        margin: 0;
        padding: 0;
        font-size: 1.6em;
        font-weight: 600;

        &--info {
            color: var(--infoTitleColor);
        }

        &--success {
            color: var(--successTitleColor);
        }

        &--error {
            color: var(--errorTitleColor);
        }

        &--warning {
            color: var(--warningTitleColor);
        }
    }

    &__icon {
        width: 2em;
        height: 2em;
        flex-shrink: 0;
        margin-right: 1em;
        align-self: center;
    }

    &_icon-wrapper {
        width: 2em;
        height: 2em;
        display: inline-block;
    }

    &__message {
        font-family: "Open Sans", sans-serif;
        text-transform: none;
        margin: 0.2em 0 0 0;
        padding: 0;
        font-size: 1.2em;
        font-weight: 400;
        //padding-left: 2.5em;

        &--info {
            color: var(--infoWarningColor);
        }

        &--success {
            color: var(--successWarningColor);
        }

        &--error {
            color: var(--errorWarningColor);
        }

        &--warning {
            color: var(--warningWarningColor);
        }
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
    }

    .gw-notification-content-wrapper {

    }

    .gw-notification-action-buttons-wrapper {
        display: flex;
        align-items: center;

        & > :not(:first-child) {
            margin-left: .25em;
        }
        & > :not(:last-child) {
            margin-right: .25em;
        }
    }

  /** Modifiers
---------------------------------------------------------*/

    &--info {
        background-color: var(--infoBackgroundColor);
        border-color: var(--infoBorderColor);
    }

    &--success {
        background-color: var(--successBackgroundColor);
        border-color: var(--successBorderColor);
    }

    &--error {
        background-color: var(--errorBackgroundColor);
        border-color: var(--errorBorderColor);
    }

    &--warning {
        background-color: var(--warningBackgroundColor);
        border-color: var(--warningBorderColor);
    }

    &--mobile {
        display: flex;
        flex-direction: row;
    }
}

  /** Buttons
---------------------------------------------------------*/


.gw-notification-close-button {
    position: absolute;
    top: .5em;
    right: .5em;
}

.gw-notification-status-icon {
    font-size: 10px;
    width: 2.25em;
    height: 1.8em;
    margin: -.3em .8em 0 0;
    vertical-align: middle;
    opacity: 0.9;

    &--info {
        fill: var(--infoTitleColor);
    }

    &--success {
        fill: var(--successTitleColor);
    }

    &--error {
        fill: var(--errorTitleColor);
    }

    &--warning {
        fill: var(--warningTitleColor);
    }
} 