@import '../mixins/variable';
@import '../mixins/box-shadow';

.gw-infopopup {
    width: 342px;
    background-color: var(--primaryColorBlue);
    border-radius: 6px;
    padding: 15px 15px 10px 25px;
    position: relative;
    @include box-shadow(0, 1px, 2px, rgba(0, 0, 0, 0.2));
    margin: 2em;
    box-sizing: border-box;

    &--onboarding {
        padding-left: 1.5em; 
    }

    &__progress {
        display: block;
        height: 0.8em;
        margin-left: 1em;
        display: flex;
    }

    &__progress-dot {
        width: 0.8em;
        height: 0.8em;
        border-radius: 1em;
        margin-right: 1.6em;
        background-color: rgba(255,255,255,0.3);

        &--active {
            background-color: rgba(255,255,255,1); 
        }
    }


    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        color: #fff;
    }

    &--pointer-top {
        &::before {
            content: '';
            position: absolute;
            top: -30px;
            left: 50%;
            margin-left: -7.5px;
            border: 15px solid transparent;
            border-bottom-color: var(--primaryColorBlue);
        }
    }

    &--pointer-topStart {
        &::before {
            content: '';
            position: absolute;
            top: -30px;
            left: 10%;
            margin-left: -7.5px;
            border: 15px solid transparent;
            border-bottom-color: var(--primaryColorBlue);
        }
    }

    &--pointer-topEnd {
        &::before {
            content: '';
            position: absolute;
            top: -30px;
            right: 10%;
            margin-left: -7.5px;
            border: 15px solid transparent;
            border-bottom-color: var(--primaryColorBlue);
        }
    }

    &--pointer-left {
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: -30px;
            margin-top: -16px;
            transform: rotate(-90deg);
            border: 15px solid transparent;
            border-bottom-color: var(--primaryColorBlue);
        }
    }

    &--pointer-leftStart {
        &::before {
            content: '';
            position: absolute;
            top: 10%;
            left: -30px;
            transform: rotate(-90deg);
            border: 15px solid transparent;
            border-bottom-color: var(--primaryColorBlue);
        }
    }

    &--pointer-leftEnd {
        &::before {
            content: '';
            position: absolute;
            bottom: 10%;
            left: -30px;
            margin-top: -16px;
            transform: rotate(-90deg);
            border: 15px solid transparent;
            border-bottom-color: var(--primaryColorBlue);
        }
    }

    &--pointer-right {
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            right: -30px;
            margin-top: -16px;
            transform: rotate(90deg);
            border: 15px solid transparent;
            border-bottom-color: var(--primaryColorBlue);
        }
    }

    &--pointer-rightStart {
        &::before {
            content: '';
            position: absolute;
            top: 10%;
            right: -30px;
            transform: rotate(90deg);
            border: 15px solid transparent;
            border-bottom-color: var(--primaryColorBlue);
        }
    }

    &--pointer-rightEnd {
        &::before {
            content: '';
            position: absolute;
            bottom: 10%;
            right: -30px;
            margin-top: -16px;
            transform: rotate(90deg);
            border: 15px solid transparent;
            border-bottom-color: var(--primaryColorBlue);
        }
    }

    &--pointer-bottom {
        &::before {
            content: '';
            position: absolute;
            bottom: -30px;
            left: 50%;
            margin-left: -7.5px;
            transform: rotate(180deg);
            border: 15px solid transparent;
            border-bottom-color: var(--primaryColorBlue);
        }
    }

    &--pointer-bottomStart {
        &::before {
            content: '';
            position: absolute;
            bottom: -30px;
            left: 10%;
            margin-left: -7.5px;
            transform: rotate(180deg);
            border: 15px solid transparent;
            border-bottom-color: var(--primaryColorBlue);
        }
    }

    &--pointer-bottomEnd {
        &::before {
            content: '';
            position: absolute;
            bottom: -30px;
            right: 10%;
            margin-left: -7.5px;
            transform: rotate(180deg);
            border: 15px solid transparent;
            border-bottom-color: var(--primaryColorBlue);
        }
    }

    &__header {
        font-family: 'Roboto', sans-serif;
        font-weight: 600;
        min-height: 35px;

        h5 {
            width: auto;
            color: white;
            font-size: 1.8em;
        }

        button {
            margin-left: auto;

        }

        svg {
            fill: #fff;
        }
    }

    &__body {
        padding: 6px 10px 10px 0;

        p {
            color: #fff;
            font-family: 'Open Sans', sans-serif;
            font-size: 1.5em;
            font-weight: 400;
            margin-bottom: 0;
            margin-block-start: 0;
            margin-block-end: 0;
            margin-inline-start: 0;
            margin-inline-end: 0;
        }
    }

    &__footer {
        padding: 0;
        min-height: 5px;
    }

}

