// Buttonset

.gw-group {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    // align items left
    &--align-left {
        justify-content: flex-start;
    }

    // align items right
    &--align-right {
        flex-direction: row;
        justify-content: flex-end;
    }

    // center align items
    &--center {
        justify-content: center;
    }

    // space items out full width
    &--space-between {
        justify-content: space-between;
    }

    // space items out full width
    &--space-around {
        justify-content: space-around;
    }

    // no wrap
    &--no-wrap {
        flex: 0 0 auto; 
        flex-shrink: 0;
    }

    // Spacing between group elements
    &--align-left,
    &--align-right,
    &--center,
    &--space-between {

        &>:not(:last-child) {
            margin-right: .25em;
        }
        
        &>:not(:first-child) {
            margin-left: .25em;
        }

    }

    & .gw-group-item {
        &--align-start {
            align-self: flex-start;
        }
        &--align-end {
            align-self: flex-end;
        }
    }
}

// Placing here for now, could do with a gw-grid css shortcut ala gw-groups
.gw-control-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;

    &--last-item {
        grid-column: 4;
    }
}