@import '../mixins/variable';
@import '../mixins/box-shadow';

// Container that the modal scrolls within
.gw-modal {
    position: relative; //fixed
    top: 0;
    left: 0;
    z-index: var(--zindex-modal);
    display: block; // none
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;

    &--s1 {
      width: 420px;
    }

    &--s2 {
      width: 620px;
    }

    &--s3 {
      width: 100%;
    }
  }
  
  .gw-modal-dialog {
    position: relative;
    width: auto;
    margin: var(--modal-dialog-margin);
    pointer-events: none;
  }
  
  .gw-modal-dialog-scrollable {
    display: flex; // IE10/11
    max-height: subtract(100%, 2rem * 2);
  
    .gw-modal-content {
      max-height: subtract(100vh, 2rem * 2); // IE10/11
      overflow: hidden;
    }
  
    .gw-modal-header,
    .gw-modal-footer {
      flex-shrink: 0;
    }
  
    .gw-modal-body {
      overflow-y: auto;
    }
  }
  
  .gw-modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: subtract(100%, 2rem * 2);
  
    &::before {
      display: block; // IE10
      height: subtract(100vh, 2rem * 2);
      height: min-content; // Reset height to 0 except on IE
      content: "";
    }
  

    &.gw-modal-dialog-scrollable {
      flex-direction: column;
      justify-content: center;
      height: 100%;
  
      .gw-modal-content {
        max-height: none;
      }
  
      &::before {
        content: none;
      }
    }
  }
  
  // Actual modal
  .gw-modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
    // counteract the pointer-events: none; in the .modal-dialog
    @include variable( color, --modalTextColor,  --th-slate-dark, hsl(222, 22%, 18%) );
    pointer-events: auto;
    background-color: var(--modal-content-bg);
    @include variable( background-color, --modalContentBackgroundColor,  --modal-content-bg, #fff );
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: var(--modal-content-border-radius);
    @include box-shadow(0, 1px, 2px, rgba(0, 0, 0, 0.2));
    // emove focus outline from opened modal
    outline: 0;
  }
  
  // Modal background
  .gw-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999;
    width: 100vw;
    height: 100vh;
    background-color: var(--modal-backdrop-bg);
  
    // Fade for backdrop
    &.fade { opacity: 0; }
    &.show { opacity: var(--modal-backdrop-opacity); }
  }
  
  // Modal header
  // Top section of the modal w/ title and dismiss
  .gw-modal-header {
    display: flex;
    align-items: center; // so the close btn always stays on the upper right corner
    justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 600;
    border-bottom: 1px solid;
    @include variable( border-color, --modalContentBorderColorHeader,   --modal-header-border-color, #e5e5e5 );
    padding: 1em 1.5em;
    border-radius: 1px;
    @include variable( background-color, --modalHeaderBackgroundColor,  --modal-header-background-color, #f5f5f5 );

    & > h5 {
      font-size: 1.4em;
      @include variable( color, --modalTextColor,  --th-slate-dark, hsl(222, 22%, 18%) );
    }
  }
  
  // Title text within header
  .gw-modal-title {
    margin-top: 0;
    margin-bottom: 0;
    line-height: var(--modal-title-line-height);
  }
  
  // Modal body
  // Where all modal content resides (sibling of .modal-header and .modal-footer)
  .gw-modal-body {
    position: relative;
    // Enable `flex-grow: 1` so that the body take up as much space as possible
    // when there should be a fixed height on `.modal-dialog`.
    flex: 1 1 auto;
    padding: var(--modal-inner-padding);

    & > p {
      font-family: "Open Sans", Arial, sans-serif;
      font-weight: 400;
      font-size: 1.4em;
      @include variable( color, --modalTextColor,  --th-slate-dark, hsl(222, 22%, 18%) );
    }
  }

  .gw-modal-body--logoBottomLeft {
    position: absolute;
    bottom: 15px;
    left: 15px;
    display: block;

    @media only screen and (max-width: 600px) {
       display: none; 
   }
  }
  
  // Footer (for actions)
  .gw-modal-footer {
    padding: 2em - 1em / 2;
    border-top: 1px solid;
    @include variable( border-color, --modalContentBorderColorFooter, --th-white, #fff );
    border-radius: 1px;
    margin-top: auto;

    > * {
      margin: 1em / 2;
    }
  }
  
  // Measure scrollbar width for padding body during modal show/hide
  .gw-modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }