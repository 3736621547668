// --------------------------------------------------
// Flexbox grid layout - adapted version of - https://frowcss.com/
// --------------------------------------------------
$grid-gutter-width: 10px !default;

$sizes: (xs: 0px, sm: 768px, md: 992px, lg: 1200px);
$sizes-names: map-keys($sizes);

.gw-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  
    // Fix for IE text not wrapping wihin a Frow
    & > * {
      max-width: 100%;
    }

    // Flexbox settings classes
    &.centered {
        justify-content: center;
        align-items: center;
        align-content: center;
        height: 100%;
    }
    &.centered-column {
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-direction: column;
        height: 100%;
    }
    &.row-start{
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
    &.row-center{
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    &.row-end{
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }
    &.row-between{
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    &.row-around {
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    &.row-evenly {
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }
    &.column-start {
        flex-direction: column;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: flex-start;
    }
    &.column-center {
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: flex-start;
    }
    &.column-end {
        flex-direction: column;
        align-items: flex-end;
        align-content: flex-end;
        justify-content: flex-start;
    }

    // Inline-Flex
    &.inline {
        display: inline-flex;
    }

    // Wrap
    &.wrap {
        flex-wrap: wrap;
    }  
    &.wrap-reverse{
        flex-wrap: wrap-reverse;
    }
    &.nowrap {
        flex-wrap: nowrap;
    }

    // Flex-direction
    &.direction-row{
        flex-direction: row;
    }
    &.direction-reverse{
        flex-direction: row-reverse;
    }
    &.direction-column{
        flex-direction: column;
    }
    &.direction-column-reverse{
        flex-direction: column-reverse;
    }

    // Justify-content
    &.justify-start{
        justify-content: flex-start;
    }
    &.justify-end{
        justify-content: flex-end;
    }
    &.justify-center{
        justify-content: center;
    }
    &.justify-between{
        justify-content: space-between;
    }
    &.justify-around{
        justify-content: space-around;
    }
    &.justify-evenly{
        justify-content: space-evenly;
    }

    // Align-items
    &.items-start{
        align-items: flex-start;
    }
    &.items-end{
        align-items: flex-end;
    }
    &.items-center{
        align-items: center;
    }
    &.items-stretch{
        align-items: stretch;
    }
    &.items-baseline{
        align-items: baseline;
    }

    // Align-content
    &.content-start{
        align-content: flex-start;
    }
    &.content-end{
        align-content: flex-end;
    }
    &.content-center{
        align-content: center;
    }
    &.content-between{
        align-content: space-between;
    }
    &.content-around{
        align-content: space-around;
    }
    &.content-evenly{
        align-content: space-evenly;
    }

    // Columns
    // Generate the various columns widths.
    $all-columns: ();
    $all-columns-direct: ();

    @each $size in $sizes-names{
        $all-columns: append($all-columns, "[class*=gw-col-#{$size}]", comma);
    }
    @each $column in $all-columns{
        $all-columns-direct: append($all-columns-direct, "& > #{$column}", comma);
    }
  
    &.no-gutters {
        margin-left: -$grid-gutter-width / 2;
        margin-right: -$grid-gutter-width / 2;
    }

    #{$all-columns-direct} {
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
    }

    #{$all-columns} {
        width: 100%;
    }
  
    @each $size in $sizes-names{
        @media (min-width: map-get($sizes, $size)){
            @for $denominator from 1 through 12{
                .gw-col-#{$size}-#{$denominator} {
                    width: percentage($denominator / 12);
                }
            }
        }
    }
}
