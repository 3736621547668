.gw-comment-container{
    width: 300px;
    height: fit-content;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 6px #00000033;
    border-radius: 6px;
    position: relative;
}
.gw-comment-user-container{
    display: flex;
    align-items: center;
}
.gw-comment-user-icon{
    width: 32px;
    height: 32px;
    background: #318085 0% 0% no-repeat padding-box;
    border-radius: 50%;
    text-align: center;
    letter-spacing: 0px;
    color: #FFFFFF;
    font-weight: normal;
    font-stretch: normal;
    font-size: medium;
    line-height: 31px;
    font-family: 'Roboto';
}
.green{
    background: #318085 0% 0% no-repeat padding-box;
}
.purple{
    background: #9E63FB 0% 0% no-repeat padding-box;

}
.blue{
    background: #006FE6 0% 0% no-repeat padding-box;

}
.gw-comment-user-name{
    text-align: left;
    letter-spacing: 0px;
    color: #202020;
    font-weight: bold;
    font-stretch: normal;
    font-size: 21px;
    line-height: 30px;
    font-family: 'Roboto';
    margin-left: 11px;
}
.gw-comment-reply-user-name{
    text-align: left;
    letter-spacing: 0px;
    color: #202020;
    font-weight: bold;
    font-stretch: normal;
    font-size: 16px;
    line-height: 30px;
    font-family: 'Roboto';
    margin-left: 11px;
    margin-top: -13px;
    width: 160px;
}
.gw-comment-user-date{
    text-align: left;
    font-weight: normal;
    font-stretch: normal;
    font-size: 12px;
    line-height: 17px;
    font-family: 'Open Sans';
    letter-spacing: 0px;
    color: #626F88;
    position: absolute;
    margin-left: 45px;
    margin-top: -15px;
}
.gw-comment-content-container{
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    align-items: center;
    margin: 20px 0;
}
.gw-comment-audio-content-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 1.2em 0 0 0;
    padding: 2.2em 2.5em 1em 2.5em;
    align-items: center;

    &--no-padding {
        padding: 2.2em 0 1em 0;
    }
    
    &__label {
        position: absolute;
        font-family: "Open Sans", Arial, sans-serif;
        font-size: 1.3em;
        font-weight: 600;
        display: inline-block;
        top: -1.1em;
        left: .8em;
        margin: 0 0 .3em 0;
        padding: 9px 0;
        box-sizing: border-box;
        color: var(--fontColorDark);
    }

    & .item {
        min-height: 4em;
        min-width: 4em;
    }
}
.gw-comment-input{
    border: 1px dashed #BCC3D0;
    height: 45px;
}
.gw-comment-footer-buttons{
    padding: 0;
}
.gw-comment-reply-text{
    text-align: left;
    font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #202020;
    margin: 0 0px 12px 14px;
}
.gw-comment-audio-reply-timer{
    width: 45px;
    height: 45px;
    border: 2px solid #767676;
    border-radius: 50%;
    text-align: center;
    font: normal normal 600 15px/20px Open Sans;
    letter-spacing: 0px;
    color: #504F4F;
    line-height: 2.6;
}

.gw-comment-audio-reply-content{
    display: flex;
    justify-content: center;
    margin: 30px 0 30px 0;
}
.gw-comment-button-size{
    height: 45px;
    width: 45px;
}
.gw-comment-icon-size{
    height: 20px;
    width: 20px;
}
.gw-comment-audio-reply-time{
    text-align: center;
    font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #626F87;
    margin: -3px 5px 0 5px;
}
.gw-comment-video-container{
    margin: 12px 0;
    background: #4D4D4D 0% 0% no-repeat padding-box;
    width: 100%;
    height: 263.5px;
    padding: 0;
    position: relative;
    border: 1px solid white;
}
.gw-comment-video-reply-container{
    height: 140px;
    width: 185px;
    margin: 12px auto;
    background: #4D4D4D 0% 0% no-repeat padding-box;
    position: relative;
}
.gw-comment-list-button-icon{
    margin-right: 12px;
}
.gw-comment-delete-background{
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 0.85;
    padding: 20px;
    font: normal normal 600 13px/22px Open Sans;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.gw-comment-delete-container{
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    z-index: 2;
}
.gw-comment-delete-container p{
    text-align: left;
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
}
.gw-comment-delete-buttonset{
    justify-content: space-evenly;
    display: flex;
}
.gw-comment-container-comment{
    position: relative;
    padding: 16px;
    padding-top: 0px;
}
.gw-comment-container-comment:not(:last-child){
    padding-bottom: 0px;
}
.gw-comment-container-initial-comment{
    padding: 16px;
}
.gw-comment-button-back{
    float: left;
}
.gw-comment-reply-divider {
    width: 269px;
    border: dashed 1px hsl(0, 0%, 78%);

    /* Individual border image properties */
    border-image-slice: 1;
    border-image-repeat: round;
    margin: 18px 0;
}
.gw-comment-list-dropdown--more {
    position: absolute;
    width: 150px;
    top: auto;
    margin-top: 115px;
    margin-left: 115px;
    font-size: 10px;
    z-index: 999999;
}
.gw-comment-button-more {
    float: right;
    margin-top: -8px;
    margin-left: 36px;
}
.gw-reply-delete-background {
    padding: 16px;
    left: -16px;
    box-sizing: content-box;
}
.gw-comment-reply-content-container{
    display: flex;
    margin-top: 12px;
    align-items: center;
}
.gw-comment-input-buttons {
    position: absolute;
    margin-left: 180px;
    display: flex;
}
.gw-comment-video-expand{
    background-color: var(--th-grey);
}
.gw-comment-menu-demo-position{
    position: absolute;
    width: 150px;
    top: 38px;
    margin-left: 20px;
    margin-top: 0;
    font-size: 10px;
    z-index: 999999;
}
