.gw-accessible-modal-container{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999999999999999;
    right: 0;
    top: 0;
    overflow: hidden;
}
.gw-accessible-modal{
    margin: 0 auto;
    width: 777px;
    top: 25%;
    height: 100%;
}