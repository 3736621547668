@import '../mixins/variable';

@mixin tableStatic {
    & > tbody > tr {
        &:hover{
            background: none;
        }
    }
}

@mixin tableRowHover {
    &:hover {
        //background-color: hsl(210, 20%, 98%);
        background-color: var(--tableBackgroundHoverColor);
    }
}

table {
    &.gw-table {
        font-family: 'Roboto', sans-serif;
        display: table;
        table-layout: fixed;
        border-collapse: collapse;
        border-spacing: 0;
        margin: auto;
        width: 100%;
        max-width: 100%;
        background-color: var(--tableBackgroundColor);
        color: var(--tableTextColor);

        @media screen 
            and (max-width: 850px) { 
                overflow-x: auto;
                display: block;
                width: 100%;
        }

        & th,
        & td {
            padding: 16px 20px;
            font-size: 13px;
            border-bottom: solid 1px var(--tableBorderColor);
            border-right: none;
        
            &:last-child {
                border-right: none;
            }
        }

        & > thead > tr > th,
        & > thead > tr > td {
            font-family: 'Roboto', sans-serif;
            text-align: left;
            font-size: 14px;
            //background-color: #F9FAFB;
            background-color: var(--tableBackgroundHeaderColor);
            font-weight: 600;
            //color: #484848;
            color: var(--tableTextColor);
            white-space: normal;
        }

        & > tbody > tr {
            @include tableRowHover()
        }

        // Table types

        // Rowed will add alternate row colors
        &--rowed {
            & tbody > tr:nth-child(even) {
                //background-color: #f7f7f7;
                background-color: var(--tableBackgroundAltColor);
                @include tableRowHover()
            }
        }

        // Bordered will add another border around
        &--bordered {
            border: solid 1px var(--tableBorderColor);

            & th,
            & td {
                border: solid 1px var(--tableBorderColor);
            }

            &__static{

                & th,
                & td {
                    border: solid 1px var(--tableBorderColor);
                }

                @include tableStatic()
            }
        }

        // Static table will remove all hover effects
        &__static {
            @include tableStatic()
        }
    }
}