

@import '../mixins/variable';
@import 'notifications';

.gw-message-block {
    @extend .gw-notification;

  /** Elements
---------------------------------------------------------*/

    &__title {
        @extend .gw-notification__title;

        &--info {
            @extend .gw-notification__title--info;
        }

        &--success {
            @extend .gw-notification__title--success;
        }

        &--error {
            @extend .gw-notification__title--error;
        }

        &--warning {
            @extend .gw-notification__title--warning;
        }

    }

    &__message {
        @extend .gw-notification__message;

        &--info {
            @extend .gw-notification__message--info;
        }

        &--success {
            @extend .gw-notification__message--success;
        }

        &--error {
            @extend .gw-notification__message--error;
        }

        &--warning {
            @extend .gw-notification__message--warning;
        }
    }

  /** Modifiers
---------------------------------------------------------*/

    &--info {
        @extend .gw-notification--info;
    }

    &--success {
        @extend .gw-notification--success;
    }

    &--error {
        @extend .gw-notification--error;
    }

    &--warning {
        @extend .gw-notification--warning;
    }
}
