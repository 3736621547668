

@import '../mixins/variable';

// Input Group
.gw-input-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;

    & > .gw-input:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    & > .gw-input {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        height: 44px;
        width: 1%;
        margin-bottom: 0;

        &--action {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &--action-context {
            border-radius: 0;
        }
    }

    & > .gw-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &__prepend {
        position: relative;
        margin-right: -1px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    &__append {
        position: relative;
        margin-left: -1px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

// Button

.gw-input-group-button {
    @extend .gw-button;
    // Overide inherited font size from button
    font-size: 10px;

    &__icon {
        @extend .gw-button__icon;
        height: 1.4em;
        width: 1.4em;
    }

    &--action {
        position: relative;
        z-index: 2;
        padding: 10px 12px;
        border-top-left-radius: 0!important;
        border-bottom-left-radius: 0!important;
    }
}


.gw-input-group-button--dropdown {
    position: relative;
    z-index: 2;
    padding: 10px 12px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
    background: #F2F2F2 0% 0% no-repeat;
    border: 1px solid rgba(0, 0, 0, 0.15);

    & > .gw-input-group-button__icon {
        height: 11px;
        width: 11px;
        margin-left: 8px;
    }
}

// Dropdown Menu

.gw-input-group-dropdown-menu {
    @extend .gw-dropdown-menu;

    &__item {
        @extend .gw-dropdown-menu__item;
    }
}

// Dropdown Select


.gw-input-group-dropdown-select {
    @extend .gw-select;
    
    background-color: #F2F2F2;
    height: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

// Filter Dropdown Button

.gw-filter {


    & > .gw-button {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    & .gw-button {
        position: absolute;
        right: 48px;
        border: 0;
    }

    &.gw-input-group-button {
        position: absolute;
        border-radius: 0;
        padding: 10px 12px;
        border: 0;
        background-color: transparent!important;
        background: hsl(0, 0%, 100%) url(svg-data-uri("0 0 18 18", '<path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"/><path d="M0-.75h24v24H0z" fill="none"/>', (height: 22px, width: 22px))) no-repeat;
        background-repeat: no-repeat, repeat;
        background-position: right .7em top 46%, 0 0;
        background-size: .55em auto, 100%;
    }

    &__prepend {
        width: 100%;
        position: relative;
        display: flex;
    }

    &__fieldset {
        margin: 0;
        padding: 0;
    }


    &-label {
        font-size: 1.4em!important;
    }

    & .gw-input {
        border: transparent;
        background-color: transparent;
        border-bottom: 1px solid rgba(0,0,0,0.15);
        border-radius: inherit;
      }

}

.gw-filter-button {
    justify-content: space-between;
    width: 100%!important;
    display: flex!important;
    align-items: center;

    &:hover {
        border-width: 1px;
    }
}

