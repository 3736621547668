

@import '../mixins/variable';

.gw-icon-button {
    @extend .gw-button;
    display: inline-block;
    font-size: 10px;
    height: 3.5em;
    width: 3.5em;
    border-radius: 35px;
    padding: 0.4em;
    margin: 0;
    border: 2px solid var(--transparent);
    @include variable( background-color,  --iconButtonBackgroundDefaultColor,  --icon-button-background-default-color, rgba(220, 223, 228, 0.5) );
    

    &--icon {
        color: hsl(0, 0%, 100%);
        background-color: transparent;
        border: 2px solid transparent;
        @include variable( fill, --toolbarIconButtonFillColor, --fontColorDark, hsl(0, 0%, 0%));
    
    
        &:disabled,
        &[disabled] {
    
            background-color: var(--transparent);
            border-color: var(--transparent);
        }
    
        &:visited {
            color: var(--fontColorDark);
            text-decoration: none;
        }
    
        &:hover {
            color: hsla(0, 0%, 0%, 0.6);
            @include variable( border, --iconButtonBackgroundDefaultColor, --icon-button-border-color, 2px solid transparent);
            @include variable( background-color,  --iconButtonBackgroundHoverColor,  --icon-button-background-hover-color, rgba(220, 223, 228, 0.5) );
        }
    
        &:hover,
        &:focus {
            text-decoration: none;
        }
    
        &:active {
            color: var(--fontColorDark, hsl(0, 0%, 0%));
    
            @include variable( border, --icon-button-border-color, --icon-button-background-hover-color, 2px solid transparent);
            @include variable( background-color,  --iconButtonBackgroundActiveColor,  --icon-button-background-active-color, rgba(220, 223, 228, 0.5) );@include variable( background-color,  --iconButtonBackgroundActiveColorNoBackground,  --icon-button-background-active-color-no-background, hsla(0, 0%, 99%, 0.135) );
        }
    }

    &:not(:last-child) {
        margin-right: .25em;
    }
    

    &:hover {
        @include variable( border, --icon-button-border-color, --icon-button-background-hover-color, 2px solid transparent);
        @include variable( background-color,  --iconButtonBackgroundHoverColor,  --icon-button-background-hover-color, hsla(0, 0%, 100%, 0.22) );
    }

    &:active {
        @include variable( border, --icon-button-border-color, --icon-button-background-hover-color, 2px solid transparent);
        @include variable( background-color, --iconButtonBackgroundActiveColor,  --icon-button-background-active-color, hsla(222, 22%, 18%, 0.12) );
    }

    &__icon {
        @extend .gw-button__icon;
        top: -.1em;
        right: -.1em;
        height: 1.3em;
        width: 1.3em;
    }

    &__icon--s2b {
        width: 1.7em;
        height: 1.7em;
        top: -1px;
    }

    &__icon--s2 {
        width: 1.9em;
        height: 1.9em;
        top: -1px;
    }

    &__icon--s3 {
        width: 2.2em;
        height: 2.2em;
        top: 0;
    }

    /** Mobile icon Buttons
    ---------------------------------------------------------*/

    &__icon--app-floating {
        width: 3.2em;
        height: 3.2em;
        top: 0;
    }

    /** ---- **/

    &__icon--app-square {
        width: 3.5em;
        height: 3.5em;
        top: 0;
    }

    &__icon--launchpad-s1 {
        width: auto;
        height: 3.2em;
        top: 0;
    }

    &__icon--launchpad-s1b {
        width: auto;
        height: 2.8em;
        top: 0;
    }

    &__icon--launchpad-s2 {
        width: 3.2em;
        height: auto;
        top: 0;
    }

    &--move {
        background-color: transparent;
        color: hsl(0, 0%, 100%);
        opacity: 0.5;
    
        &:visited {
            color: var(--fontColorDark);
            text-decoration: none;
        }
    
        &:hover {
            background-color: transparent;
            border-color: transparent;
            color: hsla(0, 0%, 0%, 0.6);
            opacity: 0.8;
        }
    
        &:hover,
        &:focus {
            text-decoration: none;
        }
    
        &:active {
            background-color: transparent;
            color: var(--fontColorDark);
        }

        & > .gw-icon-button__icon {
            height: 17px;
            width: 17px;
        }
    }

    &--selected {
        @include variable( background-color, --defaultColorSelected,  --defaultColorSelected, hsl(221, 20%, 78%) );
        @include variable( border, --buttonBorderColorSelected,  --buttonBorderColorSelected, 2px solid hsl(221, 20%, 78%));
    }

    /** Icon Button Size Three
---------------------------------------------------------*/

    &--s3 {
        width: 54px;
        height: 54px;
        border-radius: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &--recording {
        position: relative;
        background-color: hsl(359, 70%, 51%);
        transition: scale .05s ease-in-out;

        &:hover {
            background-color: hsl(359, 78%, 44%);
        }

        &:active {
            transform: scale(.95);
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 50%;
        }

        &:before {
            box-shadow: 0 0 0 0 rgba(#EAAAAA, .5);
            -webkit-animation: pulse 1500ms infinite;
            z-index: 1;
        }

    }

    /** Mobile Floating Button
    ---------------------------------------------------------*/

    &--mobile-s4 {
        width: 9em;
        height: 9em;
        border-radius: 9em;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 3px 9px rgba(0,0,0,0.62);
        transition: scale .05s ease-in-out;
    }

    /** Mobile Square Button
    ---------------------------------------------------------*/

    &--mobile-square {
        width: 8em;
        height: 8em;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /** Mobile & Launchpad Floating Action Button Colours
    ---------------------------------------------------------*/

    &--green {
        background-color: var(--brandColorGreen);

        &:hover {
            background-color: var(--brandColorGreen);
        }

        &:active {
            background-color: var(--brandColorGreenActive);
            opacity: 1;
            transform: scale(.95);
        }
    }

    &--blue {
        background-color: var(--brandColorBlue);

        &:hover {
            background-color: var(--brandColorBlue);
        }

        &:active {
            background-color: var(--brandColorBlueActive);
            opacity: 1;
            transform: scale(.95);
        }
    }

    &--red {
        background-color: hsl(359, 70%, 51%);

        &:hover {
            background-color: hsl(359, 70%, 51%);
        }

        &:active {
            background-color: hsl(359, 78%, 44%);
            opacity: 1;
            transform: scale(.95);
        }
    }

    &--orange {
        background-color: var(--primaryColorOrange);

        &:hover {
            background-color: var(--primaryColorOrangeHover);
        }

        &:active {
            background-color: var(--primaryColorOrangeActive);
            opacity: 1;
        }
    }

    &--primary-blue {
        background-color: var(--primaryColorBlue);

        &:hover {
            background-color: var(--primaryColorBlueHover);
        }

        &:active {
            background-color: var(--primaryColorBlueActive);
            opacity: 1;
        }
    }

    &--dark {
        @include variable( background-color, --secondaryButtonBackgroundColor, --secondaryColor, hsl(221, 20%, 33%));

        &:hover {
            @include variable( background-color, --secondaryButtonBackgroundHoverColor, --secondaryColorHover, hsl(221, 20%, 23%));
        }

        &:active {
            @include variable( background-color, --secondaryButtonBackgroundActiveColor, --secondaryColorActive, hsl(221, 20%, 13%));
            opacity: 1;
        }
    }

    &--light {
        background-color: var(--launchpadButtonLight);

        &:hover {
            background-color: var(--launchpadButtonLightHover);
        }

        &:active {
            background-color: var(--launchpadButtonLightActive);
            opacity: 1;
        }
    }

    &--white {
        background-color: #fff;

        &:hover {
            background-color: #fff;
        }

        &:active {
            background-color: 	rgba(255, 255, 255, 0.75);
            transform: scale(.95);
        }
    }

}

@-webkit-keyframes pulse {
    0% {
      transform: (scale(.9));
    }
    70% {
      transform: (scale(1));
      box-shadow: 0 0 0 20px rgba(#EAAAAA, 0);
    }
      100% {
      transform: (scale(.9));
      box-shadow: 0 0 0 0 rgba(#EAAAAA, 0);
    }
  }