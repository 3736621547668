@import "../mixins/variable";
@import "../mixins/margin-padding";
@import "../mixins/flex-wrap-fix";

$borderColor: var(--dashboardPaneBorderColor);

.gw-dashboard {
    position: relative;
    font-size: 10px;

    &__header {}

    &__sidebar {}

    &__main {}
}

.gw-dashboard-header {
    // flex
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    position: fixed;
    width: 100%;
    height: 9em;
    border-top: solid;
    border-top-width: 7px;
    @include variable( border-top-color,  --dashboardTopColor,  --th-grey, hsl(220, 20%, 91%) );

    &__logo {
        // flex
        display: flex;
        align-items: center;
        justify-content: center;
        @include variable( background-color, --dashboardHeaderBackgroundColor,  --defaultColor, hsl(0, 0%, 100%) );
        //background-color: #fff;
        min-width: 250px;
        height: 100%;

        // flex
        flex: 0 0 100px;
    }

    &__title {
        position: relative;

        // flex
        flex: 1 1 auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 1em 0 2em;
        background-color: inherit;
        height: 100%;

        &:before {
            position: absolute;
            content: "";
            top: 0 auto;
            left: 0;
            height: 40%;
            border: 1px solid var(--th-grey);
        }
    }

    &__profile {
        // flex
        flex: 0 0 100px;
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        padding: 0 1em;
        height: 100%;

        & p {
            @include variable( color,  --fontColor, --th-slate-dark, hsl(222, 22%, 18%) );
        }
    }
}

.gw-dashboard-sidebar {
    position: fixed;
}

.pt50 {
    padding-top: 3em;
}

.gw-dashboard-main {
    position: fixed;
    @include variable( background-color,  --dashboardContentBackgroundColor, --th-white, hsl(0, 0%, 100%) );

    &__content {
        @include variable( background-color,  --dashboardContentBackgroundColor, --th-white, hsl(0, 0%, 100%) );
        //background: #fff;
        border-radius: 3px;
        padding: 1.8em 3em 2.9em;
        border: 1px solid #ebeaea;
        box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.06);

        &--transparent {
            background-color: transparent !important;
            padding: 1.8em 1.4em 2.9em;
            box-shadow: none;
            border: none;
        }
    }
}

.gw-dashboard-main-content-header {
    position: relative;
    border-bottom: 1px solid var(--th-grey);
    width: 100%;

    &--paddtop {
        padding-top: 1.8em;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 5em;
        padding: 0 0 2em 0;
    }

    &__group {
        display: inherit;
        align-items: inherit;
    }

    &__title {
        font-family: Roboto,sans-serif;
        font-size: 1.7em;
        margin: 0;
        padding: 0 2em 0 2em;
        font-weight: 800;
        color: #484a4f;

        &--scan {
            font-size: 2.5em;
            padding: 0 1em 0 1.5em;
        }
    }

    &__fieldset {
        border: 0;
        margin: 0 2em 0 .5em;
        padding: .5em 0 0 0;
    }
}

.gw-dashboard-logo {
    position: relative;
    height: 75px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &--od {
        height: 65px;
    }
}

.gw-dashboard-menu {
    position: relative;
    margin: 1em 0 0 0;

    &__item {
        font-family: "Roboto", sans-serif;
        border-radius: 0!important;
    }
}

.gw-dashboard-menu {
    &__icon {
        position: relative;
        height: 20px;
        vertical-align: middle;
        height: 36px;
        width: 36px;
        bottom: .1em;
        border-radius: 0px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 8px;
        background: transparent;
        background-repeat: no-repeat;
        background-position: 50%, 50%;
        @include variable( fill,  --menuIconFillColor, --white, hsl(0, 0%, 100%) );
        //fill: var(--th-slate);
    }
}

.gw-dashboard-welcome-text {
    display: block;
    position: relative;
    width: 100%;
    margin: 2em 0 1.7em 0;
    font-family: "Open Sans", sans-serif;
    font-size: 1.6em;
    @include variable( color,  --fontColor, --th-slate-dark, hsl(222, 22%, 18%) );
}

.gw-dashboard-notify {
    display: block;
    width: 100%;
    height: auto;
}

.gw-dashboard-title {
    position: relative;
    top: 2px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    @include variable( color,  --dashboardTitleTextColor,  --th-slate-dark, hsl(222, 22%, 18%) );
    font-size: 2.2em;
    margin: 0;
    padding: 0;

    &--subtitle {
        @include variable( color,  --dashboardTitleTextColor,  --th-slate-dark, hsl(222, 22%, 18%) );
        font-size: 2em;
        margin: 0 0 20px 0;
    }
}

.gw-dashboard-card {
    display: flex;
    justify-content: space-between !important;
    align-items: flex-start !important;
    flex-direction: column !important;
    position: relative;
    padding: calc( var(--accordion-inner-padding) / 2);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    @include variable( background-color, --dashboardCardBackgroundColor,  --dashboardCardBackgroundColor, hsl(0, 0%, 99%) );
    box-shadow: 0px 3px 16px #00000029;
    border-radius: 5px;
    opacity: 1;
    height: 100%;
    min-height: 32em;

    &--link {
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center !important;
        text-align: center;
        padding: .5em 0;
    }

    // Modifier tiles

    &--auditor {
        border-top: 3px solid;
        @include variable( border-color,  --dashboardCardAuditorColor,  --dashboardCardAuditorColor, hsl(208, 100%, 43%) );

        &:before {
            position: absolute;
            content: "Auditor";
            top: -1.7em;
            right: 1.8em;
            font-family: "Roboto", sans-serif;
            font-weight: 600;
            @include variable( color,  --dashboardCardAuditorLabelColor,  --brandColorBlue, hsl(197, 100%, 50%) );
            font-size: 1.5em;
            pointer-events: none;
        }
    }

    &--editor {
        border-top: 3px solid;
        @include variable( border-color,  --dashboardCardEditorColor,  --reachDeckEditorColor, hsl(112, 100%, 26%) );

        &:before {
            position: absolute;
            content: "Editor";
            top: -1.7em;
            right: 1.8em;
            font-family: "Roboto", sans-serif;
            font-weight: 600;
            @include variable( color,  --dashboardCardEditorLabelColor,  --reachDeckEditorColor, hsl(112, 100%, 26%) );
            font-size: 1.5em;
            pointer-events: none;
        }
    }

    &--toolbar {
        border-top: 3px solid;
        @include variable( border-color,  --dashboardCardToolbarColor,  --primaryColorOrange, hsl(15, 79%, 45%) );

        &:before {
            position: absolute;
            content: "Toolbar";
            top: -1.7em;
            right: 1.8em;
            font-family: "Roboto", sans-serif;
            font-weight: 600;
            @include variable( color,  --dashboardCardToolbarLabelColor,  --primaryColorOrange, hsl(15, 79%, 45%) );
            font-size: 1.5em;
            pointer-events: none;
        }
    }

    &__inner {
        width: 100%;
        height: 100%;
        padding: calc( var( --modal-inner-padding) / 2 );
    }
}

.gw-dashboard-card-icon {
    position: relative;
    height: 20px;
    vertical-align: middle;
    height: 65px;
    bottom: .1em;
    border-radius: 0px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 8px;
    background: transparent;
    background-repeat: no-repeat;
    background-position: 50%, 50%;
    @include variable( fill, --cardIconFillColor,  --th-slate, hsl(221, 20%, 33%) );
    //fill: var(--th-slate);
    margin-bottom: .3em;
}

.gw-dashboard-card-link {
    
    &__inner {
        position: relative;
        padding: calc( var( --modal-inner-padding) * 2 );
        opacity: .9;
        transition: all 0.05s ease-in-out 0.05s;
        transform: scale(1);
    }


    &:hover {
        cursor: pointer;
        @include variable( background-color, --dashboardCardBackgroundHoverColor,  --th-grey-light, hsl(210, 10%, 94.8%) );
        //background-color: var(--th-grey-light);
        opacity: 1;

        .gw-dashboard-card-tile-title {
            @include variable( color, --dashboardCardHighlightColor,  --primaryColor, hsl(211, 100%, 45%) );
            //color: var(--primaryColor);
        }

        & .gw-dashboard-card-link__inner, .gw-dashboard-pane__inner {
            transform: scale(1.06);
        }

        & .gw-dashboard-card-icon {
            @include variable( fill, --dashboardCardHighlightColor,  --primaryColor, hsl(211, 100%, 45%) );
            //fill: var(--primaryColor);
        }
    }

    &:active,
    &:focus {
        @include variable( background-color, --dashboardCardBackgroundActiveColor,  --lightGrey, hsl(0, 0%, 94%) );

        & .gw-dashboard-card-link__inner, .gw-dashboard-pane__inner {
            transform: scale(1);
        }
    }
}


// text classes

.gw-dashboard-card-tile-title {
    font-family: "Roboto", sans-serif;
    text-shadow: none;
    font-size: 1.7em;
    margin-bottom: .1em;
    @include variable( color,  --dashboardTitleTextColor, --fontColorDark, hsl(222, 22%, 18%) );
    //color: var(--fontColorDark);

    &--subtitle {
        font-size: 1.6rem;
        @include variable( color,  --dashboardSubTitleTextColor, --th-slate-light, hsl(221, 20%, 45%) );
        //color: var(--th-slate-light);
        padding: 5px 0 0 0;
        display: inline-block;
    }

    &__icon {
        position: relative;
        vertical-align: middle;
        height: 36px;
        width: 36px;
        border-radius: 0px;
        bottom: .1em;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 6px;
        background: transparent;
        background-repeat: no-repeat;
        background-position: 50%, 50%;
        @include variable( fill, --cardIconFillColor,  --th-slate, hsl(221, 20%, 33%) );
        //fill: var(--th-slate);
    }
}

.gw-dashboard-card-summary-title {
    font-family: "Roboto", sans-serif;
    text-shadow: none;
    font-size: 1.4em;
    margin-bottom: .1em;
    font-weight: 300;
    @include variable( color,  --dashboardTextColor, --th-slate, hsl(221, 20%, 33%) );
    //color: var(--th-slate);
}

.gw-dashboard-card-summary-overview {
    font-family: "Roboto", sans-serif;
    text-shadow: none;
    font-size: 1.4em;
    margin-bottom: .1em;
    font-weight: 300;
    @include variable( color,  --dashboardTextColor, --th-slate, hsl(221, 20%, 33%) );
    //color: var(--th-slate);

    &--strong {
        font-weight: 600;
        font-size: 1em;
    }
}

.gw-dashboard-card-tile {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__section {
        position: relative;

        &--header {
            padding: .3em 0 0 0;
        }

        &--content {
            padding: 0 1.1em 1em 1.1em;
            flex: 1;
        }

        &--footer {
            margin-top: .03em;
            padding-top: 0.5em;
            border-top: solid;
            border-top-width: 1px;
            @include variable( border-top-color,  --dashboardCardBorderColor, --th-grey, hsl(220, 20%, 91%) );
            //border-top-color: var(--th-grey);
            text-align: center;
        
            & button {
                @include variable( color,  --dashboardCardFooterLinkColor, --primaryColor, hsl(211, 100%, 45%) );
            }
        
        }
    }
}

.gw-dashboard-tile-list {
    margin: .1em 0 .3em 0;

    &__item {

        &:nth-of-type(odd) {
            @include variable( background-color, --listGroupItemBackgroundColorHover,  --list-group-item-background-hover-color, hsl(210, 10%, 96%) );
            //background-color: var(--th-grey-light);

            &:hover {
                @include variable( background-color, --listGroupItemBackgroundColorHover,  --list-group-item-background-hover-color, hsl(210, 10%, 96%) );
                //background-color: var(--th-grey-light);
            }

            &:active {
                @include variable( background-color, --listGroupItemBackgroundColorHover,  --list-group-item-background-hover-color, hsl(210, 10%, 96%) );
                //background-color: var(--th-grey-light);
            }
        }
    }
}

.gw-dashboard-card-summary {
    &__title-area {
        padding: .5em 0;
        text-align: center;
    }

    &__overview-area {
        padding: .6em 0 .6em 0;
        text-align: center;
    }
}

.gw-dashboard-tile-list-item {
    position: relative;
    width: 100%;
    border-radius: 0!important;

    &__metric-status {
        position: relative;

        // @include flex-wrap-fix(80px);

        background-color: transparent;
        width: 3em;
        margin: 0 !important;
        opacity: var(--icon-opacity); // full for light mode, toned down for dark mode

        &:before {
            position: absolute;
            top: -8px;
            left: -5px;
            content: "";
            //background-color: rgba(255, 255, 255, 0.1);
            border: 0.213em solid var(--th-grey);
            height: 41px;
            border-radius: 50%;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            width: 41px;
        }

        &--status-red {
            &:before {
                border: 0.213em solid #D62313 !important;
                opacity: var(--icon-opacity); // full for light mode, toned down for dark mode
            }
        }

        &--status-amber {
            &:before {
                border: .213em solid #C06307;
            }
        }

        &--status-green {
            &:before {
                border: .213em solid #339900;
            }
        }
    }

    &__metric-label {
        // @include flex-wrap-fix(150px);
        flex: 1 1 auto;
        display: flex;
        justify-content: flex-start;
        font-family: Roboto, sans-serif;
        font-size: 1.1em;
        font-weight: 400;
        width: auto;
        margin: 0 !important;
        padding: 0 .3em;

        &--strong {
            font-weight: 600;
            font-size: 1em;
        }
    }

    &__metric-value {
        display: flex;
        justify-content: flex-end;
        width: auto;
        font-family: Roboto, sans-serif;
        font-size: 1.1em;
        font-weight: 600;
        @include variable( color,  --fontColor, --th-slate-dark,  hsl(222, 22%, 18%) );
        //color: var(--fontColorDark);
        align-items: center;
        margin: 0 !important;
        padding: 0 .8em 0 0;
    }
}

.gw-dashboard-tile-list-item-metric-status {
    &__icon {
        position: relative;
        vertical-align: middle;
        height: 26px;
        bottom: .1em;
        border-radius: 0px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 3px;
        background: transparent;
        background-repeat: no-repeat;
        background-position: 50%, 50%;
        fill: var(--th-slate);
    }
}

// Groundworks panes & resources

.gw-dashboard-pane {
    min-height: 15.8em;
    height: 26em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid;
    @include variable( border-color, --dashboardPaneBorderColor,  --dashboardPaneBorderColor, rgba(92, 106, 138, 0.4) );
    @include variable( background-color, --dashboardPaneBackgroundColor,  --dashboardPaneBackgroundColor, hsl(0, 0%, 99%) );
    //background-color: #fcfcfc;
    padding-top: 8em;

    @media only screen and (max-width: 992px) {
        height: 15.8em;
        padding-top: 0;
        justify-content: center;
    }

    &__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        transition: all 0.05s ease-in-out 0.05s;
        transform: scale(1);
        text-align: center;

        @media only screen and (max-width: 992px) {
            flex-direction: row;

            :first-child {
                margin-right: 10px;
            }
        }
    }

    &--dashed {
        border: 0;
        @include variable( background-image, --dashboardPaneDashedBorderColor,  --th-slate-light, hsl(222, 20%, 45%) );
        //background-image: var(--dashboardPaneDashedBorderColor);
    }
}

.gw-dashboard-resources {
        height: 7.6em;
        min-height: 7.6em;
        box-shadow: none;
        background-color: #fcfcfc;
        border: 1px solid;
        @include variable( border-color, --dashboardPaneBorderColor,  --dashboardPaneBorderColor, rgba(92, 106, 138, 0.4) );
        @include variable( background-color, --dashboardPaneBackgroundColor,  --dashboardPaneBackgroundColor, hsl(0, 0%, 99%) );
        border-radius: 0;
        margin-bottom: 10px;

    &__inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1em; 
        font-size: 1.6em;
        color: var(--dashboardTextColor);
    }

        &--topgap {
            margin-top: 41.26px;
        } 
}

.gw-dashboard-resources-icon {
    height: 34px;
    margin-right: 2em;
}

.gw-dashboard-pane-title {
font-family: "Roboto", sans-serif;
    text-shadow: none;
    //font-size: 1.7em;
}

.orbitdoc-gap-mb50 {
    margin-bottom: 5em;

    @media only screen and (max-width: 992px) {
        margin-bottom: 1em;
    }
}

.calendar-padding {
    padding: 0 3em 2.9em;
}