

@import '../mixins/variable';
@import 'button';

/** Button
---------------------------------------------------------*/

.gw-button-outlined {
    @extend .gw-button;

        background-color: transparent;
        border-color: var(--defaultColor);
        color: var(--fontColorDark);

        &:visited {
            color: var(--fontColorDark);
            text-decoration: none;
        }

        &:hover {
            border-color: var(--defaultColorHover);
            background-color: var(--defaultColorHover);
            color: var(--fontColorDark);
        }

        &:active {
            border-color: var(--defaultColorActive);
            background-color: var(--defaultColorActive);
            color: var(--fontColorDark);
        }

        &--primary {
            background-color: transparent;
            color: var(--fontColorDark);
            @include variable( border, --primaryButtonBorder, --primaryButtonBorder, hsl(211, 100%, 45%));
            @include variable( border-radius, --buttonBorderRadius, --buttonBorderRadius, 30px);

            &:visited {
                color: var(--fontColorDark);
                text-decoration: none;
            }

            &:hover {
                @include variable( background-color, --primaryButtonBackgroundHoverColor, --primaryColorHover, hsl(211, 100%, 35%));
                @include variable( border, --primaryButtonBorderHover, --primaryButtonBorderHover, 2px solid hsl(211, 100%, 35%));
                color: var(--fontColorWhite);
            }

            &:active {
                @include variable( border, --primaryButtonBorderHover, --primaryButtonBorderHover, 2px solid hsl(211, 100%, 35%));
                @include variable( background-color, --primaryButtonBackgroundActiveColor, --primaryColorActive, hsl(211, 100%, 25%));
                color: hsl(0, 0%, 100%);
            }
        }

        &--secondary {
            background-color: transparent;
            @include variable( border, --secondaryButtonBorder, --secondaryButtonBorderColor, 2px solid hsl(0, 0%, 28%));
            color: var(--fontColorDark);

            &:visited {
                color: var(--fontColorDark);
                text-decoration: none;
            }

            &:hover {
                border-color: var(--secondaryColorHover);
                background-color: var(--secondaryColorHover);
                color: var(--fontColorWhite);
            }

            &:active {
                border-color: var(--secondaryColorActive);
                background-color: var(--secondaryColorActive);
                color: hsl(0, 0%, 100%);
            }
        }

        &:disabled,
        &[disabled] {
            pointer-events: none;
            color: var(--disabledColor);
            border-color: var(--disabledColor);
    
            & .gw-button__icon {
                opacity: 0.2;
            }
        }

        &--green {
            background-color: transparent;
            border-color: var(--primaryColorGreen);
            color: var(--fontColorDark);

            &:visited {
                color: var(--fontColorDark);
                text-decoration: none;
            }

            &:hover {
                border-color: var(--primaryColorGreenHover);
                background-color: var(--primaryColorGreenHover);
                color: var(--fontColorWhite);
            }

            &:active {
                border-color: var(--primaryColorGreenActive);
                background-color: var(--primaryColorGreenActive);
                color: hsl(0, 0%, 100%);
            }
        }

        &--pink {
            background-color: transparent;
            border-color: var(--primaryColorPink);
            color: var(--fontColorDark);

            &:visited {
                color: var(--fontColorDark);
                text-decoration: none;
            }

            &:hover {
                border-color: var(--primaryColorPinkHover);
                background-color: var(--primaryColorPinkHover);
                color: hsl(0, 0%, 100%);
            }

            &:active {
                border-color: var(--primaryColorPinkActive);
                background-color: var(--primaryColorPinkActive);
                color: hsl(0, 0%, 100%);
            }
        }

        &--teal {
            background-color: transparent;
            border-color: var(--primaryColorTeal);
            color: var(--fontColorDark);

            &:visited {
                color: var(--fontColorDark);
                text-decoration: none;
            }

            &:hover {
                border-color: var(--primaryColorTealHover);
                background-color: var(--primaryColorTealHover);
                color: var(--fontColorWhite);
            }

            &:active {
                border-color: var(--primaryColorTealActive);
                background-color: var(--primaryColorTealActive);
                color: hsl(0, 0%, 100%);
            }
        }

        &--purple {
            background-color: transparent;
            border-color: var(--primaryColorPurple);
            color: var(--fontColorDark);

            &:visited {
                color: var(--fontColorDark);
                text-decoration: none;
            }

            &:hover {
                border-color: var(--primaryColorPurpleHover);
                background-color: var(--primaryColorPurpleHover);
                color: var(--fontColorWhite);
            }

            &:active {
                border-color: var(--primaryColorPurpleActive);
                background-color: var(--primaryColorPurpleActive);
                color: hsl(0, 0%, 100%);
            }
        }

        &--orange {
            background-color: transparent;
            border-color: var(--primaryColorOrange);
            color: var(--fontColorDark);

            &:visited {
                color: var(--fontColorDark);
                text-decoration: none;
            }

            &:hover {
                border-color: var(--primaryColorOrangeHover);
                background-color: var(--primaryColorOrangeHover);
                color: var(--fontColorWhite);
            }

            &:active {
                border-color: var(--primaryColorOrangeActive);
                background-color: var(--primaryColorOrangeActive);
                color: hsl(0, 0%, 100%);
            }
        }

        &--blue {
            background-color: transparent;
            border-color: var(--primaryColorBlue);
            color: var(--fontColorDark);

            &:visited {
                color: var(--fontColorDark);
                text-decoration: none;
            }

            &:hover {
                border-color: var(--primaryColorBlueHover);
                background-color: var(--primaryColorBlueHover);
                color: var(--fontColorWhite);
            }

            &:active {
                border-color: var(--primaryColorBlueActive);
                background-color: var(--primaryColorBlueActive);
                color: hsl(0, 0%, 100%);
            }
        }

        &--s1 {
            @extend .gw-button--s1;
        }
    
        &--s2 {
            @extend .gw-button--s2;
        }
    
        &--s3 {
            @extend .gw-button--s3;
        }
    
        /**
         * @Full Width
         */
    
        &--full-width {
            @extend .gw-button--full-width;
        }

        // Dark

        &--dark {
            background-color: transparent;
            color: var(--fontColorWhite);

            &:visited {
                color: var(--fontColorWhite);
                text-decoration: none;
            }

            &:hover {
                color: var(--fontColorWhite);
            }

            &:active {
                color: hsl(0, 0%, 100%);
            }

        }
    
}