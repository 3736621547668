//  Headings
h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    color: hsl(0, 0%, 0%);
}

h1 {
    font-size: 3em;
    margin-bottom: 1.5em;
    line-height: 1.5em;
}
h2 {
    font-size: 2.6em;
    margin-bottom: 1.5em;
    line-height: 2em;
}
h3 {
    font-size: 2em;
    margin-bottom: 1.5em;
    line-height: 1.5em;
}
h4 {
    font-size: 1.8em;
    margin-bottom: 1.4em;
    line-height: 1.8em;
}
h5 {
    font-size: 1.6em;
    margin-bottom: 1.6em;
    line-height: 1.6em;
}
h6 {
    font-size: 1.4em;
    margin-bottom: 1.4em;
    line-height: 1.4em;
}

// Body
p {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.4em;
    line-height: 1.4em;
    font-weight: 400;
    margin-bottom: 1em;
    color: hsl(0, 0%, 0%);
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}