@import "../mixins/variable";

//** Calendar measurements

$title-height: 8.8em;
$days-height: 8em;
$time-width: 3em;
$time-height: 6em;
$grid-color: var(--th-grey);
$calendar-template: $time-width 10px repeat(7, 1fr);
$current-time-color: #D61131;
$event-color: var(--brandColorPurple);
$event-color1: var(--BrandcolorYellow);


.gw-dashboard-calendar {
    //margin-top: 3em;
    width: 100%;
    display: grid;
    grid-template-rows: $title-height $days-height auto;
    position: relative;
    font-size: 10px;

    &__border-filler {
      position: absolute;
      top: -3em;
      width: 100%;
      height: 4em;
      background-color: #fff;
      z-index: 9;
    }

    &__title {
        background: #fff;
        display: grid;
        color: #fff;
        position: sticky;
        top: 0;
        z-index: 11;

        &:before {
          background-color: white;
          content: '';
          display: block;
          position: absolute;
          top: -30px;
          height: 30px;
          width: 100%;
        }
    }

    &__days {
        background: #fff;
        display: grid;
        place-content: center;
        text-align: center;
        grid-template-columns: $calendar-template;
        position: sticky;
        top: $title-height;
        z-index: 10;
        border-bottom: 1px dashed $grid-color;
    }

    &__day {
        //border-left: 1px solid $grid-color;
        text-align: center;
        font-size: 1.2em;
        font-weight: 500;
        color: var(--th-slate-light);
        display: flex;
        justify-content: center;
        align-items: center;

        &--current {
            color: var(--primaryColorReachDeckHover);
            font-weight: bold;
        }
      }

    &__content {
        display: grid;
        grid-template-columns: $calendar-template;
        grid-template-rows: repeat(11, $time-height);
        font-size: 10px;
        height: 100%;
        //overflow-x: scroll;  Possibly enable overflow scroll to avoid sticky divs sitting on top of content
    }

    &__time {
        grid-column: 1;
        text-align: right;
        align-self: end;
        position: relative;
        bottom: -1ex;
        color: var(--th-slate-light);
        padding-right: 2px;
    }

    &__col {
        //border-right: 1px solid $grid-color;
        grid-row: 1 / span 11;
        grid-column: span 1;

        &:nth-child(odd) {
            background-color: rgba(0,134,197, .04);
          }
      }

    &__filler-col {
        grid-row: 1 / -1;
        grid-column: 2;
        border-right: 1px solid $grid-color;
      }
      
    &__row {
        grid-column: 2 / -1;
        border-bottom: 1px dashed $grid-color;
      }
      
    &__event {
        border-radius: 0;
        
        padding: .5em 0 .5em 1.5em;
        margin: .3em 1em .3em .3em;
        font-size: 1.2em;
        font-weight: 400;
        font-family: "Open Sans", sans-serif;
        overflow: hidden;
        box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.06);
        display: flex;
        align-items: center;
        background-color: #fff;
        cursor: pointer;

        &:hover {
          background-color: var(--th-grey-light);
          border-right: 2px solid #fff;
          border-top: 2px solid #fff;
          border-bottom: 2px solid #fff;
        }

        &:visited {
          background-color: #fff;
        }

        &:active {
          background-color: var(--th-grey);
        }
        

        &--title {
          font-size: 1.2em;
          font-family: "Roboto", sans-serif;
          font-weight: bold;
          margin-bottom: .5em;
          flex-basis: 100%;
          overflow-x: hidden;
          white-space: nowrap; 
          width: 1.5em; 
          overflow: hidden;
          text-overflow: clip; 
        }

        &--disabled {
          pointer-events: none;
          opacity: 0.4;
        }
      }
      
    &__weekend {
        //background-color: #f1f3f4;
      }
      
    &__calendar1 {
        border-left: 3px solid $event-color;
      }
      
    &__calendar2 {
        border-left: 3px solid $event-color1;
      }
      
    &__event1 {
        grid-column: 3;
        grid-row: 2 / span 1;
      }
      
    &__event2 {
        grid-column: 5;
        grid-row: 6 / span 1;
      }
      
    &__event3 {
        grid-column: 7;
        grid-row: 8 / span 1;
      }
      
    &__event4 {
        grid-column: 8;
        grid-row: 3 / span 1;
      }
      
    &__current-time {
        grid-column: 5;
        grid-row: 5;
        border-top: 2px solid $current-time-color;
        position: relative;
        top: calc(50% - 1px);
        height: 1em;
      }
      
    &__circle {
        width: 12px;
        height: 12px;
        border: 1px solid $current-time-color;
        border-radius: 50%;
        background: $current-time-color;
        position: relative;
        top: -6px;
        left: -6px;
      }
      
    &__current-date {
        width: 2.2em;
        height: 2.2em;
        margin-left: 1em;
        border-radius: 10em;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--primaryColorReachDeck);
        color: #fff;
      }
}