.gw-language-select {
    position: relative;
    display: flex;
    flex-direction: row;
    z-index: 1;

    &__input {
        flex-grow: 2;
        padding: 0 2em 0 2.6em;
        background: transparent url(svg-data-uri("0 0 18 18", '<path d="M9.234,5.784A6.384,6.384,0,0,0,7.867,7.857H6.584a6.457,6.457,0,0,1,2.65-2.073ZM5.826,9.143H7.47a11.141,11.141,0,0,0-.239,1.928H5.318a6.371,6.371,0,0,1,.508-1.928Zm0,5.143a6.371,6.371,0,0,1-.508-1.928H7.231a11.141,11.141,0,0,0,.239,1.928Zm.757,1.286H7.867a6.384,6.384,0,0,0,1.367,2.073,6.457,6.457,0,0,1-2.65-2.073Zm4.487,1.967a5.842,5.842,0,0,1-1.8-1.967h1.8Zm0-3.253H8.779a9.41,9.41,0,0,1-.265-1.928h2.556Zm0-3.214H8.515a9.41,9.41,0,0,1,.265-1.928h2.292Zm0-3.214h-1.8a5.842,5.842,0,0,1,1.8-1.967Zm5.773,0H15.561a6.386,6.386,0,0,0-1.368-2.073,6.457,6.457,0,0,1,2.651,2.073ZM12.357,5.89a5.844,5.844,0,0,1,1.8,1.967h-1.8Zm0,3.253h2.292a9.41,9.41,0,0,1,.265,1.928H12.357Zm0,3.214h2.556a9.41,9.41,0,0,1-.265,1.928H12.357Zm0,5.181V15.571h1.8A5.844,5.844,0,0,1,12.357,17.538Zm1.837.106a6.386,6.386,0,0,0,1.368-2.073h1.283a6.457,6.457,0,0,1-2.651,2.073ZM17.6,14.285H15.957a11.137,11.137,0,0,0,.239-1.928h1.913A6.37,6.37,0,0,1,17.6,14.285ZM16.2,11.071a11.137,11.137,0,0,0-.239-1.928H17.6a6.37,6.37,0,0,1,.508,1.928Zm3.231.643a7.714,7.714,0,1,0-7.714,7.714,7.714,7.714,0,0,0,7.714-7.714Z" transform="translate(-4 -4)" fill="#fff"/> ', (height: 22px, width: 22px))) no-repeat;
        background-repeat: no-repeat, repeat;
        background-position: left 1.2em top 59%, 0 0;
        background-size: 1.8em auto, 100%;
        background-color: transparent;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border: none;
    }
}