@import "../mixins/background-sprites";

/** Buttons
---------------------------------------------------------*/


:root {
// General
--transparent: rgba(255, 255, 255, 0);
--white: hsl(0, 0%, 100%);
--th-white: hsl(0, 0%, 100%);
--black: hsl(0, 0%, 0%);
--th-black: hsl(0, 0%, 0%);
--lightGrey: hsl(0, 0%, 94%);

// Grey Colours

--th-grey-white: hsl(210, 10%, 97%);
--th-grey-light: hsl(210, 10%, 94.8%);
--th-grey-medium: hsl(212, 10%, 94%);
--th-grey: hsl(216, 22%, 91%);
--th-grey-dark: hsl(221, 20%, 78%);
--th-slate: hsl(221, 20%, 33%);
--th-slate-light: hsl(222, 20%, 45%);
--th-slate-dark: hsl(222, 22%, 18%);

// Dark Mode Optional Colours
--th-slate-alt: #333F57;
--th-slate-hover:#3E4A62;
--th-slate-dark-hover800:hsl(220, 22%, 24%);
--th-slate-dark-active800:hsl(219, 24%, 26%);
--th-slate-dark-hover600:hsl(222, 22%, 21%);
--th-slate-dark-active600:hsl(221, 24%, 23%);
--th-cool-grey-600: hsl(203, 16%, 44%);
--th-cool-grey-600: hsl(204, 16%, 38%);
--th-cool-grey-700: hsl(204, 18%, 30%);
--th-cool-grey-750: hsl(203, 18%, 32%);
--th-cool-grey-800: hsl(209, 20%, 25%);
--th-cool-grey-850: hsl(209, 20%, 21%);
--th-cool-grey-900: hsl(210, 24%, 16%);

--fontColorDark: var( --th-slate-dark);
--fontColorWhite: hsl(0, 0%, 100%);
--list-item-hover:hsl(225, 20%, 96%);
--list-item-light-hover: hsl(210, 20%, 98%);

--list-item-hover-dark:hsla(222, 22%, 18%, 0.50);
--list-item-light-hover-dark: hsla(222, 20%, 33%, 0.50);

// Opacity Colors
--greyTransparent: hsla(222, 22%, 18%, 0.12);
--whiteTransparent: hsla(0, 0%, 100%, 0.12);

// Default Colours

--defaultColor: hsl(220, 20%, 91%);
--defaultColorHover: hsl(220, 19%, 78%);
--defaultColorActive: hsl(220, 19%, 68%);
--defaultColorSelected: hsl(221, 20%, 75%);

--buttonBorderColor: 2px solid hsl(220, 20%, 91%);
--buttonBorderColorHover: 2px solid hsl(220, 19%, 78%);
--buttonBorderColorActive: 2px solid hsl(220, 19%, 68%);
--buttonBorderColorSelected: 2px solid hsl(221, 20%, 75%);

--iconFillColor: var( --th-slate-dark );

// Primary Colours

--primaryColor: hsl(211, 100%, 45%);
--primaryColorHover: hsl(211, 100%, 35%);
--primaryColorActive: hsl(211, 100%, 25%);
--primaryButtonBorder: 2px solid hsl(211, 100%, 45%);
--primaryButtonBorderHover: 2px solid hsl(211, 100%, 35%);
--primaryButtonBorderActive: 2px solid hsl(211, 100%, 25%);

// Secondary Colours

--secondaryColor: var(--th-slate);
--secondaryColorHover: hsl(221, 20%, 23%);
--secondaryColorActive: hsl(221, 20%, 13%);
--secondaryButtonBorderColor: 2px solid var(--th-slate);
--secondaryButtonBorderColorHover: 2px solid hsl(221, 20%, 23%);
--secondaryButtonBorderColorActive: 2px solid hsl(221, 20%, 13%);

--disabledColor: hsl(0, 0%, 50%);
--disabledBackgroundColor: hsl(0, 0%, 95%);

// Products
--brandColorGreen: hsl(76, 60%, 52%);
--brandColorGreenActive: hsl(76, 62%, 40%);
--primaryColorGreen: hsl(79, 100%, 24%);
--primaryColorGreenHover: hsl(79, 100%, 14%);
--primaryColorGreenActive: hsl(79, 100%, 4%);
--buttonBorderGreen: 2px solid hsl(79, 100%, 24%);
--buttonBorderGreenHover: 2px solid hsl(79, 100%, 14%);
--buttonBorderGreenActive: 2px solid hsl(79, 100%, 4%);

--brandColorPink: hsl(344, 98%, 59%);
--primaryColorPink: #e12362;
--primaryColorPinkHover: hsl(340, 76%, 41%);
--primaryColorPinkActive: hsl(340, 76%, 31%);
--buttonBorderPink: 2px solid hsl(340, 76%, 51%);
--buttonBorderPinkHover: 2px solid hsl(340, 76%, 41%);
--buttonBorderPinkActive: 2px solid hsl(340, 76%, 31%);

--brandColorTeal: hsl(180, 85%, 34%);
--primaryColorTeal: hsl(184, 50%, 35%);
--primaryColorTealHover: hsl(184, 50%, 25%);
--primaryColorTealActive: hsl(184, 50%, 15%);
--buttonBorderTeal: 2px solid hsl(184, 50%, 35%);
--buttonBorderTealHover: 2px solid hsl(184, 50%, 25%);
--buttonBorderTealActive: 2px solid hsl(184, 50%, 15%);

--brandColorPurple: hsl(264, 100%, 68%);
--primaryColorPurple: hsl(264, 83%, 62%);
--primaryColorPurpleHover: hsl(264, 83%, 52%);
--primaryColorPurpleActive: hsl(264, 83%, 42%);
--buttonBorderPurple: 2px solid hsl(264, 83%, 62%);
--buttonBorderPurpleHover: 2px solid hsl(264, 83%, 52%);
--buttonBorderPurpleActive: 2px solid hsl(264, 83%, 42%);

--brandColorOrange: hsl(33, 94%, 48%);
--primaryColorOrange: hsl(15, 79%, 45%);
--primaryColorOrangeHover: hsl(15, 80%, 35%);
--primaryColorOrangeActive: hsl(15, 80%, 25%);
--buttonBorderOrange: 2px solid hsl(15, 79%, 45%);
--buttonBorderOrangeHover: 2px solid hsl(15, 80%, 35%);
--buttonBorderOrangeActive: 2px solid hsl(15, 80%, 25%);

--brandColorBlue: hsl(197, 100%, 50%);
--brandColorBlueActive: hsl(197, 100%, 39%);
--brandColorBlueWCAG: hsl(197, 100%, 42%);
--primaryColorBlue: hsl(201, 100%, 37%);
--primaryColorBlueHover: hsl(201, 100%, 27%);
--primaryColorBlueActive: hsl(201, 100%, 17%);
--buttonBorderBlue: 2px solid hsl(201, 100%, 37%);
--buttonBorderBlueHover: 2px solid hsl(201, 100%, 27%);
--buttonBorderBlueActive: 2px solid hsl(201, 100%, 17%);
--BrandcolorYellow: hsl(41, 90%, 61%);

--primaryColorReachDeck: hsl(209, 100%, 39%);
--primaryColorReachDeckHover: hsl(209, 100%, 29%);
--primaryColorReachDeckActive: hsl(209, 100%, 19%);
--buttonBorderReachDeck: 2px solid hsl(209, 100%, 39%);
--buttonBorderReachDeckHover: 2px solid hsl(209, 100%, 29%);
--buttonBorderReachDeckActive: 2px solid hsl(209, 100%, 19%);

--reachDeckEditorColor: hsl(112, 100%, 26%);

--launchpadButtonLight: hsl(210, 10%, 96%);
--launchpadButtonLightHover: hsl(220, 8%, 92%);
--launchpadButtonLightActive:hsl(0, 0%, 90%);

--ContrastPinkOrbitNoteHover: hsl(343, 81%, 48%);
--ContrastPinkOrbitNoteActive: hsl(345, 96%, 42%);
--ContrastButtonBorderPink: hsl(345, 96%, 42%);

//Claro
--primaryColorClaroNavy: hsl(207, 74%, 27%);
--primaryColorClaroNavyHover: hsl(206, 83%, 21%);
--primaryColorClaroNavyActive: hsl(206, 89%, 17%);
--buttonBorderClaro: 2px solid  hsl(207, 74%, 27%);
--buttonBorderClaroHover: 2px solid hsl(206, 83%, 21%);
--buttonBorderClaroActive: 2px solid hsl(206, 89%, 17%);

--claroDark: hsl(218, 54%, 20%);
--claroGrey: hsl(217, 31%, 68%);
--claroBlue: hsl(191, 88%, 40%);
--claroBlueAlt: hsl(188, 78%, 50%);
--claroGreen: hsl(75, 63%, 51%);

// Claro Alt green Colours
--altColor: var(--claroGreen);
--altColorHover: hsl(75, 70%, 40%);
--altColorActive: hsl(75, 77%, 33%);
--altButtonBorderColor: 2px solid var(--claroGreen);
--altButtonBorderColorHover: 2px solid  hsl(75, 70%, 40%);
--altButtonBorderColorActive: 2px solid hsl(75, 77%, 33%);

//Lingdys
--brandColorLingdys: hsl(0, 82%, 49%);
--primaryColorLingdys: hsl(217, 34%, 38%);
--primaryColorLingdysHover: hsl(217, 37%, 24%);
--primaryColorLingdysActive: hsl(217, 39%, 18%);
--buttonBorderLingdys: 2px solid  hsl(217, 34%, 38%);
--buttonBorderLingdysHover: 2px solid hsl(217, 37%, 24%);
--buttonBorderLingdysActive: 2px solid hsl(217, 39%, 18%);

//Appwriter
--brandColorAppWriter: #F70790;
--primaryColorAppWriter: #D8087F;
--primaryColorAppWriterHover:#B40669;
--primaryColorAppWriterActive: #9F055C;
--buttonBorderAppwriter: 2px solid   #D8087F;
--buttonBorderAppwriterHover: 2px solid #B40669;
--buttonBorderAppWriterActive: 2px solid #9F055C;


//Orbitnote dark-mode icon fill
--iconToggleFillOrbitNote: hsl(196, 99%, 50%);

// Base

--textColor: var( --th-slate-dark );
--textColorLight: var( --th-white );
--border: 1px solid var( --th-grey );
--borderDark: 1px solid var( --th-grey-dark );

--infoColor: hsl(194, 77%, 24%);
--successColor: hsla(134, 71%, 43%, 0.584);
--errorColor: hsl(0, 71%, 51%);
--warningColor: hsl(45, 97%, 51%);

--infoBackgroundColor: hsl(180, 100%, 99%);
--successBackgroundColor: hsl(78, 100%, 98%);
--errorBackgroundColor: hsl(0, 100%, 98%);
--warningBackgroundColor: hsl(35, 100%, 98%);
--notificationDarkModeBackground: hsl(218, 22%, 37%);

--infoBorderColor: hsl(191, 44%, 82%);
--successBorderColor: hsl(100, 28%, 69%);
--errorBorderColor: hsl(0, 37%, 73%);
--warningBorderColor: hsl(41, 30%, 71%);
--notificationDarkModeBorder: hsl(217, 19%, 41%);

--infoTitleColor: hsl(194, 77%, 24%);
--successTitleColor: hsl(121, 48%, 23%);
--errorTitleColor: hsl(1, 47%, 40%);
--warningTitleColor: hsl(37, 92%, 25%);

--infoWarningColor: hsl(194, 77%, 24%);
--successWarningColor: hsla(121, 48%, 23%, 0.8);
--errorWarningColor: hsla(1, 47%, 40%, 0.8);
--warningWarningColor: hsla(37, 92%, 25%, 0.8);

//** Unit-less `line-height` for use in components like buttons.
--line-height-base: 1.5; // 20/14
--zindex-modal: 1050;
--modal-dialog-margin: 2rem;
--modal-content-border-radius: 1px;

//== Buttons

--buttonBorderRadius: 30px;
--buttonRoundness: var(--buttonBorderRadius);


// Button link
--buttonLinkTextColor: var( --primaryColor)!important;
--buttonLinkIconColor: var( --primaryColor)!important;

// Button no background
--buttonNoBackgroundTextColor: var( --th-slate-dark);
--buttonNoBackgroundIconColor: var( --th-slate-dark);
--buttonNoBackgroundColor: transparent;
--buttonNoBackgroundColorHover: var(--icon-button-background-hover-color);
--buttonNoBackgroundColorActive: var(--icon-button-background-active-color);
--buttonNoBackgroundBorder: 2px solid transparent;
--buttonNoBackgroundBorderHover: 2px solid transparent;
--buttonNoBackgroundBorderActive: 2px solid transparent;

// Toggle button dot
--activeDotColor: inherit;

// Product theme colors
--themeColorDefault: var(--defaultColor);
--themeColorGroundworks: var(--primaryColor);
--themeColorReadWrite: var(--primaryColorPurple);
--themeColorEquatio: var(--primaryColorBlue);
--themeColorBrowsealoud: var(--primaryColorOrange);
--themeColorWriq: var(--primaryColorTeal);
--themeColorFluencyTutor: var(--primaryColorGreen);
--themeColorSnapVerter: var(--primaryColorGreen);
--themeColorSpeechStream: var(--primaryColorPink);
--themeColorTexthelp: var(--primaryColorTeal);
--themeColorReachDeck: var(--primaryColorReachDeck);
--themeColorPdfReader: var(--primaryColorBlue);

// Unilogin
--uniloginBackgroundColor: #F7F7F7;
--uniloginWindowBackgroundColor: var(--primaryColorBlue);

//** Unit-less `line-height` for use in components like buttons.
--line-height-base: 1.5; // 20/14
--zindex-modal: 1050;
--modal-dialog-margin: 2em;
--modal-content-border-radius: 1px;

//== Modals

//** Padding applied to the modal body
--modal-inner-padding: 1.5em;

//** Padding applied to the modal title
--modal-title-padding: 1.5em;
//** Modal title line-height
--modal-title-line-height: var(--line-height-base);

//** Background color of modal content area
--modal-content-bg: #fff;
//** Modal content border color
--modal-content-border-color: rgba(0, 0, 0, .2);

//** Modal content border color **for IE8**
--modal-content-fallback-border-color: #999;

//** Modal backdrop background color
--modal-backdrop-bg: #000;
//** Modal backdrop opacity
--modal-backdrop-opacity: .5;
//** Modal header border color
--modal-header-border-color: #e5e5e5;
//** Modal footer border color
--modal-footer-border-color: var(--modal-header-border-color);
//** Modal header background color
--modal-header-background-color: #f5f5f5;

// Select input
--select-border: 1px solid #DADADA;
--select-background: hsl(0, 0%, 100%);
--select-background-color: #efefef;
--select-background-color-hover: hsl(0, 0%, 97%);

// input

--input-border: 1px solid #DADADA;
--input-background-color: #ffffff;

// Settings
--settings-title-color: var(--primaryColor);
--settings-item-background-color: var(--th-white);
--settings-item-background-hover-color: var(--th-grey-medium);
--settings-item-background-active-color: var(--th-grey);

// List Group
--list-group-background-color: transparent; 
--list-group-item-background-color: var(--th-white);
--list-group-item-background-hover-color: var(--th-grey-light);
--list-group-item-background-active-color: var(--th-grey);
--list-group-item-icon-color: var(--th-slate-dark);

--ListGroupSearchItemColor: var(--th-white);
--ListGroupSearchItemColorHover: #f9fafb;
--ListGroupSearchItemTextColor: var(--th-slate);
--ListGroupSearchItemBorderColor: hsla(0, 0%, 0%, 0.15);
--ListGroupSearchItemAsideColor: hsl(0, 0%, 95%);
--ListGroupSearchItemAsideTextColor: #000;

//Web Components Toolbar Buttons

--toolbar-button-disabled: hsl(0, 0%, 96.1%);
--toolbar-button-background: hsl(0, 0%, 96.1%);
--toolbar-button-hover-background: hsl(0, 0%, 80%);
--toolbar-button-color: hsl(0, 0%, 0%);
--toolbar-button-hover-active-background: hsl(0, 0%, 70.2%);
--toolbar-button-default-size: 43px;
--toolbar-button-focus-color: hsl(213, 71.3%, 60.4%);
--toolbar-button-svg-color: hsl(0, 0%, 29%);
--toolbar-button-toggle-color: hsl(264, 100%, 68%);

// Padding
--panel-inner-padding: .7em;


// Icon buttons

// light theme
--icon-button-background-default-color: hsla(0, 0%, 0%, 0.077);
--icon-button-background-hover-color: hsla(0, 0%, 0%, 0.125);
--icon-button-background-active-color: hsla(0, 0%, 0%, 0.187);

// dark theme
--icon-button-background-default-color-dark: rgba(255, 255, 255, 0.077);
--icon-button-background-hover-color-dark: rgba(255, 255, 255, 0.146);
--icon-button-background-active-color-dark: rgba(255, 255, 255, 0.221);

--icon-button-border-color: solid 2px transparent;

// Icons 
--menuIconFillColor: var(--th-slate);
--cardIconFillColor: var(--th-slate);

// notify 
--default-menu-notify-background-colour: hsl(264, 100%, 68%);

// Toolbar Buttons

--toolbarButtonBackgroundColor: var( --defaultColor);
--toolbarButtonBackgroundHoverColor: var( --icon-button-background-hover-color);
--toolbarButtonBackgroundActiveColor: var( --icon-button-background-active-color);

--toolbarIconHoverFill: var( --th-slate);
--toolbarIconToggleFill: var(  --primaryColor);

// Dashboard 

--dashboardContentBackgroundColor: #fff;
--dashboardHeaderBackgroundColor: #fff;
--dashboardSidebarBorderTopColor: var( --lightGrey);

--dashboardTextColor: var(--th-slate);
--dashboardTitleTextColor: var(--fontColorDark);
--dashboardSubTitleTextColor: var(--th-slate-light);

// Dashboard cards / panes
--dashboardCardBackgroundColor: var(--white);
--dashboardCardBackgroundHoverColor: var(--th-grey-light);
--dashboardCardBackgroundActiveColor: var(--lightGrey);
--dashboardCardHighlightColor: var(--primaryColor);
--dashboardCardBorderColor: var(--lightGrey);
--dashboardCardFooterLinkColor: var(--primaryColor);


--dashboardCardAuditorColor: hsl(208, 100%, 43%);
--dashboardCardAuditorLabelColor: hsl(208, 100%, 43%);

--dashboardCardEditorLabelColor: var(--reachDeckEditorColor);

--dashboardCardToolbarColor: var(--primaryColorOrange);
--dashboardCardToolbarLabelColor: var(--primaryColorOrange);

--dashboardPaneBackgroundColor: #fcfcfc;
--dashboardPaneBorderColor:  rgba(92, 106, 138, 0.4);
--dashboardPaneDashedBorderColor: var(--dashboardDashedBorderLight);

--dashboardDashedBorderLight: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%235C6A8ACC' stroke-width='2' stroke-dasharray='4%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
--dashboardDashedBorderDark: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23323E52FF' stroke-width='2' stroke-dasharray='4%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

--icon-opacity: 1;

// Accordion 
--accordionBackgroundColor: #fff;
--accordionTextColor: #484848;
--accordionTextBottomColor: #484848;
--accordionIconColor: var(--toolbarWhite);
--accordionBackgroundColorHover: #f9fafb;

// Accordion 
--tableBackgroundColor: none;
--tableBackgroundHeaderColor: #F9FAFB;
--tableBackgroundAltColor: #f7f7f7;
--tableBackgroundHoverColor: hsl(210, 20%, 98%);
--tableBorderColor: #E2E2E3;
--tableTextColor: #484848;

}



