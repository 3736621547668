@import '../mixins/variable';

//main accordion, sets margin and shadow
.gw-accordion {
  position: relative;
  background: var(--accordionBackgroundColor) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 16px #00000029;
  border-radius: 5px;
  opacity: 1;

  //main container 
.gw-accordion-section {
    position: relative;
  }
  //top container for the main title/data to always be displayed
  .gw-accordion-section__top {
    display: block;
    font-size: 1.4em;
    font-weight: 600;
    padding: var(--accordion-inner-padding) var(--accordion-inner-padding) var(--accordion-inner-padding) calc(var(--accordion-inner-padding) * 1.5 );
    //color: #484848;
    color: var(--accordionTextColor);
    white-space: normal;
    cursor: pointer;
    position: static;
    transition: all 0.2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: -webkit-fill-available;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    &:hover {
      //@include variable( background-color, --table-hover-item,  list-item-light-hover, hsl(210, 20%, 98%) );
      background-color: var(--accordionBackgroundColorHover);
    }
  }
  //the collapse/expand button positioning and size
  .gw-accordion-icon {
    font-family: Open Sans,sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: var(--accordionIconColor);
    height: 28px;
    width: 28px;
    top: 8px;
    right: 9px;
    border: none;
    z-index: 0;
    transition: .2s;
    border-radius: 25px;
  }
  //collapsed accordion button icon
  .gw-button__icon--accordion {
    content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' height='20px' viewBox='0 0 9 5.56'>%3Cpath d='M4.5 0L0 4.5l1.06 1.06L4.5 2.12l3.44 3.44L9 4.5z'/%3E</svg>");
    transform: rotate(-90deg);
    top: 0;
    right: 1px;
    height: 20px;
  }
  //expanded accordion button icon
  .gw-button__icon--accordion-expanded {
    content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' height='20px' viewBox='0 0 9 5.56'>%3Cpath d='M4.5 0L0 4.5l1.06 1.06L4.5 2.12l3.44 3.44L9 4.5z'/%3E</svg>");
    transform: rotate(-180deg);
    top: 2px;
    height: 20px;
  }
  //hidden content for accessability
  .gw-hiddenContent {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 0px;
    height: 0px;
    overflow: hidden;
  }
  //the container for the data to be shown when the accordion is collapsed
  .gw-accordion-section__bottom {
    position: relative;
    width: 100%;
    display: none;
  }
  //the container for the data to be shown when the accordion is expanded
  .gw-accordion-section__bottom--shown {
    display: block;
    padding:  calc(var(--accordion-inner-padding) * 1.6); 
    font-size: 13px;
    font-family: 'Roboto', sans-serif;
    color: var(--accordionTextBottomColor);
  }
}

// Mini variant
.gw-accordion-mini {
    .gw-accordion-section {
        display: flex;
        flex-direction: column;
    }

    .gw-accordion-section__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.3em;
        line-height: 1.3em;
        font-weight: 600;
        margin: 0 0 0.3em 0;
        box-sizing: border-box;
        color: var(--fontColorDark);

        &--button {
            cursor: pointer;
            background: none;
            border: none;
            padding: 0.5em 1em 0.5em 0;
            transition: opacity ease .25s;

            & i {
                width: 0.7em;
                height: 0.7em;
                display: block;
            }

            &:hover {
                opacity: .8;
            }
        }
    }

    //the container for the data to be shown when the accordion is collapsed
    .gw-accordion-section__bottom {
        display: none;
    }
    //the container for the data to be shown when the accordion is expanded
    .gw-accordion-section__bottom--shown {
        display: block;
        color: var(--accordionTextBottomColor);
    }
}