@import '../mixins/variable';

// Button
.gw-toolbar-button {
    position: relative;
    display: inline-block;
    overflow: hidden;
    border: 0;
    border-radius: 0.9em;
    min-width: 24px;
    margin: 0;
    padding: 0;
    height: 40px;
    width: 40px;
    cursor: pointer;
    font-size: 10px;

    // transition
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    @include variable( background-color, --toolbarButtonBackgroundColor,  --defaultColor, hsl(210, 2%, 84%) );
    @include variable( color, --buttonTextColor, --fontColorDark, hsl(0, 0%, 0%));

    &:visited {
        text-decoration: none;
        @include variable( color, --buttonTextVisitedColor, --buttonTextVisitedColor, hsl(0, 0%, 0%));

    }

    &:hover {
        @include variable( background-color, --toolbarButtonBackgroundHoverColor, --defaultColorHover , hsl(210, 2%, 74%) );
        @include variable( color, --buttonTextHoverColor, --primaryColor, hsl(211, 100%, 45%) );
        @include variable( fill, --toolbarIconHoverFill, --primaryColor, hsl(211, 100%, 45%) );
        
        & .gw-toolbar-button-inner__icon {
            @include variable( fill, --toolbarIconHoverFill, --primaryColor, hsl(211, 100%, 45%) );
        }
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:active {
        @include variable( background-color, --toolbarButtonBackgroundActiveColor, --defaultColorActive, hsl(210, 2%, 64%));
       
        & .gw-toolbar-button-inner__icon {
            @include variable( fill, --toolbarIconActiveFill, --fontColorDark, hsl(0, 0%, 0%));
        }
    }

    &--toggle-state {
               
        & .gw-toolbar-button-inner__icon {
            @include variable( fill, --toolbarIconToggleFill, --primaryColor, hsl(211, 100%, 45%) );
        }

        &:after {
            display: inline-block;
            position: absolute;
            content: "";
            bottom: 0.2em;
            left: calc(60% - 0.6em);
            height: .4em;
            width: .4em;
            border-radius: .5em;
            @include variable( background-color, --toolbarIconToggleFill, --primaryColor, hsl(211, 100%, 45%) );
        }

        &:hover:after {
            @include variable( background-color, --toolbarIconToggleHoverFill, --primaryColor, hsl(211, 100%, 45%) );
        }

        &:hover {
            & .gw-toolbar-button-inner__icon {
                @include variable( fill, --toolbarIconToggledHoverFill, --primaryColor, hsl(211, 100%, 45%) );
            }
        }
    }

    &:disabled,
    &[disabled] {
        pointer-events: none;
        opacity: 0.36;

        & .gw-button__icon {
            opacity: 0.36;
        }
    }

}

.gw-toolbar-button__wrapper {
    height: 100%;
    width: 100%;
}

.gw-toolbar-button-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.gw-toolbar-button-inner__icon {
    position: relative;
    height: 2.1em;
    @include variable( fill, --toolbarIconFill,  --th-slate-dark, hsl(222, 22%, 18%));
}
